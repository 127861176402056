import React, { useCallback, useState } from 'react';

import { useGetSystemListQuery } from 'store/api/hhs/recipe.api';
import { useGetTestPlayersListQuery } from 'store/api/hhsKeyCloack/testPlayers';

import { Box } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Text from 'components/UI/Form/Text';
import Dialog from 'components/pages/Players/TestPlayers/Dialog';

import { columns } from 'components/config/testPlayers';

const TestPlayers = () => {
  const [filter, setFilter] = useState({});

  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery({
      'type[]': 'agregator',
    });

  const handleChangeFilter = useCallback(
    ({ name, value }) => setFilter((prev) => ({ ...prev, [name]: value })),
    [],
  );

  const handleClearFilter = useCallback(() => setFilter({}), []);

  return (
    <TableLayout
      title="Test Players"
      columns={columns}
      getDataQuery={useGetTestPlayersListQuery}
      queryConditional={filter}
      actions={[]}
      dialogComponent={(props) => <Dialog {...props} />}
      editableRow={false}
    >
      <Box
        component="div"
        sx={{
          mb: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <FilterBox>
          <Autocomplete
            label="System"
            name="operatorId"
            value={filter.operatorId ?? null}
            handleChange={handleChangeFilter}
            required={false}
            options={dataSystems || []}
            loading={systemsLoading}
          />
        </FilterBox>
        <FilterBox>
          <Text
            label="Agregator user id"
            name="playerId"
            onChange={(event) =>
              handleChangeFilter({
                name: 'playerId',
                value: event.target.value,
              })
            }
            fullWidth
            value={filter.playerId ?? ''}
          />
        </FilterBox>
        <ClearBtn handleClick={handleClearFilter} filter={filter} />
      </Box>
    </TableLayout>
  );
};

export default TestPlayers;
