import dayjs from 'dayjs';

import { eventTemplateController } from 'store/api/api/tournamentService/eventTemplateController';
import { questTemplateController } from 'store/api/api/tournamentService/questTemplateController';

import { prizeTypes } from 'components/config/prizeSet';
import { DateTimeFormat } from 'config/dates';

export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: (row) => row.name,
  },
  {
    name: 'Base Currency',
    sortLabel: 'baseCurrency',
    value: (row) => row.baseCurrency,
  },
  {
    name: 'Events (amount)',
    value: (row) => row.eventTemplates.length,
  },
  {
    name: 'Operators',
    value: (row) => row.operatorCodes.length,
  },
  {
    name: 'Start',
    value: (row) =>
      dayjs.utc(new Date(row.startDateTime)).format(DateTimeFormat),
  },
  {
    name: 'End',
    value: (row) =>
      dayjs
        .utc(new Date(row.startDateTime))
        .add(row.eventTemplates.length, 'day')
        .format(DateTimeFormat),
  },
  {
    name: 'Details',
    value: () => `Show details`,
    collapse: {
      id: (row) => row.id,
      data: async (row, dispatch) =>
        await Promise.all(
          row.eventTemplates.map(async (id) => {
            return dispatch(
              eventTemplateController.endpoints.getQuestsEventTemplatesItem.initiate(
                { id },
              ),
            ).unwrap();
          }),
        ),
      columns: [
        {
          name: 'Id',
          value: (row) => row.id,
        },
        {
          name: 'Quests',
          value: (row) => row.quests.length,
        },
        {
          name: 'Details',
          value: () => `Show details`,
          collapse: {
            id: (row) => row.id,
            data: async (row, dispatch) =>
              await Promise.all(
                row.quests.map(async (id) => {
                  return dispatch(
                    questTemplateController.endpoints.getQuestsTemplatesItem.initiate(
                      { id },
                    ),
                  ).unwrap();
                }),
              ),
            columns: [
              {
                name: 'ID',
                value: (row) => row.id,
              },
              {
                name: 'Quest name',
                value: (row) => row.name,
              },
              {
                name: 'Game',
                value: (row) => row.gameCodes?.[0],
              },
              {
                name: 'Min. bet to participating',
                value: (row) => row.minimalBet,
              },
              {
                name: 'Scoring Rule',
                value: (row) => row.scoringRule,
              },
              {
                name: 'Goal Score',
                value: (row) => row.goalScore,
              },
              {
                name: 'Prize type',
                value: (row) =>
                  prizeTypes.find(({ value }) => value === row.prize.prizeType)
                    ?.label ?? row.prize.prizeType,
              },
              {
                name: 'Amount',
                value: (row) =>
                  row.prize.freeRoundNumber ??
                  row.prize.moneyPrize ??
                  row.prize.textPrize,
              },
              {
                name: 'Free Round bet amount',
                value: (row) =>
                  row.prize.prizeType === 'FREE_ROUND'
                    ? row.prize.freeRoundBet
                    : '',
              },
            ],
          },
        },
      ],
    },
  },
];
