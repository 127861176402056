import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { AddCircle, HighlightOff } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import Confirm from 'components/UI/Confirm/Confirm';
import Dialog from 'components/UI/PromoTranslations/Dialog';

const propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})),
  handleChange: PropTypes.func.isRequired,
  langKey: PropTypes.string,
  required: PropTypes.bool,
};

const TranslationItem = styled(Box)(({ theme }) => ({
  position: 'relative',
  span: {
    display: 'block',
    padding: '5px 10px',
    background: theme.palette.mode === 'light' ? '#dbd4e9' : '#2d2d2d',
    borderRadius: 6,
    border: '1px solid #858585',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: 14,
  },
}));

const IconRemove = styled(Box)({
  position: 'absolute',
  opacity: 0.7,
  right: -14,
  top: -9,
  width: 18,
  height: 18,
  cursor: 'pointer',
  svg: {
    width: 18,
    height: 18,
  },
});

export const PromoTranslations = ({
  fields,
  values,
  handleChange,
  langKey = 'lang',
  required = true,
}) => {
  const [confirm, setConfirm] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [translation, setTranslation] = useState(null);

  const handleEdit = useCallback((value) => {
    setOpen(true);
    setTranslation(value);
  }, []);

  const handleSave = useCallback(
    (translation) => {
      const newValues = [...values];
      const index = newValues.findIndex(
        (value) => value[langKey] === translation[langKey],
      );

      if (index !== -1) {
        newValues[index] = translation;
      } else {
        newValues.push(translation);
      }
      handleChange(newValues);
      setOpen(false);
    },
    [values],
  );

  const handleDelete = useCallback((item) => {
    setDeleteItem(item);
    setConfirm(true);
  }, []);

  const handleCloseDelete = useCallback(
    (result) => {
      if (result) {
        handleChange(
          [...values].filter((item) => item[langKey] !== deleteItem[langKey]),
        );
      }
      setConfirm(false);
    },
    [values, deleteItem],
  );

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}
      >
        {values.map((value) => (
          <TranslationItem key={value[langKey]}>
            <IconRemove onClick={() => handleDelete(value)}>
              <HighlightOff />
            </IconRemove>
            <span onClick={() => handleEdit(value)}>{value[langKey]}</span>
          </TranslationItem>
        ))}
        <Button
          startIcon={<AddCircle />}
          aria-label="more"
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={() => handleEdit()}
        >
          Add language{required ? <strong>*</strong> : ''}
        </Button>
      </Box>
      {open && (
        <Dialog
          fields={fields}
          translation={translation}
          onSave={handleSave}
          onCancel={() => setOpen(false)}
          langKey={langKey}
        />
      )}
      {confirm && (
        <Confirm
          content="Are you sure you want to remove this translation language?"
          handleClose={handleCloseDelete}
          okText="Yes, I'm"
        />
      )}
    </>
  );
};

PromoTranslations.propTypes = propTypes;
export default PromoTranslations;
