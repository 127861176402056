import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';

import { useGetBingoReportListQuery } from 'store/api/api/bingoService/bingoReports';

import { Box } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import DateRange from 'components/UI/DateRange/DateRange';
import FilterBox from 'components/UI/Filter/FilterBox';
import Text from 'components/UI/Form/Text';

import { columns } from 'components/config/bingoReports';

const RoundsReports = () => {
  const [filter, setFilter] = useState(() => {
    const defaultStartDate = dayjs().subtract(2, 'day').format();
    const defaultEndDate = dayjs().format();
    return {
      date_from: defaultStartDate,
      date_to: defaultEndDate,
    };
  });

  const handleChangeFilter = useCallback(
    ({ name, value }) =>
      setFilter((prevState) => ({ ...prevState, [name]: value })),
    [],
  );

  return (
    <TableLayout
      actions={[]}
      editableRow={false}
      columns={columns}
      getDataQuery={useGetBingoReportListQuery}
      queryConditional={filter}
      title="Rounds report"
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          columnGap: 2,
          mb: 2,
        }}
      >
        <Box component="div">
          <Text
            label="Round ID"
            name="id"
            value={filter.round_id || ''}
            onChange={(event) =>
              handleChangeFilter({
                name: 'round_id',
                value: event.target.value,
              })
            }
          />
        </Box>
        <FilterBox>
          <DateRange handleChangeFilter={handleChangeFilter} filter={filter} />
        </FilterBox>
      </Box>
    </TableLayout>
  );
};

export default RoundsReports;
