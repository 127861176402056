import { Box, Chip } from '@mui/material';

export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: ({ id }) => id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: ({ name }) => name,
  },
  {
    name: 'ENV',
    sortLabel: 'env',
    value: ({ env }) => env,
  },
  {
    name: 'Operators',
    value: () => `Show details`,
    collapse: {
      id: ({ id }) => id,
      data: ({ operatorConfigs }) => [
        {
          operators: (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
              }}
            >
              {operatorConfigs.map(({ id, title }) => (
                <Chip key={id} label={`#${id}: ${title}`} />
              ))}
            </Box>
          ),
        },
      ],
      columns: [
        {
          name: 'Operators',
          value: ({ operators }) => operators,
        },
      ],
    },
  },
  {
    name: 'Description',
    value: ({ description }) => description,
  },
];
