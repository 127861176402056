import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';
import _ from 'lodash';

import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { useGetSystemListQuery } from 'store/api/hhs/recipe.api';
import {
  useGetBalanceIncreaseListQuery,
  useGetBalanceIncreaseTypesQuery,
} from 'store/api/hhsKeyCloack/balanceIncrease';

import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import TableLayout from 'components/Layout/TableLayout';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import Dialog from 'components/pages/BalanceIncrease/Dialog';

import { columns, filters } from 'components/config/balanceIncrease';
import { types, walletTypes } from 'components/pages/BalanceIncrease/configs';
import { DateFormat } from 'config/dates';

const BalanceIncrease = () => {
  const [filter, setFilter] = useState({});

  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery({
      'type[]': 'agregator',
    });
  const { data: dataTypes, isLoading: typesLoading } =
    useGetBalanceIncreaseTypesQuery();

  const handleChangeFilter = useCallback(
    ({ name, value }) =>
      setFilter((prevState) => {
        const newState = _.cloneDeep(prevState);
        _.set(newState, name, typeof value === 'string' ? value.trim() : value);
        return newState;
      }),
    [],
  );
  const handleClearFilter = useCallback(() => setFilter({}), []);

  return (
    <TableLayout
      actions={[]}
      editableRow={false}
      columns={columns}
      getDataQuery={useGetBalanceIncreaseListQuery}
      queryConditional={{
        criteria: {
          ...filter,
          ...(filter?.created_from && {
            created_from: dayjs(filter.created_from).format(DateFormat),
          }),
          ...(filter?.created_to && {
            created_to: dayjs(filter.created_to).format(DateFormat),
          }),
        },
      }}
      title="Balance Increase"
      dialogComponent={(props) => <Dialog {...props} />}
    >
      <Accordion sx={{ mb: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Filters
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            {Object.keys(filters).map((key) => {
              const typeKey = key === 'type';
              const partnerIdKey = key === 'partner_id';

              return (
                <FilterBox key={key}>
                  {['partner_id', 'currency'].includes(key) ? (
                    <Autocomplete
                      label={filters[key]}
                      name={key}
                      value={filter[key] ?? null}
                      handleChange={handleChangeFilter}
                      required={false}
                      options={
                        partnerIdKey ? dataSystems || [] : dataCurrency || []
                      }
                      loading={partnerIdKey ? systemsLoading : currencyLoading}
                    />
                  ) : ['type', 'wallet_type'].includes(key) ? (
                    <Select
                      label={filters[key]}
                      name={key}
                      value={filter[key] ?? ''}
                      handleChange={handleChangeFilter}
                      required={false}
                      options={typeKey ? dataTypes || types : walletTypes}
                      loading={typeKey && typesLoading}
                    />
                  ) : ['created_from', 'created_to'].includes(key) ? (
                    <DatePicker
                      label={filters[key]}
                      format={DateFormat}
                      onChange={(newValue) => {
                        if (newValue && newValue.format() !== 'Invalid Date') {
                          handleChangeFilter({
                            name: key,
                            value: newValue.format(),
                          });
                        }
                      }}
                      slotProps={{
                        textField: {
                          name: filters[key],
                          error:
                            key === 'created_from' &&
                            !filter[key] &&
                            !!filter['created_to'],
                        },
                      }}
                      value={filter[key] ? dayjs(filter[key]) : null}
                    />
                  ) : ['is_sent'].includes(key) ? (
                    <Select
                      label={filters[key]}
                      name={key}
                      value={filter[key] ?? ''}
                      handleChange={handleChangeFilter}
                      required={false}
                      options={[
                        { value: true, label: 'Sent' },
                        { value: false, label: 'Not sent' },
                      ]}
                    />
                  ) : (
                    <Text
                      name={key}
                      label={filters[key]}
                      onChange={(event) =>
                        handleChangeFilter({
                          name: key,
                          value: event.target.value,
                        })
                      }
                      fullWidth
                      value={filter[key] ?? ''}
                    />
                  )}
                </FilterBox>
              );
            })}
            <ClearBtn handleClick={handleClearFilter} filter={filter} />
          </Box>
        </AccordionDetails>
      </Accordion>
    </TableLayout>
  );
};

export default BalanceIncrease;
