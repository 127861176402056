import { configureStore } from '@reduxjs/toolkit';

import { api } from 'store/api/api';
import { configService } from 'store/api/configService';
import { cr } from 'store/api/cr';
import { cs } from 'store/api/cs';
import { rtkQueryErrorLogger } from 'store/api/errorLogger';
import { hhs } from 'store/api/hhs';
import { hhsKeyCloack } from 'store/api/hhsKeyCloack';
import { testingTool } from 'store/api/testingTool';
import alertSlice from 'store/slices/alert';
import tableSlice, { tableMiddleware } from 'store/slices/table';
import uiSlice, { uiMiddleware } from 'store/slices/ui';
import userSlice from 'store/slices/user';

export const store = configureStore({
  reducer: {
    alert: alertSlice,
    user: userSlice,
    table: tableSlice,
    ui: uiSlice,
    [api.reducerPath]: api.reducer,
    [hhs.reducerPath]: hhs.reducer,
    [hhsKeyCloack.reducerPath]: hhsKeyCloack.reducer,
    [cs.reducerPath]: cs.reducer,
    [cr.reducerPath]: cr.reducer,
    [testingTool.reducerPath]: testingTool.reducer,
    [configService.reducerPath]: configService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      hhs.middleware,
      hhsKeyCloack.middleware,
      cs.middleware,
      cr.middleware,
      testingTool.middleware,
      configService.middleware,
      tableMiddleware,
      uiMiddleware,
      rtkQueryErrorLogger,
    ),
});
