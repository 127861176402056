import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { throttle } from 'lodash';
import PropTypes from 'prop-types';

import { useUpdateFixedCurrenciesMutation } from 'store/api/cs/fixedCurrencyController';
import { showAlert } from 'store/slices/alert';

import { EditItem } from 'utils/EditItem';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
  TextField,
} from '@mui/material';

import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  editItem: PropTypes.shape({
    code: PropTypes.string,
    rate: PropTypes.number,
    fixedRate: PropTypes.number,
  }).isRequired,
};

const Dialog = ({ editItem, handleClose }) => {
  const dispatch = useDispatch();

  const [itemsValue, setItemsValue] = useState(editItem);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const [updateFixedCurrencies] = useUpdateFixedCurrenciesMutation();

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleClose,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const result = await updateFixedCurrencies({
      code: editItem.code,
      fixedRate: itemsValue.fixedRate,
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue]);

  const handleChange = useCallback(
    throttle(({ name, value }) => {
      editItemClass.handleChange({
        name,
        value,
        errors,
      });
    }, 500),
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    let errorsSubmit = [];

    if (
      itemsValue.rate > 0 &&
      (itemsValue.rate * 1.1 < itemsValue.fixedRate ||
        itemsValue.rate * 0.9 > itemsValue.fixedRate)
    ) {
      errorsSubmit.push('fixedRate');
      dispatch(
        showAlert({
          type: 'error',
          text: 'Currency rates differ by more than 10%',
        }),
      );
    }

    await editItemClass.handleSubmit({
      itemsValue,
      errorsSubmit,
      numericFields: ['fixedRate'],
    });
  }, [itemsValue]);

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  return (
    <MuiDialog maxWidth="md" open={true}>
      <DialogTitle>Update {itemsValue['code']} Currency Rate</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {process && <FullPageLoader />}
        <Box
          component="div"
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 250px)',
            pt: 1,
          }}
        >
          <TextField
            fullWidth
            label="Real rate, EUR"
            value={itemsValue['rate']}
            disabled
          />

          <Text
            required
            fullWidth
            label="Fixed rate, EUR"
            name="fixedRate"
            onChange={({ target }) => handleChange(target)}
            error={!!errors.includes('fixedRate')}
            value={itemsValue.fixedRate || ''}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained">
          Update
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = propTypes;
export default Dialog;
