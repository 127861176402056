import { getENV } from 'utils/getEnv';

export const config = {
  // local: {
  //   api: 'https://api-kube.sb.evoent.tech/',
  //   cr: 'https://api-kube.sb.evoent.tech/',
  //   cs: 'https://api-kube.sb.evoent.tech/',
  //   hhs: 'https://api.sb.evoent.tech/',
  //   testingTool: 'https://api-kube.gt.evoent.tech/',
  // },
  sb: {
    api: 'https://api-kube.sb.evoent.tech/',
    cr: 'https://api-kube.sb.evoent.tech/',
    cs: 'https://api-kube.sb.evoent.tech/',
    hhs: 'https://api.sb.evoent.tech/',
    testingTool: 'https://api-kube.gt.evoent.tech/',
    configService: 'https://api-kube.sb.evoent.tech/',
  },
  gt: {
    api: 'https://api-kube.gt.evoent.tech/',
    cr: 'https://api.gt.evoent.tech/',
    cs: 'https://api-kube.gt.evoent.tech/',
    hhs: 'https://api.gt.evoent.tech/',
    testingTool: 'https://api-kube.gt.evoent.tech/',
    configService: 'https://api-kube.gt.evoent.tech/',
  },
  rc: {
    api: 'https://api-kube.rc.evoent.tech/',
    cr: 'https://api-kube.rc.evoent.tech/',
    cs: 'https://api-kube.rc.evoent.tech/',
    hhs: 'https://api.rc.evoent.tech/',
    testingTool: 'https://api-kube.gt.evoent.tech/',
    configService: 'https://api-kube.rc.evoent.tech/',
  },
  eu: {
    api: 'https://api-kube.evoplay.games/',
    cr: 'https://api-kube.evoplay.games/',
    cs: 'https://api-kube.evoplay.games/',
    hhs: 'https://api.evoplay.games/',
    testingTool: 'https://api-kube.evoplay.games/',
    configService: 'https://api-kube.evoplay.games/',
  },
  cn: {
    api: 'https://api-kube.8provider.com/',
    cr: 'https://api-kube.8provider.com/',
    cs: 'https://api-kube.8provider.com/',
    hhs: 'https://api.8provider.com/',
    testingTool: 'https://api-kube.evoplay.games/',
    configService: 'https://api-kube.evoplay.games/',
  },
  wg: {
    api: 'https://api-kube.production.games/',
    cr: 'https://api-kube.production.games/',
    cs: 'https://api.gt.evoent.tech/',
    hhs: 'https://api.production.games/',
    testingTool: 'https://api-kube.evoplay.games/',
    configService: 'https://api-kube.evoplay.games/',
  },
  evoent: {
    api: 'https://api-kube.evoent.games/',
    cr: 'https://api-kube.evoent.games/',
    cs: 'https://api-kube.evoent.games/',
    hhs: 'https://api.evoent.games/',
    testingTool: 'https://api-kube.evoplay.games/',
    configService: 'https://api-kube.evoplay.games/',
  },
  mga: {
    api: 'https://api-kube.evoplay-games.com/',
    cr: 'https://api-kube.evoplay-games.com/',
    cs: 'https://api-kube.evoplay-games.com/',
    hhs: 'https://api.evoplay-games.com/',
    testingTool: 'https://api-kube.evoplay.games/',
    configService: 'https://api-kube.evoplay.games/',
  },
  hkg: {
    api: 'https://api-kube.66provider.com/',
    cr: 'https://api-kube.66provider.com/',
    cs: 'https://api.gt.evoent.tech/',
    hhs: 'https://api.66provider.com/',
    testingTool: 'https://api-kube.evoplay.games/',
    configService: 'https://api-kube.evoplay.games/',
  },
};

export const devEnvs = ['sb', 'gt', 'rc'];
export const prodEnvs = ['eu', 'cn', 'wg', 'evoent', 'mga', 'hkg'];
export const ENV_BY_STAGE = devEnvs.includes(getENV()) ? devEnvs : prodEnvs;
