export const HelperList = [
  {
    name: 'Campaign name',
    text: 'The name, which will be displayed in the list of other campaigns in the Bonus Room.',
  },
  {
    name: 'Start date',
    text: 'Date and time of the campaign start.',
  },
  {
    name: 'End date',
    text: 'Date and time of the campaign ends.',
  },
  {
    name: 'Pending time',
    text: 'The time in hours during which the campaign will be displayed in the Bonus Room before the campaign starts.',
  },
  {
    name: 'Show after finish',
    text: 'The time in hours during which the campaign will be displayed in the Bonus Room after its completion.',
  },
  {
    name: 'Base currency',
    text: 'The main currency in which the campaign will be conducted.',
  },
  {
    name: 'Min. bet to participating',
    text: 'The minimum bet to participate in this campaign.',
  },
  {
    name: 'Scoring rule',
    text: 'The rule type by which points are awarded to a player.',
  },
  {
    name: 'Tournament subtype',
    text: 'Subtype selection for the tournament.',
  },
  {
    name: 'Goal score for event',
    text: 'The goal score value for Races tournament subtype.',
  },
  {
    name: 'Prize distribution',
    text: 'The principle of prize distribution.',
  },
  {
    name: 'Participating games',
    text: 'Games in which this campaign will be available.',
  },
  {
    name: 'Operator codes',
    text: 'The list of operator codes for which this campaign will be available.',
  },
  {
    name: 'Limit to base currency',
    text: 'Only users, that playing with the same currency, as campaigns base currency, can participate in a given campaign. Rounds in the other currencies will be ignored.',
  },
  {
    name: 'Banners',
    text: 'Banners are displayed in gaming clients (login pop-up, Bonus Room cards). Currently, only the Middle banner is used',
  },
];
