import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const externalOperatorConfigs = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getExternalOperatorConfigs: build.query({
      query: ({ page, size, ...params }) => ({
        url: '/external-operator-config/v1',
        params: {
          limit: size,
          offset: page * size,
          ...params,
        },
      }),
      transformResponse: ({ data, pagination }) => ({
        content: data,
        totalElements: pagination.total,
      }),
      providesTags: () => [{ type: 'ExternalOperatorConfigs' }],
    }),
    getExternalOperatorConfig: build.query({
      query: ({ id }) => ({
        url: `/external-operator-config/v1/${id}`,
      }),
      providesTags: () => [{ type: 'ExternalOperatorConfigs' }],
    }),
    updateExternalOperatorConfig: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/external-operator-config/v1${id ? `/${id}` : ''}`,
        method: id ? 'PATCH' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'ExternalOperatorConfigs' }],
    }),
    deleteExternalOperatorConfig: build.mutation({
      query: ({ id }) => ({
        url: `/external-operator-config/v1/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'ExternalOperatorConfigs' }],
    }),
    getDomainAliasList: build.query({
      query: () => ({
        url: '/domain/v1/alias/list',
      }),
    }),
  }),
});

export const {
  useGetExternalOperatorConfigsQuery,
  useGetExternalOperatorConfigQuery,
  useUpdateExternalOperatorConfigMutation,
  useDeleteExternalOperatorConfigMutation,
  useGetDomainAliasListQuery,
} = externalOperatorConfigs;
