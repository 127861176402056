import { api } from 'store/api/api';

export const eventTemplateController = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestsEventTemplates: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v1/quests/event-templates',
        params,
      }),
      providesTags: () => [{ type: 'EventTemplates' }],
    }),
    getQuestsEventTemplatesItem: build.query({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/quests/event-templates/${id}`,
      }),
      providesTags: () => [{ type: 'EventTemplates' }],
    }),
    getAllQuestsEventTemplates: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v1/quests/event-templates/dictionary',
        params,
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, val) => [
            ...acc,
            { label: `${val.id}-${val.name}`, value: val.id },
          ],
          [],
        ),
      providesTags: () => [{ type: 'EventTemplates' }],
    }),
    updateQuestsEventTemplate: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/tournament-service/api/v1/quests/event-templates${id ? `/${id}` : ''}`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'EventTemplates' }],
    }),
    deleteQuestsEventTemplate: build.mutation({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/quests/event-templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'EventTemplates' }],
    }),
  }),
});

export const {
  useGetQuestsEventTemplatesQuery,
  useGetQuestsEventTemplatesItemQuery,
  useGetAllQuestsEventTemplatesQuery,
  useUpdateQuestsEventTemplateMutation,
  useDeleteQuestsEventTemplateMutation,
} = eventTemplateController;
