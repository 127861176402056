export const columns = [
  {
    name: 'Campaign',
    sortLabel: 'campaignName',
    value: (row) => `${row.campaignId}-${row.campaignName}`,
  },
  {
    name: 'Language',
    sortLabel: 'lang',
    value: (row) => row.lang,
  },
  {
    name: 'Type',
    value: (row) => row.typeText,
  },
  {
    name: 'Ranking',
    value: (row) => row.rankingText,
  },
  {
    name: 'Title',
    value: (row) => row.titleText,
  },
  {
    name: 'Rules',
    value: (row) => row.rulesText,
  },
];

export const dialogColumns = columns.filter((item) => item.name !== 'ID');

export const filters = {
  'Filter by Campaign': 'campaign_id',
  'Filter by Language': 'lang',
};

export const fields = [
  {
    name: 'typeText',
    title: 'Type',
    type: 'text',
  },
  {
    name: 'rankingText',
    title: 'Ranking',
    type: 'text',
  },
  {
    name: 'titleText',
    title: 'Title',
    type: 'text',
  },
  {
    name: 'rulesText',
    title: 'Rules',
    type: 'textarea',
  },
];
