export const columns = [
  {
    name: 'ENV',
    value: (row) => row.env,
  },
  {
    name: 'Operator Id',
    value: (row) => row.operator_id,
  },
  {
    name: 'Game Code',
    value: (row) => row.game_code,
  },
  {
    name: 'Currency',
    value: (row) => row.currency,
  },
  {
    name: 'Slot Count',
    value: (row) => row.slot_count,
  },
  {
    name: 'Slot Bet',
    value: (row) => row.slot_bet,
  },
  {
    name: 'Avg Bet',
    value: (row) => row.avg_bet,
  },
  {
    name: 'Slot Win',
    value: (row) => row.slot_win,
  },
  {
    name: 'GGR',
    value: (row) => row.ggr,
  },
  {
    name: 'Avg Profit',
    value: (row) => row.avg_profit,
  },
  {
    name: 'Slot Payout',
    value: (row) => row.slot_payout,
  },
];
