import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import { store } from 'store';

import { initKeycloak } from 'services/UserService';

import { LicenseInfo } from '@mui/x-license';

import App from 'components/App/App';

dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(duration);

const renderApp = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
  );
};

// TODO: think about rewriting
// eslint-disable-next-line
if (isSupportedBrowser && isSupportedBrowser()) {
  initKeycloak(renderApp);
}
