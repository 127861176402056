import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const mockSystem = {
  systemId: '12',
  id: '12',
  systemKey: 'new_key',
  altSystemKey: null,
  name: 'test-name',
  type: 'agregator',
  title: 'test-title',
  eventListener: '',
  production: '1',
  disabled: '0',
  comments: null,
  isSocial: '0',
  balanceIncreaseUrl: 'http://test-url/bi',
  integrationType: 'seamless_wallet',
  callbackType: 'test',
  callbackUrl: 'http://test-url/',
  sendSystemId: '1',
  sendZeroWins: '1',
  callbackTimeoutSec: '0',
  gameSessionLifeTimeHours: '0',
  licenseType: 'other',
  labels: ['label 1', 'label 2'],
  balanceIncreaseEnabled: '1',
};

const systemSettings = hhsKeyCloack.injectEndpoints({
  //TODO: update mock endpoints after BE will be ready
  endpoints: (build) => ({
    getSystems: build.query({
      queryFn: () => ({
        data: {
          content: [mockSystem],
          totalElements: 1,
        },
      }),
    }),
    getSystem: build.query({
      queryFn: () => ({
        data: mockSystem,
      }),
    }),
    updateSystem: build.mutation({
      queryFn: ({ id, ...body }) => {
        id ? console.log('updated', id, body) : console.log('created', body);
      },
    }),
    deleteSystem: build.mutation({
      queryFn: ({ systemId }) => {
        console.log('deleted', systemId);
      },
    }),
    generateSystemKey: build.mutation({
      queryFn: () => ({
        data: {
          systemKey: Math.random().toString(36).substring(2),
        },
      }),
    }),
    getGameLicenseList: build.query({
      queryFn: () => ({
        data: ['licensed', 'streamer', 'other'],
      }),
    }),
  }),
});

export const {
  useGetSystemsQuery,
  useGetSystemQuery,
  useUpdateSystemMutation,
  useDeleteSystemMutation,
  useGenerateSystemKeyMutation,
  useGetGameLicenseListQuery,
} = systemSettings;
