import { getENV } from 'utils/getEnv';

import { devEnvs } from 'config/api';

const ENV_PROD = ['hkg'];
const PROVIDERS = [
  { id: 1, name: 'Evoplay' },
  { id: 2, name: 'Slotopia' },
  { id: 3, name: 'Aviatrix' },
  { id: 4, name: 'Bingo' },
  { id: 5, name: 'EvoplayLottery' },
  { id: 6, name: 'FormulaSpin' },
];

export const getProvidersList = () =>
  PROVIDERS.reduce(
    (acc, provider) => [...acc, { label: provider.name, value: provider.name }],
    [],
  );
export const getProvidersIdList = () =>
  PROVIDERS.reduce(
    (acc, provider) => [
      ...acc,
      { label: `${provider.id} - ${provider.name}`, value: provider.id },
    ],
    [],
  );
export const ENV_LIST = devEnvs.includes(getENV()) ? devEnvs : ENV_PROD;
