import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useToggle } from 'hooks/useToggle';
import _ from 'lodash';

import { useUploadImageMutation } from 'store/api/api/tournamentService/imageController';
import {
  useGetQuestsTemplatesItemQuery,
  useUpdateQuestsTemplateMutation,
} from 'store/api/api/tournamentService/questTemplateController';
import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { useGetGamesQuery } from 'store/api/hhs/recipe.api';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import { InfoOutlined, KeyboardBackspace } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Stack } from '@mui/material';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import PrizeSet from 'components/UI/PrizeSet/PrizeSet';
import { validatePrizeSet } from 'components/UI/PrizeSet/validate';
import PromoTranslations from 'components/UI/PromoTranslations/PromoTranslations';
import Columns from 'components/UI/Table/Columns';
import NotFound from 'components/pages/NotFound/NotFound';
import { HelperList } from 'components/pages/PromoTools/Quests/QuestTemplates/helperList';

import { PAGE_PATH } from 'components/config/pages';
import { defaultExpirationPeriodInDays } from 'components/config/prizeSet';
import { fields } from 'components/config/questsTranslations';
import { scoringRulesQuest } from 'components/config/scoringRule';

const initialValue = (editItem) =>
  editItem
    ? {
        ...editItem,
        gameCodes: editItem.gameCodes[0],
        translations: editItem.translations || [],
      }
    : {
        name: '',
        icon: null,
        gameCodes: null,
        baseCurrency: null,
        minimalBet: '',
        scoringRule: '',
        goalScore: '',
        prize: {
          prizeType: 'FREE_ROUND',
          prizeQuantity: '',
          freeRoundNumber: '',
          freeRoundBet: '',
          freeRoundGameCode: '',
          freeRoundExpirationPeriod: defaultExpirationPeriodInDays,
          moneyPrize: '',
          textPrize: '',
          isPrizeForActiveGame: false,
        },
        translations: [],
      };

const QuestsTemplatesItem = () => {
  const dispatch = useDispatch();

  const { itemId } = useParams();
  const navigate = useNavigate();

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetQuestsTemplatesItemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const { data: dataGames, isLoading: gamesLoading } = useGetGamesQuery();
  const { data: dataCurrencies, isLoading: currenciesLoading } =
    useGetCurrencyRatesQuery();

  const [uploadImage] = useUploadImageMutation();
  const [updateQuest] = useUpdateQuestsTemplateMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.QUESTS_TEMPLATES));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
        uploadImage,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const result = await updateQuest({
      ...(editItem?.id && { id: editItem.id }),
      ...itemsValue,
      gameCodes: [itemsValue.gameCodes],
    });

    editItemClass.checkUpdateData(result);
  }, [editItem?.id, itemsValue, itemId]);

  const updateImg = useCallback(
    async () => await editItemClass.updateImg(['icon'], itemsValue),
    [itemsValue],
  );

  const handleChange = useCallback(
    ({ name, value, isCheckValue = true, ...props }) => {
      editItemClass.handleChange({
        name,
        value,
        isCheckValue,
        errors,
        ...props,
      });
    },
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    const errorsSubmit = validatePrizeSet({
      itemPrize: itemsValue,
      itemPath: '',
      errorsSubmit: [],
    });

    if (itemsValue.translations.length < 1) {
      errorsSubmit.push('translations');
    }

    await editItemClass.handleSubmit({
      itemsValue,
      updateImg,
      errorsSubmit,
      imageFields: ['icon'],
      textFields: ['name', 'baseCurrency', 'scoringRule', 'gameCodes'],
      numericFields: ['minimalBet', 'goalScore'],
    });
  }, [itemsValue, errors]);

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore
      ? itemId !== 'copy'
        ? editItemStore
        : _.omit(editItemStore, 'id')
      : editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (itemId === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [itemId, editItemStore]);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}
      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem
                  ? editItem.id
                    ? `Update "${editItem.name}"`
                    : `Copy by "${editItem.name}"`
                  : 'Create Quest Template'}
              </Box>
            </Box>
            <IconButton onClick={handleOpenHelperModal}>
              <InfoOutlined />
            </IconButton>
          </Box>
          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'scroll',
            }}
          >
            <Text
              required
              label="Quest name"
              name="name"
              fullWidth
              onChange={({ target }) => handleChange(target)}
              error={!!errors.includes('name')}
              value={itemsValue.name}
            />
            <Divider sx={{ mt: 2, mb: 2 }}>Parameters</Divider>
            <Columns count={3}>
              <Autocomplete
                label="Game name"
                name="gameCodes"
                error={!!errors.includes('gameCodes')}
                value={itemsValue.gameCodes}
                handleChange={handleChange}
                options={dataGames || []}
                loading={gamesLoading}
              />
              <Autocomplete
                label="Base currency"
                name="baseCurrency"
                value={itemsValue.baseCurrency}
                handleChange={handleChange}
                options={dataCurrencies || []}
                error={errors.includes('baseCurrency')}
                loading={currenciesLoading}
              />
              <Text
                required
                fullWidth
                label="Min. bet to participating"
                name="minimalBet"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('minimalBet')}
                value={itemsValue.minimalBet}
              />
              <Select
                label="Scoring rule"
                name="scoringRule"
                error={!!errors.includes('scoringRule')}
                value={itemsValue.scoringRule}
                handleChange={handleChange}
                options={scoringRulesQuest}
              />
              <Text
                required
                fullWidth
                label="Goal Score"
                name="goalScore"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('goalScore')}
                value={itemsValue.goalScore}
              />
            </Columns>

            <Divider sx={{ mt: 2, mb: 2 }}>Prize set</Divider>

            <PrizeSet
              handleChange={handleChange}
              itemsValue={itemsValue}
              objectPath=""
              errors={errors}
              type="quest_template"
              {...(editItem && { editItem })}
            />

            <Divider
              sx={{
                my: 2,
                color: errors.includes('translations') ? 'error.main' : '',
              }}
            >
              Translations
            </Divider>

            <PromoTranslations
              fields={fields}
              values={itemsValue.translations}
              handleChange={(value) =>
                handleChange({ name: 'translations', value })
              }
              required={
                !itemsValue.translations.length ||
                errors.includes('translations')
              }
            />
            <Divider />

            <ImageUpload
              label="Image"
              value={itemsValue.icon}
              handleChange={(value) => handleChange({ name: 'icon', value })}
              hasError={!!errors.includes('icon')}
            />
          </Stack>
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button onClick={handleSubmit} variant="contained">
              {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
            </Button>
          </Box>
          {openHelperModal && (
            <HelperModal
              handleClose={handleCloseHelperModal}
              helperList={HelperList}
            />
          )}
        </>
      )}
    </>
  );
};
export default QuestsTemplatesItem;
