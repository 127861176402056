import React from 'react';

import PropTypes from 'prop-types';

import { IconButton, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  sx: PropTypes.shape({}),
  slotProps: PropTypes.shape({}),
};

const Text = ({
  onChange,
  name,
  value = '',
  disabled = false,
  sx = {},
  slotProps = { input: {} },
  ...props
}) => (
  <TextField
    {...props}
    onChange={onChange}
    value={value}
    name={name}
    disabled={disabled}
    slotProps={{
      ...slotProps,
      input: {
        ...slotProps?.input,
        ...(!disabled && {
          endAdornment: (
            <IconButton
              onClick={() => {
                onChange({ target: { value: '', name } });
              }}
              sx={{
                width: 28,
                height: 28,
                visibility: 'hidden',
              }}
            >
              <ClearIcon sx={{ width: 20, height: 20 }} />
            </IconButton>
          ),
        }),
      },
    }}
    sx={{
      ...sx,
      '&.Mui-focused .MuiIconButton-root': {
        visibility: value?.length > 0 ? 'visible' : 'hidden',
      },
      ':hover .MuiIconButton-root': {
        visibility: value?.length > 0 ? 'visible' : 'hidden',
      },
      '& .MuiInputBase-root': { paddingRight: '6px' },
    }}
  />
);

Text.propTypes = propTypes;
export default Text;
