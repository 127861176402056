import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import PropTypes from 'prop-types';

import { Box, FormControl, FormLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

const ReactQuillStyled = styled(Box)(({ height }) => ({
  height: 'auto',
  '.ql-editor': {
    height,
  },
}));

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  required: PropTypes.bool,
  height: PropTypes.number,
};

const defaultModules = {
  toolbar: [
    [{ header: [3, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
};

const defaultFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
];

const Editor = ({
  label,
  value,
  error = false,
  required = true,
  height = 200,
  ...rest
}) => (
  <FormControl required={required} error={error} sx={{ width: '100%' }}>
    <FormLabel>{label}</FormLabel>
    <ReactQuillStyled height={height}>
      <ReactQuill
        theme="snow"
        value={value}
        modules={defaultModules}
        formats={defaultFormats}
        {...rest}
      />
    </ReactQuillStyled>
  </FormControl>
);

Editor.propTypes = propTypes;
export default Editor;
