import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import DetailsRows from 'components/pages/Bingo/RoundsReports/PartDetails/DetailsRows/DetailsRows';

const mainbgcolor = 'rgba(0, 0, 0, 0.03)';
const bgcolor = 'rgba(25, 118, 210, 0.08)';
const padding = '5px 5px 5px 10px';

const PartDetails = (data) => (
  <Box
    sx={{
      bgcolor: mainbgcolor,
      ml: '-16px',
      mt: '-7px',
    }}
  >
    <Table>
      <TableHead>
        {data.calledNumbers && (
          <TableRow>
            <TableCell colSpan={6} sx={{ padding }}>
              Drawn Numbers:{' '}
              {data.calledNumbers.map((number, index) => {
                const isWinningCall = data.partDetails.some(
                  (part) => part.winningNumber === number,
                );

                return (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        fontWeight: isWinningCall ? 'bold' : 'normal',
                      }}
                    >
                      {number}
                    </span>
                    {index < data.calledNumbers.length - 1 && ', '}
                  </React.Fragment>
                );
              })}
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          {data.partDetails?.map((el) => (
            <TableCell colSpan={2} key={el.part} sx={{ padding }}>
              Part {el.part}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        <DetailsRows data={data.partDetails} padding={padding} />
        <TableRow sx={{ verticalAlign: 'sub' }}>
          {data.partDetails?.map(
            (el) =>
              el.winningTickets && (
                <React.Fragment key={el.part}>
                  <TableCell sx={{ padding }}>Winning Tickets</TableCell>
                  <TableCell sx={{ padding: '10px' }}>
                    {el.winningTickets?.map((ticket) => (
                      <Box
                        key={ticket.id}
                        borderRadius="10px"
                        sx={{ bgcolor, p: 2, mb: 1 }}
                      >
                        <Typography variant="body2">
                          Ticket Id: {ticket.ticketId}
                        </Typography>
                        <Typography variant="body2">
                          Prize: {ticket.prizePerTicket}
                        </Typography>
                        <Typography variant="body2">
                          Player Id: {ticket.playerId}
                        </Typography>
                      </Box>
                    ))}
                  </TableCell>
                </React.Fragment>
              ),
          )}
        </TableRow>
      </TableBody>
    </Table>
  </Box>
);

export default PartDetails;
