export const externalBO = {
  local: 'http://localhost:8080',
  sb: 'https://bo.sb.evoent.tech',
  gt: 'https://bo.gt.evoent.tech',
  rc: 'https://bo.rc.evoent.tech',
  eu: 'https://bo.evoplay.games',
  cn: 'https://bo.8provider.com',
  wg: 'https://bo.production.games',
  evoent: 'https://bo.evoent.games',
  mga: 'https://bo.evoplay-games.com',
  hkg: 'https://bo.66provider.com',
};
