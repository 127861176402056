import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { useToggle } from 'hooks/useToggle';
import _ from 'lodash';

import { useUploadImageMutation } from 'store/api/api/tournamentService/imageController';
import {
  useGetPrizeDropsItemQuery,
  useUpdatePrizeDropsMutation,
} from 'store/api/api/tournamentService/prizeDropController';
import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { useGetGamesQuery } from 'store/api/hhs/recipe.api';
import { showAlert } from 'store/slices/alert';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { getEventDurationInMs, getStartOfTheDay } from 'utils/durations';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import {
  AddCircle,
  Delete,
  InfoOutlined,
  KeyboardBackspace,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers-pro';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import PrizeSet from 'components/UI/PrizeSet/PrizeSet';
import { validatePrizeSet } from 'components/UI/PrizeSet/validate';
import PromoTranslations from 'components/UI/PromoTranslations/PromoTranslations';
import Columns from 'components/UI/Table/Columns';
import NotFound from 'components/pages/NotFound/NotFound';
import { HelperList } from 'components/pages/PromoTools/PrizeDrops/Campaigns/helperList';

import { PAGE_PATH } from 'components/config/pages';
import { fields } from 'components/config/prizeDropTranslations';
import { defaultExpirationPeriodInDays } from 'components/config/prizeSet';
import { DateTimeFormat } from 'config/dates';

const defaultPrizeEntry = {
  prizeQuantity: '',
  prize: {
    freeRoundExpirationPeriod: defaultExpirationPeriodInDays,
  },
};

const initialValue = (editItem) =>
  editItem
    ? {
        ...editItem,
        operatorCodes: editItem.operatorCodes
          ? editItem.operatorCodes?.join(', ')
          : '',
        prizeInventoryItems: [
          ...editItem.prizeInventoryItems.map((item) => ({
            ...item,
            id: _.uniqueId(),
          })),
          { ...defaultPrizeEntry, id: _.uniqueId() },
        ],
        recurringRule: {
          ...editItem.recurringRule,
          eventDurationMs: editItem.recurringRule?.eventDurationMs
            ? new Date(
                getStartOfTheDay().getTime() +
                  editItem.recurringRule.eventDurationMs,
              )
            : '',
          campaignDurationMs: editItem.recurringRule?.campaignDurationMs
            ? new Date(
                getStartOfTheDay().getTime() +
                  editItem.recurringRule.campaignDurationMs,
              )
            : '',
        },
        translations: editItem.translations || [],
      }
    : {
        name: '',
        recurringRule: {
          pendingHours: '',
          startDateTime: '',
          durationInDays: '',
          eventDurationMs: '',
        },
        baseCurrency: null,
        minimalBet: '',
        playerDailyLimit: '',
        mediumBanner: null,
        operatorCodes: '',
        gameCodes: [],
        prizeInventoryItems: [{ ...defaultPrizeEntry, id: _.uniqueId() }],
        crossEnv: false,
        limitToBaseCurrency: false,
        testCampaign: false,
        translations: [],
      };

const CampaignsItem = () => {
  const dispatch = useDispatch();

  const { itemId } = useParams();
  const navigate = useNavigate();

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetPrizeDropsItemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const { data: dataGames, isLoading: gamesLoading } = useGetGamesQuery();

  const [uploadImage] = useUploadImageMutation();
  const [updatePrizeDrops] = useUpdatePrizeDropsMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.PRIZE_DROPS_CAMPAIGNS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
        uploadImage,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const cloneItemsValue = _.cloneDeep(itemsValue);
    const result = await updatePrizeDrops({
      ...(editItem && { id: editItem.id }),
      ...cloneItemsValue,
      operatorCodes: cloneItemsValue.operatorCodes
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item),
      prizeInventoryItems: cloneItemsValue.prizeInventoryItems
        .slice(0, -1)
        .map((item) => {
          delete item.id;
          return item;
        }),
      recurringRule: {
        ...cloneItemsValue.recurringRule,
        eventDurationMs: getEventDurationInMs(
          cloneItemsValue.recurringRule.eventDurationMs,
          getStartOfTheDay(),
        ),
      },
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue]);

  const updateImg = useCallback(async () => {
    return await editItemClass.updateImg(['mediumBanner'], itemsValue);
  }, [itemsValue]);

  const handleChange = useCallback(
    ({ name, value, isCheckValue = true, ...props }) => {
      editItemClass.handleChange({
        name,
        value,
        isCheckValue,
        errors,
        ...props,
      });
    },
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    let errorsSubmit = [];

    if (itemsValue.translations.length < 1) {
      errorsSubmit.push('translations');
    }

    if (
      !getEventDurationInMs(
        itemsValue.recurringRule.eventDurationMs,
        getStartOfTheDay(),
      )
    ) {
      errorsSubmit.push('recurringRule.eventDurationMs');
    }

    const addedPrizes = itemsValue.prizeInventoryItems.slice(0, -1);
    if (addedPrizes.length) {
      addedPrizes.map((item, index) => {
        errorsSubmit.concat(
          validatePrizeSet({
            itemPrize: item,
            itemPath: `prizeInventoryItems.${index}.`,
            type: 'prize_drops',
            errorsSubmit,
          }),
        );
      });
    } else {
      dispatch(
        showAlert({
          type: 'error',
          text: 'You must add at least one prize set',
        }),
      );
      errorsSubmit.push(`prizeInventoryItems.0.moneyPrize`);
      errorsSubmit.push(`prizeInventoryItems.0.prizeQuantity`);
    }

    await editItemClass.handleSubmit({
      itemsValue,
      updateImg,
      errorsSubmit,
      imageFields: ['mediumBanner'],
      textFields: [
        'name',
        'recurringRule.startDateTime',
        'recurringRule.eventDurationMs',
        'baseCurrency',
        'operatorCodes',
      ],
      numericFields: [
        'recurringRule.pendingHours',
        'recurringRule.durationInDays',
        'minimalBet',
        'playerDailyLimit',
      ],
      multipleFields: ['gameCodes'],
    });
  }, [itemsValue]);

  const handleAdd = useCallback(() => {
    const addedPrizes = itemsValue.prizeInventoryItems.slice(-1);
    const errorsSubmit = [
      ...validatePrizeSet({
        itemPrize: addedPrizes[0],
        itemPath: `prizeInventoryItems.${itemsValue.prizeInventoryItems.length - 1}.`,
        type: 'prize_drops',
        errorsSubmit: [],
      }),
    ];

    if (errorsSubmit.length) {
      setErrors(errorsSubmit);
    } else {
      setItemsValue((prevState) => {
        return {
          ...prevState,
          prizeInventoryItems: [
            ...prevState.prizeInventoryItems,
            { ...defaultPrizeEntry, id: _.uniqueId() },
          ],
        };
      });
    }
  }, [itemsValue]);

  const handleDelete = useCallback(
    (row) =>
      setItemsValue((prevState) => {
        const modifyEntries = _.cloneDeep(prevState.prizeInventoryItems);
        modifyEntries.splice(row, 1);

        return {
          ...prevState,
          prizeInventoryItems: modifyEntries,
        };
      }),
    [],
  );

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (itemId === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [itemId, editItemStore]);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}
      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem
                  ? editItem.id
                    ? `Update "${editItem.name}"`
                    : `Copy by "${editItem.name}"`
                  : 'Create Prize Drops Campaign'}
              </Box>
            </Box>
            <IconButton onClick={handleOpenHelperModal}>
              <InfoOutlined />
            </IconButton>
          </Box>

          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'scroll',
            }}
          >
            <Columns count={3}>
              <Text
                required
                fullWidth
                label="Campaign name"
                name="name"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('name')}
                value={itemsValue.name}
              />

              <DateTimePicker
                sx={{ width: '100%' }}
                label="Start date"
                format={DateTimeFormat}
                timeSteps={{ minutes: 1 }}
                interval={1}
                timezone="UTC"
                onChange={(newValue) => {
                  if (newValue && newValue.format() !== 'Invalid Date') {
                    handleChange({
                      name: 'recurringRule.startDateTime',
                      value: newValue.format(),
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                    name: 'Start date',
                    error: !!errors.includes('recurringRule.startDateTime'),
                  },
                }}
                {...(editItem && {
                  defaultValue: dayjs(editItem.recurringRule.startDateTime),
                })}
              />
              <Text
                required
                fullWidth
                label="Campaign duration"
                name="recurringRule.durationInDays"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('recurringRule.durationInDays')}
                value={itemsValue.recurringRule.durationInDays}
              />
            </Columns>
            <Columns count={3}>
              <TimePicker
                sx={{ width: '100%' }}
                label="Event duration"
                views={['hours', 'minutes']}
                format="HH:mm"
                timeSteps={{ minutes: 1 }}
                onChange={(newValue) => {
                  if (newValue && newValue.format() !== 'Invalid Date') {
                    handleChange({
                      name: 'recurringRule.eventDurationMs',
                      value: newValue.format(),
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                    name: 'Event duration',
                    error: !!errors.includes('recurringRule.eventDurationMs'),
                  },
                }}
                {...(editItem && {
                  defaultValue: dayjs(itemsValue.recurringRule.eventDurationMs),
                })}
              />
              <Text
                required
                fullWidth
                label="Pending time"
                name="recurringRule.pendingHours"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('recurringRule.pendingHours')}
                value={itemsValue.recurringRule.pendingHours}
              />
              <Autocomplete
                label="Base currency"
                name="baseCurrency"
                error={!!errors.includes('baseCurrency')}
                value={itemsValue.baseCurrency}
                handleChange={handleChange}
                options={dataCurrency}
                loading={currencyLoading}
              />
            </Columns>

            <Columns count={3}>
              <Text
                required
                fullWidth
                label="Min. bet to participating"
                name="minimalBet"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('minimalBet')}
                value={itemsValue.minimalBet}
              />

              <Text
                required
                fullWidth
                label="Player prizes limit per event"
                name="playerDailyLimit"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('playerDailyLimit')}
                value={itemsValue.playerDailyLimit}
              />
            </Columns>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={itemsValue.crossEnv}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({
                        name,
                        value: checked,
                      })
                    }
                  />
                }
                label="Cross environment"
                name="crossEnv"
                sx={{ mx: 0 }}
              />
              <Tooltip
                title="Only users, that playing with the same currency, as campaigns base currency, can participate in a given campaign"
                arrow
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={itemsValue.limitToBaseCurrency}
                      onChange={({ target: { name, checked } }) =>
                        handleChange({
                          name,
                          value: checked,
                        })
                      }
                    />
                  }
                  label="Limit to base currency"
                  name="limitToBaseCurrency"
                  sx={{ mx: 0 }}
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    checked={itemsValue.testCampaign}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({
                        name,
                        value: checked,
                      })
                    }
                  />
                }
                label="Test campaign"
                name="testCampaign"
                sx={{ mx: 0 }}
              />
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }}>Prize set</Divider>
            {itemsValue.prizeInventoryItems.map((item, index) => {
              const isLastItem =
                index === itemsValue.prizeInventoryItems.length - 1;

              return (
                <React.Fragment key={item.id}>
                  {(isLastItem || index > 0) && <Divider sx={{ mb: 2 }} />}
                  <Box
                    component="div"
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 50px',
                      gap: 2,
                    }}
                  >
                    <PrizeSet
                      handleChange={handleChange}
                      itemsValue={item}
                      objectPath={`prizeInventoryItems.${index}.`}
                      errors={errors}
                      type="prize_drops"
                      showTooltip={!!isLastItem}
                      {...(editItem && {
                        editItem: itemsValue.prizeInventoryItems[index],
                      })}
                    />
                    <Box
                      component="div"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      {isLastItem ? (
                        <IconButton onClick={handleAdd}>
                          <AddCircle />
                        </IconButton>
                      ) : (
                        <IconButton onClick={() => handleDelete(index)}>
                          <Delete />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </React.Fragment>
              );
            })}

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Autocomplete
              label="Participating games"
              name="gameCodes"
              error={!!errors.includes('gameCodes')}
              value={itemsValue.gameCodes}
              handleChange={handleChange}
              options={dataGames || []}
              loading={gamesLoading}
              multiple
            />

            <Text
              required
              fullWidth
              label="Operator codes"
              name="operatorCodes"
              onChange={({ target }) => handleChange(target)}
              error={!!errors.includes('operatorCodes')}
              value={itemsValue.operatorCodes}
              multiline
              maxRows={3}
            />

            <Divider
              sx={{
                my: 2,
                color: errors.includes('translations') ? 'error.main' : '',
              }}
            >
              Translations
            </Divider>

            <PromoTranslations
              fields={fields}
              values={itemsValue.translations}
              handleChange={(value) =>
                handleChange({ name: 'translations', value })
              }
              langKey="language"
              required={
                !itemsValue.translations.length ||
                errors.includes('translations')
              }
            />

            <Divider sx={{ mt: 2, mb: 2 }}>Banners</Divider>

            <ImageUpload
              label="Image"
              value={itemsValue.mediumBanner}
              handleChange={(value) =>
                handleChange({ name: 'mediumBanner', value })
              }
              hasError={!!errors.includes('mediumBanner')}
            />
          </Stack>
          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button onClick={handleSubmit} variant="contained">
              {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
            </Button>
          </Box>
          {openHelperModal && (
            <HelperModal
              handleClose={handleCloseHelperModal}
              helperList={HelperList}
            />
          )}
        </>
      )}
    </>
  );
};
export default CampaignsItem;
