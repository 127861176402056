import React from 'react';

import { useGetEventsQuery } from 'store/api/hhs/eventLogsController';

import Table from 'components/UI/Table/Table';

import { columns } from 'components/config/eventLogs';

const EventLogs = () => {
  return (
    <Table
      actions={[]}
      getDataQuery={useGetEventsQuery}
      columns={columns}
      editableRow={false}
      rowsPerPageOptions={[-1]}
    />
  );
};

export default EventLogs;
