import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Link, useNavigate } from 'react-router-dom';

import { findPatchPages } from 'utils/pages';

import { Box, Button, Typography } from '@mui/material';

import { PAGE_PATH } from 'components/config/pages';

import Preload from 'images/NotFound/preload.jpg';
import Vid from 'images/NotFound/video.mp4';

const NotFound = () => {
  const navigate = useNavigate();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100vh - 200px)',
      }}
    >
      <Box
        sx={{
          m: '0 auto',
          width: 690,
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: 640,
            height: 360,
            m: '0 auto 10px',
          }}
        >
          <img
            src={Preload}
            alt="preload"
            style={{
              position: 'absolute',
              width: 'inherit',
              top: 0,
              left: 0,
              ...(isVideoLoaded && { opacity: 0 }),
              ...(!isVideoLoaded && { opacity: 1 }),
            }}
          />
          <ReactPlayer
            url={Vid}
            playing={true}
            controls={false}
            loop={true}
            muted={true}
            onReady={onLoadedData}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: 0,
              ...(isVideoLoaded && { opacity: 1 }),
              ...(!isVideoLoaded && { opacity: 0 }),
            }}
          />
        </Box>
        <Typography>
          The page you are looking for has flown away.
          <br />
          Our mascot, Evo, can&apos;t find it. Let&apos;s fly to a better place!
        </Typography>
        <Box
          sx={{
            display: 'flex',
            columnGap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            m: '10px 0',
          }}
        >
          {window?.navigation?.canGoBack && (
            <>
              <Button variant="contained" onClick={() => navigate(-1)}>
                Go Back
              </Button>
              <div>or</div>
            </>
          )}
          <Button
            component={Link}
            to={findPatchPages(PAGE_PATH.HOME)}
            variant="contained"
          >
            Take Me Home
          </Button>
        </Box>
        <Typography>Or check out the topics on the menu on the left</Typography>
      </Box>
    </Box>
  );
};

export default NotFound;
