import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRauth } from 'store/api/queryWithReAuth';

import { getENV } from 'utils/getEnv';

import { config } from 'config/api';

const baseQuery = fetchBaseQuery({
  baseUrl: config[getENV()]?.hhs,
  prepareHeaders: (headers) => {
    headers.set(
      'hhs-aok',
      'f3b72c36b040e328816dee2e73f8afd1e5d59a0ebc6f8b9d716975f383178b97',
    );
    headers.set(
      'hhs-apk',
      '2214bbf029b0a0800b5581405ae6f48456a200cf4fd6ef95499944b7e02d9e845003769d63e21801d626df8d514e27f7165d3624b839d340bb09cf7f1e967036',
    );

    return headers;
  },
});

export const hhs = createApi({
  reducerPath: 'hhs',
  tagTypes: ['ExternalOperatorConfigs'],
  baseQuery: (...props) => baseQueryWithRauth(...props, baseQuery),
  endpoints: () => ({}),
});
