export const types = [
  'gift',
  'promo',
  'quest',
  'tournament',
  'manual',
  'prize_drops',
  'third_party_bonus',
  'mini_games',
  'races',
  'chases',
  'jack_drops',
  'cashback',
  'raceslchases',
  'free_rounds_win',
];

export const walletTypes = ['real', 'bonus'];
