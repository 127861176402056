export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: ({ id }) => id,
  },
  {
    name: 'Campaign',
    sortLabel: 'campaignName',
    value: ({ campaignId, campaignName }) => `${campaignId}-${campaignName}`,
  },
  {
    name: 'Language',
    sortLabel: 'lang',
    value: ({ lang }) => lang,
  },
  {
    name: 'Title',
    value: ({ title }) => title,
  },
  {
    name: 'Rules',
    value: ({ rules }) => rules,
  },
];

export const dialogColumns = columns.filter(({ name }) => name !== 'ID');

export const filters = {
  'Filter by Campaign': 'campaign_id',
  'Filter by Language': 'lang',
};

export const fields = [
  {
    name: 'title',
    title: 'Title',
    type: 'text',
  },
  {
    name: 'rules',
    title: 'Rules',
    type: 'textarea',
  },
];
