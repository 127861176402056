import StorageCollection from 'utils/storage/StorageCollection';

const StorageKey = 'ui';
const StorageData = {
  colorMode: 'light',
  sidebarView: 'true',
};
const storage = new StorageCollection(StorageKey, StorageData);
export const getStorageData = () => storage.getAll();
export const setStorageData = (data) => storage.add(data);
