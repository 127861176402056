import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const mockSystemGroup = {
  id: 2,
  name: 'ffffesssnw',
  comment: 'testig',
  disabled: 0,
  created: '2022-04-13T15:30:34+00:00',
  updated: '2023-11-13T08:37:17+00:00',
  systemIds: [1, 3, 94],
  users: [
    {
      id: 51,
      username: 'test_client',
      email: 'gfhfh@jjj.com',
      name: '412 аа',
      company: 'аа',
    },
  ],
};

const systemGroups = hhsKeyCloack.injectEndpoints({
  //TODO: update mock endpoints after BE will be ready
  endpoints: (build) => ({
    getSystemGroups: build.query({
      queryFn: () => ({
        data: {
          content: [mockSystemGroup],
          totalElements: 1,
        },
      }),
    }),
    getSystemGroup: build.query({
      queryFn: () => ({
        data: mockSystemGroup,
      }),
    }),
    updateSystemGroup: build.mutation({
      queryFn: ({ id, ...body }) => {
        id ? console.log('updated', id, body) : console.log('created', body);
      },
    }),
    deleteSystemGroup: build.mutation({
      queryFn: ({ systemId }) => {
        console.log('deleted', systemId);
      },
    }),
  }),
});

export const {
  useGetSystemGroupsQuery,
  useGetSystemGroupQuery,
  useUpdateSystemGroupMutation,
  useDeleteSystemGroupMutation,
} = systemGroups;
