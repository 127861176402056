import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import {
  useGetLanguagesQuery,
  useGetLocalizationsItemQuery,
  useUpdateLocalizationsItemMutation,
} from 'store/api/api/localizationService/localizationsController';
import { showAlert } from 'store/slices/alert';

import { EditItem } from 'utils/EditItem';

import { AddCircle, Delete } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Dialog as MuiDialog,
  Typography,
} from '@mui/material';

import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

const getLanguageName = (index) => `translations.${index}.language`;
const getTranslationName = (index) => `translations.${index}.translation`;

const defaultLanguageItem = { language: '', translation: '' };

const Dialog = ({ editItem, handleClose }) => {
  const dispatch = useDispatch();

  const [itemsValue, setItemsValue] = useState({
    groupCode: '',
    fieldCode: '',
    translations: [{ ...defaultLanguageItem }],
  });
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const { groupCode, fieldCode } = editItem ?? {};

  const { data: languages, languagesLoading } = useGetLanguagesQuery();
  const { data: localizationsItem, isLoading } = useGetLocalizationsItemQuery(
    { groupCode, fieldCode },
    {
      skip: !editItem,
    },
  );

  const [updateLocalizationsItem] = useUpdateLocalizationsItemMutation();

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleClose,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const translationFields = useMemo(
    () =>
      itemsValue.translations?.flatMap((_, index) => [
        getLanguageName(index),
        getTranslationName(index),
      ]),
    [itemsValue.translations],
  );

  const handleChange = useCallback(
    ({ name, value }) => {
      editItemClass.handleChange({
        name,
        value,
        errors,
      });
    },
    [errors],
  );

  const handleAdd = useCallback(() => {
    const addErrors = editItemClass.checkText(translationFields, itemsValue);

    if (addErrors.length) {
      setErrors((prev) => [...new Set([...prev, ...addErrors])]);
      return;
    }

    setItemsValue((prev) => ({
      ...prev,
      translations: [...prev.translations, { ...defaultLanguageItem }],
    }));
  }, [translationFields, itemsValue]);

  const handleDelete = useCallback((index) => {
    setItemsValue(({ translations, ...prev }) => ({
      ...prev,
      translations: translations.filter((_, i) => i !== index),
    }));

    setErrors((prev) => prev.filter((item) => !item.includes('translations')));
  }, []);

  const handleSubmit = useCallback(async () => {
    const errorsSubmit = editItemClass.checkText(
      ['groupCode', 'fieldCode', ...translationFields],
      itemsValue,
    );

    const enTranslationExists = itemsValue.translations.find(
      ({ language }) => language === 'EN',
    );

    if (!errorsSubmit.length && !enTranslationExists) {
      dispatch(
        showAlert({
          type: 'error',
          text: 'English language is required.',
        }),
      );
      return;
    }

    await editItemClass.handleSubmit({
      errorsSubmit,
      itemsValue,
    });
  }, [translationFields, itemsValue]);

  const getFilteredLanguages = useCallback(
    (selected) =>
      languages?.filter(
        (language) =>
          language === selected ||
          !itemsValue.translations.find((item) => language === item.language),
      ),
    [languages, itemsValue.translations],
  );

  const updateData = useCallback(async () => {
    const result = await updateLocalizationsItem({
      ...itemsValue,
      translations: itemsValue.translations.reduce(
        (acc, { language, translation }) => ({
          ...acc,
          [language]: translation,
        }),
        {},
      ),
      isCreation: !editItem,
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue, editItem]);

  const updateItemsValue = useCallback(
    (values) => setItemsValue((prev) => ({ ...prev, ...values })),
    [],
  );

  useEffect(() => {
    localizationsItem &&
      updateItemsValue({
        translations: Object.entries(localizationsItem.translations).map(
          ([language, translation]) => ({
            language,
            translation,
          }),
        ),
      });
  }, [localizationsItem]);

  useEffect(() => {
    editItem && updateItemsValue({ groupCode, fieldCode });
  }, [editItem]);

  useEffect(() => {
    submit && updateData();
  }, [submit]);

  return (
    <MuiDialog maxWidth="md" open={true} fullWidth>
      <DialogTitle>{editItem ? 'Edit' : 'Create'} Key</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {process && <FullPageLoader />}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: 2,
            pt: 1,
          }}
        >
          <Text
            name="groupCode"
            label="Code"
            value={itemsValue.groupCode}
            onChange={({ target }) => handleChange(target)}
            error={errors.includes('groupCode')}
            disabled={!!editItem}
            required
          />
          <Text
            name="fieldCode"
            label="Key"
            value={itemsValue.fieldCode}
            onChange={({ target }) => handleChange(target)}
            error={errors.includes('fieldCode')}
            disabled={!!editItem}
            required
          />
        </Box>
        <Divider sx={{ my: 3 }} />
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: 2 }}>
          <Typography>Languages</Typography>
          <Typography>Translations</Typography>
        </Box>
        <Divider sx={{ my: 3 }} />
        {!isLoading ? (
          <>
            <Box
              sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr', gap: 2 }}
            >
              {itemsValue.translations.map(
                ({ language, translation }, index, arr) => (
                  <Fragment key={language}>
                    <Select
                      name={getLanguageName(index)}
                      label="Language"
                      value={language}
                      options={getFilteredLanguages(language)}
                      handleChange={handleChange}
                      error={errors.includes(getLanguageName(index))}
                      loading={languagesLoading}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Text
                        name={getTranslationName(index)}
                        value={translation}
                        onChange={({ target }) => handleChange(target)}
                        error={errors.includes(getTranslationName(index))}
                        required
                        fullWidth
                      />
                      {arr.length > 1 && (
                        <IconButton onClick={() => handleDelete(index)}>
                          <Delete />
                        </IconButton>
                      )}
                    </Box>
                  </Fragment>
                ),
              )}
            </Box>
            {languages?.length !== itemsValue.translations.length && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                <Button startIcon={<AddCircle />} onClick={handleAdd}>
                  Add Language
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
          Save
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  editItem: PropTypes.shape({
    groupCode: PropTypes.string.isRequired,
    fieldCode: PropTypes.string.isRequired,
  }),
  handleClose: PropTypes.func.isRequired,
};

export default Dialog;
