import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { useUpdateTagMutation } from 'store/api/hhsKeyCloack/tags';

import { EditItem } from 'utils/EditItem';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';

import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

const initialValue = (editItem) =>
  editItem ? { tagName: editItem.tagName } : { tagName: '' };

const Dialog = ({ editItem, handleClose }) => {
  const dispatch = useDispatch();

  const [itemsValue, setItemsValue] = useState(initialValue(editItem));

  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const [updateTag] = useUpdateTagMutation();

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleClose,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value }) =>
      editItemClass.handleChange({
        name,
        value,
        errors,
      }),
    [errors],
  );

  const handleSubmit = useCallback(
    () =>
      editItemClass.handleSubmit({
        itemsValue,
        textFields: ['tagName'],
      }),
    [itemsValue],
  );

  const updateData = useCallback(async () => {
    const result = await updateTag({
      ...(editItem?.tagId && { id: editItem.tagId }),
      ...itemsValue,
    });

    editItemClass.checkUpdateData(result);
  }, [editItem, itemsValue]);

  useEffect(() => {
    submit && updateData();
  }, [submit]);

  return (
    <MuiDialog maxWidth="md" open={true} fullWidth>
      <DialogTitle>{editItem ? 'Update' : 'Create'} Tag</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {process && <FullPageLoader />}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <Text
            name="tagName"
            value={itemsValue.tagName}
            label="Name"
            onChange={({ target }) => handleChange(target)}
            error={errors.includes('tagName')}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          {editItem ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  editItem: PropTypes.shape({
    tagId: PropTypes.string.isRequired,
    tagName: PropTypes.string.isRequired,
  }),
  handleClose: PropTypes.func.isRequired,
};

export default Dialog;
