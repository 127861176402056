import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRauth } from 'store/api/queryWithReAuth';

import { getToken } from 'services/UserService';
import { getENV } from 'utils/getEnv';

import { config } from 'config/api';

const baseQuery = fetchBaseQuery({
  baseUrl: config[getENV()]?.api,
  prepareHeaders: (headers) => {
    const accessToken = getToken();
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const api = createApi({
  reducerPath: 'api',
  tagTypes: [
    'TournamentCampaigns',
    'TournamentTranslations',
    'TournamentPrizeDrops',
    'PrizeDropTranslations',
    'TournamentWheelOfFortune',
    'WheelOfFortuneTranslations',
    'QuestsTemplates',
    'QuestTranslations',
    'EventTemplates',
    'QuestsCampaign',
    'Localizations',
    'InvoiceGroup',
  ],
  baseQuery: (...props) => baseQueryWithRauth(...props, baseQuery),
  endpoints: () => ({}),
});
