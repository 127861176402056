import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const HelperList = [
  {
    name: (
      <>
        Session Id,
        <br />
        ENV,
        <br />
        Round Finish Date,
        <br />
        Provider,
        <br />
        Game Type,
        <br />
        Country Code
      </>
    ),
    text: 'determined by API gateway if obtained via Cloudflare',
  },
  {
    name: 'Multiplier = win/bet',
    text: (
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell>Multiplier</StyledTableCell>
            <StyledTableCell>Color</StyledTableCell>
            <StyledTableCell colSpan={2}>RGB</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell>х10-x99.99</StyledTableCell>
            <StyledTableCell>Yellow</StyledTableCell>
            <StyledTableCell>255,255,0</StyledTableCell>
            <StyledTableCell>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: 'rgb(255,255,0)',
                }}
              ></Box>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>x100-x199.99</StyledTableCell>
            <StyledTableCell>Orange</StyledTableCell>
            <StyledTableCell>255,125,0</StyledTableCell>
            <StyledTableCell>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: 'rgb(255,125,0)',
                }}
              ></Box>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>x200-x499.99</StyledTableCell>
            <StyledTableCell>Pink</StyledTableCell>
            <StyledTableCell>255, 192, 230</StyledTableCell>
            <StyledTableCell>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: 'rgb(255, 192, 230)',
                }}
              ></Box>
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>x500+</StyledTableCell>
            <StyledTableCell>Red</StyledTableCell>
            <StyledTableCell>255,0,0</StyledTableCell>
            <StyledTableCell>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: 'rgb(255,0,0)',
                }}
              ></Box>
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    ),
  },
];
