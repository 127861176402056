import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useToggle } from 'hooks/useToggle';
import _ from 'lodash';

import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import {
  useGetGamesQuery,
  useGetSystemListQuery,
} from 'store/api/hhs/recipe.api';
import {
  useGetRoundsHistoryCSVQuery,
  useGetRoundsHistoryQuery,
} from 'store/api/hhsKeyCloack/analytics';
import { showAlert } from 'store/slices/alert';

import StorageCollection from 'utils/storage/StorageCollection';

import { Download, ExpandMore, InfoOutlined } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Typography,
} from '@mui/material';

import DateRange from 'components/UI/DateRange/DateRange';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import Subtitle from 'components/UI/Subtitle/Subtitle';
import Table from 'components/UI/Table/Table';
import { HelperList } from 'components/pages/Statistics/RoundHistory/helperList';

import { ENV_LIST, getProvidersList } from 'components/config/analytics';
import { countryCodesList } from 'components/config/country';
import { columns } from 'components/config/roundHistory';

const storage = new StorageCollection('RoundHistory', {});

const prepareFilter = (filter) => ({
  ...filter,
  ...(filter.date_from && {
    date_from: new Date(filter.date_from).valueOf(),
  }),
  ...(filter.date_to && {
    date_to: new Date(filter.date_to).valueOf(),
  }),
});

const RoundHistory = () => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState(storage.getAll());
  const [resetPage, setResetPage] = useState(false);
  const [downloadCSV, setDownloadCSV] = useState(false);

  const COUNTRY_CODES = useMemo(countryCodesList, []);
  const PROVIDERS_LIST = useMemo(getProvidersList, []);

  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery({
      'type[]': 'agregator',
    });
  const { data: dataGames, isLoading: gamesLoading } = useGetGamesQuery();
  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const { isFetching: isFetchingCSV } = useGetRoundsHistoryCSVQuery(
    prepareFilter(filter),
    {
      skip:
        !downloadCSV ||
        !(
          filter.env &&
          ((filter.date_from && filter.date_to) || filter.round_id)
        ),
    },
  );

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const handleChangeFilter = useCallback(({ name, value }) => {
    setFilter((prevState) => {
      const newState = _.cloneDeep(prevState);
      const newValue = typeof value === 'string' ? value.trim() : value;
      if (newValue === null || newValue === undefined || newValue === '') {
        if (name === 'operator_id') {
          _.unset(newState, 'operator');
          _.unset(newState, 'player_id');
          _.unset(newState, 'game_code');
          _.unset(newState, 'round_state');
          _.unset(newState, 'currency');
          _.unset(newState, 'session_id');
          _.unset(newState, 'game_type');
          _.unset(newState, 'provider');
          _.unset(newState, 'country_code');
          _.unset(newState, 'user_type');
        }
        _.unset(newState, name);
      } else {
        _.set(newState, name, newValue);
      }
      return newState;
    });
    setResetPage(true);
  }, []);
  const handleClearFilter = useCallback(() => {
    setFilter({});
    setResetPage(true);
  }, []);
  const handleDownload = useCallback(() => {
    setDownloadCSV(true);
    CSVStartDownload();
    setTimeout(() => setDownloadCSV(false), 500);
  }, []);
  const CSVStartDownload = useCallback(() => {
    dispatch(
      showAlert({
        type: 'info',
        text: 'Your request has been accepted. The document will be download in a few minutes.',
      }),
    );
  }, []);

  useEffect(() => {
    storage.set(filter);
  }, [filter]);

  return (
    <>
      {isFetchingCSV && <FullPageLoader />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          columnGap: 2,
        }}
        component="div"
      >
        <Box>
          <Typography variant="h5" component="h2">
            Round History
          </Typography>
          <Subtitle subTitle="This report includes only completed rounds. Data is nearly real-time, with rounds appearing after a brief delay." />
        </Box>
        <Box>
          <IconButton onClick={handleOpenHelperModal}>
            <InfoOutlined />
          </IconButton>
        </Box>
      </Box>
      <Accordion sx={{ mb: 2 }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Filters & Download CSV
        </AccordionSummary>
        <AccordionDetails>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            <FilterBox>
              <Select
                label="ENV"
                name="env"
                value={filter.env ?? ''}
                handleChange={handleChangeFilter}
                options={ENV_LIST}
              />
            </FilterBox>
            <FilterBox>
              <DateRange
                filter={filter}
                handleChangeFilter={handleChangeFilter}
              />
            </FilterBox>
            <FilterBox>
              <Text
                label="Round Id"
                name="round_id"
                onChange={(event) =>
                  handleChangeFilter({
                    name: 'round_id',
                    value: event.target.value,
                  })
                }
                fullWidth
                value={filter.round_id ?? ''}
              />
            </FilterBox>
            <FilterBox>
              <Autocomplete
                label="Operator Id"
                name="operator_id"
                value={filter.operator_id ?? null}
                handleChange={handleChangeFilter}
                required={false}
                options={dataSystems || []}
                loading={systemsLoading}
              />
            </FilterBox>
            <FilterBox>
              <Text
                label="Operator"
                name="operator"
                disabled={!filter.operator_id}
                onChange={(event) =>
                  handleChangeFilter({
                    name: 'operator',
                    value: event.target.value,
                  })
                }
                fullWidth
                value={filter.operator ?? ''}
              />
            </FilterBox>
            <FilterBox>
              <Text
                label="Player Id"
                name="player_id"
                disabled={!filter.operator_id}
                onChange={(event) =>
                  handleChangeFilter({
                    name: 'player_id',
                    value: event.target.value,
                  })
                }
                fullWidth
                value={filter.player_id ?? ''}
              />
            </FilterBox>
            <FilterBox>
              <Autocomplete
                label="Game code"
                name="game_code"
                disabled={!filter.operator_id}
                required={false}
                value={filter.game_code ?? null}
                handleChange={handleChangeFilter}
                options={dataGames || []}
                loading={gamesLoading}
              />
            </FilterBox>
            <FilterBox>
              <Select
                label="Round State"
                name="round_state"
                disabled={!filter.operator_id}
                required={false}
                value={filter.round_state ?? ''}
                handleChange={handleChangeFilter}
                options={['finished', 'canceled']}
              />
            </FilterBox>
            <FilterBox>
              <Autocomplete
                label="Currency"
                name="currency"
                disabled={!filter.operator_id}
                required={false}
                value={filter.currency ?? null}
                handleChange={handleChangeFilter}
                options={dataCurrency || []}
                loading={currencyLoading}
              />
            </FilterBox>
            <FilterBox>
              <Text
                label="Session Id"
                name="session_id"
                disabled={!filter.operator_id}
                onChange={(event) =>
                  handleChangeFilter({
                    name: 'session_id',
                    value: event.target.value,
                  })
                }
                fullWidth
                value={filter.session_id ?? ''}
              />
            </FilterBox>
            <FilterBox>
              <Text
                label="Game Type"
                name="game_type"
                disabled={!filter.operator_id}
                onChange={(event) =>
                  handleChangeFilter({
                    name: 'game_type',
                    value: event.target.value,
                  })
                }
                fullWidth
                value={filter.game_type ?? ''}
              />
            </FilterBox>
            <FilterBox>
              <Select
                label="Provider"
                name="provider"
                disabled={!filter.operator_id}
                required={false}
                value={filter.provider ?? ''}
                handleChange={handleChangeFilter}
                options={PROVIDERS_LIST}
              />
            </FilterBox>
            <FilterBox>
              <Autocomplete
                label="Country Code"
                name="country_code"
                disabled={!filter.operator_id}
                required={false}
                value={filter.country_code ?? null}
                handleChange={handleChangeFilter}
                options={COUNTRY_CODES || []}
              />
            </FilterBox>
            <FilterBox>
              <Text
                label="User Type"
                name="user_type"
                disabled={!filter.operator_id}
                onChange={(event) =>
                  handleChangeFilter({
                    name: 'user_type',
                    value: event.target.value,
                  })
                }
                fullWidth
                value={filter.user_type ?? ''}
              />
            </FilterBox>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
              <Button
                startIcon={<Download />}
                onClick={handleDownload}
                disabled={
                  !(
                    filter.env &&
                    ((filter.date_from && filter.date_to) || filter.round_id)
                  )
                }
              >
                Download CSV
              </Button>
            </Box>
            <ClearBtn handleClick={handleClearFilter} filter={filter} />
          </Box>
        </AccordionDetails>
      </Accordion>
      {filter.env &&
        ((filter.date_from && filter.date_to) || filter.round_id) && (
          <Table
            actions={[]}
            getDataQuery={useGetRoundsHistoryQuery}
            columns={columns}
            queryConditional={prepareFilter(filter)}
            resetPage={resetPage}
            editableRow={false}
          />
        )}
      {openHelperModal && (
        <HelperModal
          handleClose={handleCloseHelperModal}
          helperList={HelperList}
        />
      )}
    </>
  );
};

export default RoundHistory;
