import { api } from 'store/api/api';

const localizationsController = api.injectEndpoints({
  endpoints: (build) => ({
    getLocalizations: build.query({
      query: (params) => ({
        url: '/localization-service/admin/v1/localizations',
        params,
      }),
      providesTags: () => [{ type: 'Localizations' }],
    }),
    getLocalizationsItem: build.query({
      query: ({ groupCode, fieldCode }) => ({
        url: `/localization-service/admin/v1/localizations/${groupCode}`,
        params: {
          fieldCode,
        },
      }),
      providesTags: () => [{ type: 'Localizations' }],
    }),
    updateLocalizationsItem: build.mutation({
      query: ({ groupCode, isCreation, ...body }) => ({
        url: `/localization-service/admin/v1/localizations/${groupCode}`,
        method: isCreation ? 'POST' : 'PUT',
        body,
      }),
      invalidatesTags: () => [{ type: 'Localizations' }],
    }),
    deleteLocalizationsItem: build.mutation({
      query: ({ groupCode, fieldCode }) => ({
        url: `/localization-service/admin/v1/localizations/${groupCode}`,
        method: 'DELETE',
        params: {
          fieldCode,
        },
      }),
      invalidatesTags: () => [{ type: 'Localizations' }],
    }),
    getLanguages: build.query({
      query: () => ({
        url: '/localization-service/admin/v1/languages',
      }),
      transformResponse: (response) =>
        response?.map(({ languageCode }) => languageCode),
    }),
    //TODO: Mock endpoint, need to update after import/export API will be ready
    uploadLocalizationsFile: build.mutation({
      queryFn: () => ({
        data: {
          keys: [{ code: 'core', key: 'bet' }],
          errors: ['Row 15'],
        },
      }),
    }),
  }),
});

export const {
  useGetLocalizationsQuery,
  useGetLocalizationsItemQuery,
  useUpdateLocalizationsItemMutation,
  useDeleteLocalizationsItemMutation,
  useGetLanguagesQuery,
  useUploadLocalizationsFileMutation,
} = localizationsController;
