export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'Type',
    sortLabel: 'postType',
    value: (row) => row.postType,
  },
  {
    name: 'Title',
    sortLabel: 'title',
    value: (row) => row.title,
  },
  {
    name: 'Short description',
    value: (row) => (
      <div dangerouslySetInnerHTML={{ __html: row.shortDescription }}></div>
    ),
  },
  {
    name: 'Posted date',
    sortLabel: 'publicationDate',
    value: (row) => row.publicationDate,
  },
];

export const POST_TYPES = ['NEWS', 'UPDATE'];
