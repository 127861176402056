import dayjs from 'dayjs';

import { Avatar } from '@mui/material';

import { DateTimeFormat } from 'config/dates';

export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: (row) => row.name,
  },
  {
    name: 'Image',
    value: (row) => <Avatar alt="Icon" src={row.mediumBanner} />,
  },
  {
    name: 'Base Currency',
    value: (row) => row.baseCurrency,
  },
  {
    name: 'Min bet for Participating',
    sortLabel: 'minimalBet',
    value: (row) => row.minimalBet,
  },
  {
    name: 'Event prize pool',
    value: (row) => row.eventPrizePool,
  },
  {
    name: 'Campaign prize pool',
    value: (row) => row.campaignPrizePool,
  },
  {
    name: 'Participated Games',
    sortLabel: 'gameCodes',
    value: (row) => row.gameCodes.length,
  },
  {
    name: 'Operators',
    sortLabel: 'operatorCodes',
    value: (row) => row.operatorCodes?.length ?? 0,
  },
  {
    name: 'Start',
    sortLabel: 'recurringRule.startDateTime',
    value: (row) =>
      dayjs
        .utc(new Date(row.recurringRule.startDateTime))
        .format(DateTimeFormat),
  },
  {
    name: 'End',
    value: (row) =>
      dayjs.utc(new Date(row.finishDateTime)).format(DateTimeFormat),
  },
];

export const prizeTypes = [
  { label: 'Money', value: 'MONEY' },
  { label: 'Free Round', value: 'FREE_ROUND' },
  { label: 'Material', value: 'TEXT' },
];

export const mapPrizeTypeToProp = {
  MONEY: 'moneyPrize',
  FREE_ROUND: 'freeRoundPrize',
  TEXT: 'textPrize',
};
