import { api } from 'store/api/api';

const questCampaignController = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestsCampaigns: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v1/quests/campaigns',
        params,
      }),
      providesTags: () => [{ type: 'QuestsCampaign' }],
    }),
    getQuestsCampaignsItem: build.query({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/quests/campaigns/${id}`,
      }),
      providesTags: () => [{ type: 'QuestsCampaign' }],
    }),
    updateQuestsCampaign: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/tournament-service/api/v1/quests/campaigns${id ? `/${id}` : ''}`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'QuestsCampaign' }],
    }),
    deleteQuestsCampaign: build.mutation({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/quests/campaigns/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'QuestsCampaign' }],
    }),
  }),
});

export const {
  useGetQuestsCampaignsQuery,
  useGetQuestsCampaignsItemQuery,
  useUpdateQuestsCampaignMutation,
  useDeleteQuestsCampaignMutation,
} = questCampaignController;
