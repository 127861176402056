import AccessDenied from 'components/pages/AccessDenied/AccessDenied';
import BalanceIncrease from 'components/pages/BalanceIncrease/BalanceIncrease';
import PlayersReports from 'components/pages/Bingo/PlayersReports/PlayersReports';
import RoundsReports from 'components/pages/Bingo/RoundsReports/RoundsReports';
import CallbackTest from 'components/pages/Callbacks/CallbackTest/CallbackTest';
import ClientAreaNews from 'components/pages/ClientArea/News/News';
import ClientAreaNewsItem from 'components/pages/ClientArea/News/NewsItem';
import ClientAreaPromotions from 'components/pages/ClientArea/Promotions/Promotions';
import ClientAreaPromotionsItem from 'components/pages/ClientArea/Promotions/PromotionsItem';
import EventLogs from 'components/pages/EventLogs/EventLogs';
import Localizations from 'components/pages/Games/Localizations/Localizations';
import MasterGameList from 'components/pages/Games/MasterGameList/MasterGameList';
import MasterGameListItem from 'components/pages/Games/MasterGameList/MasterGameListItem';
import Home from 'components/pages/Home/Home';
import TestPlayers from 'components/pages/Players/TestPlayers/TestPlayers';
import Profile from 'components/pages/Profile/Profile';
import Audit from 'components/pages/PromoTools/Audit/Audit';
import FixedCurrency from 'components/pages/PromoTools/FixedCurrency/FixedCurrency';
import PrizeDropsCampaignsItem from 'components/pages/PromoTools/PrizeDrops/Campaigns/CampaignsItem';
import PrizeDrops from 'components/pages/PromoTools/PrizeDrops/PrizeDrops';
import PromoCampaigns from 'components/pages/PromoTools/PromoCampaigns/PromoCampaigns';
import PromoCampaignsItem from 'components/pages/PromoTools/PromoCampaigns/PromoCampaignsItem';
import QuestsCampaignsItem from 'components/pages/PromoTools/Quests/Campaigns/CampaignsItem';
import QuestsEventTemplatesItem from 'components/pages/PromoTools/Quests/EventTemplates/EventTemplatesItem';
import QuestsTemplatesItem from 'components/pages/PromoTools/Quests/QuestTemplates/QuestsTemplatesItem';
import Quests from 'components/pages/PromoTools/Quests/Quests';
import TournamentsCampaignsItem from 'components/pages/PromoTools/Tournaments/Campaigns/CampaignsItem';
import Tournaments from 'components/pages/PromoTools/Tournaments/Tournaments';
import WheelOfFortuneCampaignsItem from 'components/pages/PromoTools/WheelOfFortune/Campaigns/CampaignsItem';
import WheelOfFortune from 'components/pages/PromoTools/WheelOfFortune/WheelOfFortune';
import BetWinDaily from 'components/pages/Statistics/BetWinDaily/BetWinDaily';
import GenerateInvoice from 'components/pages/Statistics/GenerateInvoice/GenerateInvoice';
import ActionsByRound from 'components/pages/Statistics/RoundHistory/ActionsByRound/ActionsByRound';
import RoundHistory from 'components/pages/Statistics/RoundHistory/RoundHistory';
import RtpByGameStatistics from 'components/pages/Statistics/RtpByGameStatistics/RtpByGameStatistics';
import ExternalOperatorConfigs from 'components/pages/Systems/ExternalOperatorConfigs/ExternalOperatorConfigs';
import ExternalOperatorConfigsItem from 'components/pages/Systems/ExternalOperatorConfigs/ExternalOperatorConfigsItem';
import InvoiceGroupItem from 'components/pages/Systems/InvoiceGroups/InvoiceGroupItem';
import InvoiceGroups from 'components/pages/Systems/InvoiceGroups/InvoiceGroups';
import SystemGroups from 'components/pages/Systems/SystemGroups/SystemGroups';
import SystemGroupsItem from 'components/pages/Systems/SystemGroups/SystemGroupsItem';
import SystemSettings from 'components/pages/Systems/SystemSettings/SystemSettings';
import SystemSettingsItem from 'components/pages/Systems/SystemSettings/SystemSettingsItem';
import Tags from 'components/pages/Tags/Tags';
import UserRoles from 'components/pages/Users/Roles/UserRoles';
import UserSettings from 'components/pages/Users/Settings/UserSettings';

export const BASE_PATH = '/';
export const PAGE_PATH = {
  HOME: 'home',
  SYSTEM_SETTINGS: 'systemSettings',
  SYSTEM_SETTINGS_ITEM: 'systemSettingsItem',
  SYSTEM_GROUPS: 'systemGroups',
  SYSTEM_GROUPS_ITEM: 'systemGroupsItem',
  EXTERNAL_OPERATOR_CONFIGS: 'externalOperatorConfigs',
  EXTERNAL_OPERATOR_CONFIGS_ITEM: 'externalOperatorConfigsItem',
  LOCALIZATIONS: 'localizations',
  TAGS: 'tags',
  MANAGE_TAGS: 'manageTags',
  SYSTEM_TAGS: 'systemTags',
  GAME_TAGS: 'gameTags',
  PROMO_CAMPAIGNS: 'promoCampaigns',
  PROMO_CAMPAIGNS_ITEM: 'promoCampaignsItem',
  TOURNAMENTS: 'tournaments',
  TOURNAMENTS_CAMPAIGNS: 'tournamentsCampaigns',
  TOURNAMENTS_CAMPAIGNS_ITEM: 'tournamentsCampaignsItem',
  PRIZE_DROPS: 'prizeDrops',
  PRIZE_DROPS_CAMPAIGNS: 'prizeDropsCampaigns',
  PRIZE_DROPS_CAMPAIGNS_ITEM: 'prizeDropsCampaignsItem',
  FIXED_CURRENCY: 'fixedCurrency',
  BALANCE_INCREASE: 'balanceIncrease',
  PROFILE: 'profile',
  USER_SETTINGS: 'userSettings',
  USER_ROLES: 'userRoles',
  QUESTS: 'quests',
  QUESTS_CAMPAIGNS: 'questsCampaigns',
  QUESTS_CAMPAIGNS_ITEM: 'questsCampaignsItem',
  QUESTS_EVENT_TEMPLATES: 'questsEventTemplates',
  QUESTS_EVENT_TEMPLATES_ITEM: 'questsEventTemplatesItem',
  QUESTS_TEMPLATES: 'questsTemplates',
  QUESTS_TEMPLATES_ITEM: 'questsTemplatesItem',
  WHEEL_OF_FORTUNE: 'wheelOfFortune',
  WHEEL_OF_FORTUNE_CAMPAIGNS: 'wheelOfFortuneCampaigns',
  WHEEL_OF_FORTUNE_CAMPAIGNS_ITEM: 'wheelOfFortuneCampaignsItem',
  EVENT_LOGS: 'eventLogs',
  GENERATE_INVOICE: 'generateInvoice',
  MASTER_GAME_LIST: 'masterGameList',
  MASTER_GAME_LIST_ITEM: 'masterGameListItem',
  CLIENT_AREA_NEWS: 'clientAreaNews',
  CLIENT_AREA_NEWS_ITEM: 'clientAreaNewsItem',
  CLIENT_AREA_PROMOTIONS: 'clientAreaPromotions',
  CLIENT_AREA_PROMOTIONS_ITEM: 'clientAreaPromotionsItem',
  AUDIT: 'audit',
  RTP_BY_GAME_STATISTICS: 'rtpByGameStatistics',
  ROUND_HISTORY: 'roundHistory',
  ACTIONS_BY_ROUND: 'actionsByRound',
  ACCESS_DENIED: 'accessDenied',
  BET_WIN_DAILY: 'betWinDaily',
  TEST_PLAYERS: 'testPlayers',
  CALLBACK_TEST: 'callbackTest',
  BINGO_REPORTS: 'bingoReports',
  BINGO_PLAYERS_REPORTS: 'bingoPlayersReports',
  INVOICE_GROUPS: 'invoiceGroups',
  INVOICE_GROUP_ITEM: 'invoiceGroupItem',
};

export const Pages = {
  home: {
    path: BASE_PATH + 'home',
    element: Home,
  },
  systemSettings: {
    path: BASE_PATH + 'system/settings',
    element: SystemSettings,
  },
  systemSettingsItem: {
    path: BASE_PATH + 'system/settings/:itemId',
    element: SystemSettingsItem,
  },
  systemGroups: {
    path: BASE_PATH + 'system/groups',
    element: SystemGroups,
  },
  systemGroupsItem: {
    path: BASE_PATH + 'system/groups/:itemId',
    element: SystemGroupsItem,
  },
  externalOperatorConfigs: {
    path: BASE_PATH + 'system/external',
    element: ExternalOperatorConfigs,
  },
  externalOperatorConfigsItem: {
    path: BASE_PATH + 'system/external/:itemId',
    element: ExternalOperatorConfigsItem,
  },
  localizations: {
    path: BASE_PATH + 'games/localizations',
    element: Localizations,
  },
  tags: {
    path: BASE_PATH + 'tag',
    element: Tags,
  },
  manageTags: {
    path: BASE_PATH + 'tag/settings',
    element: Tags,
  },
  systemTags: {
    path: BASE_PATH + 'tag/systems',
    element: Tags,
  },
  gameTags: {
    path: BASE_PATH + 'tag/games',
    element: Tags,
  },
  promoCampaigns: {
    path: BASE_PATH + 'promoCampaigns',
    element: PromoCampaigns,
  },
  promoCampaignsItem: {
    path: BASE_PATH + 'promoCampaigns/:itemId',
    element: PromoCampaignsItem,
  },
  tournaments: {
    path: BASE_PATH + 'tournaments',
    element: Tournaments,
  },
  tournamentsCampaigns: {
    path: BASE_PATH + 'tournaments/campaigns',
    element: Tournaments,
  },
  tournamentsCampaignsItem: {
    path: BASE_PATH + 'tournaments/campaigns/:itemId',
    element: TournamentsCampaignsItem,
  },
  tournamentsTranslations: {
    path: BASE_PATH + 'tournaments/translations',
    element: Tournaments,
  },
  prizeDrops: {
    path: BASE_PATH + 'prize-drops',
    element: PrizeDrops,
  },
  prizeDropsCampaigns: {
    path: BASE_PATH + 'prize-drops/campaigns',
    element: PrizeDrops,
  },
  prizeDropsCampaignsItem: {
    path: BASE_PATH + 'prize-drops/campaigns/:itemId',
    element: PrizeDropsCampaignsItem,
  },
  prizeDropsTranslations: {
    path: BASE_PATH + 'prize-drops/translations',
    element: PrizeDrops,
  },
  fixedCurrency: {
    path: BASE_PATH + 'fixed-currency',
    element: FixedCurrency,
  },
  balanceIncrease: {
    path: BASE_PATH + 'balance-increase',
    element: BalanceIncrease,
  },
  profile: {
    path: BASE_PATH + 'profile',
    element: Profile,
  },
  userSettings: {
    path: BASE_PATH + 'users/settings',
    element: UserSettings,
  },
  userRoles: {
    path: BASE_PATH + 'users/roles',
    element: UserRoles,
  },
  quests: {
    path: BASE_PATH + 'quests',
    element: Quests,
  },
  questsCampaigns: {
    path: BASE_PATH + 'quests/campaigns',
    element: Quests,
  },
  questsCampaignsItem: {
    path: BASE_PATH + 'quests/campaigns/:itemId',
    element: QuestsCampaignsItem,
  },
  questsEventTemplates: {
    path: BASE_PATH + 'quests/event-templates',
    element: Quests,
  },
  questsEventTemplatesItem: {
    path: BASE_PATH + 'quests/event-templates/:itemId',
    element: QuestsEventTemplatesItem,
  },
  questsTemplates: {
    path: BASE_PATH + 'quests/templates',
    element: Quests,
  },
  questsTemplatesItem: {
    path: BASE_PATH + 'quests/templates/:itemId',
    element: QuestsTemplatesItem,
  },
  questsTranslations: {
    path: BASE_PATH + 'quests/translations',
    element: Quests,
  },
  wheelOfFortune: {
    path: BASE_PATH + 'wheel-of-fortune',
    element: WheelOfFortune,
  },
  wheelOfFortuneCampaigns: {
    path: BASE_PATH + 'wheel-of-fortune/campaigns',
    element: WheelOfFortune,
  },
  wheelOfFortuneCampaignsItem: {
    path: BASE_PATH + 'wheel-of-fortune/campaigns/:itemId',
    element: WheelOfFortuneCampaignsItem,
  },
  wheelOfFortuneTranslations: {
    path: BASE_PATH + 'wheel-of-fortune/translations',
    element: WheelOfFortune,
  },
  eventLogs: {
    path: BASE_PATH + 'event-logs',
    element: EventLogs,
  },
  generateInvoice: {
    path: BASE_PATH + 'statistics/generate-invoice',
    element: GenerateInvoice,
  },
  masterGameList: {
    path: BASE_PATH + 'games/master-list/:provider?',
    element: MasterGameList,
  },
  masterGameListItem: {
    path: BASE_PATH + 'games/master-list/:provider/:gameCode',
    element: MasterGameListItem,
  },
  clientAreaNews: {
    path: BASE_PATH + 'client-area/news',
    element: ClientAreaNews,
  },
  clientAreaNewsItem: {
    path: BASE_PATH + 'client-area/news/:itemId',
    element: ClientAreaNewsItem,
  },
  clientAreaPromotions: {
    path: BASE_PATH + 'client-area/promotions/:provider?',
    element: ClientAreaPromotions,
  },
  clientAreaPromotionsItem: {
    path: BASE_PATH + 'client-area/promotions/:provider/:itemId',
    element: ClientAreaPromotionsItem,
  },
  audit: {
    path: BASE_PATH + 'audit',
    element: Audit,
  },
  roundHistory: {
    path: BASE_PATH + 'statistics/round-history',
    element: RoundHistory,
  },
  actionsByRound: {
    path: BASE_PATH + 'statistics/round-history/actions-by-round',
    element: ActionsByRound,
  },
  accessDenied: {
    path: BASE_PATH + 'access-denied',
    element: AccessDenied,
  },
  rtpByGameStatistics: {
    path: BASE_PATH + 'statistic/game/payouts',
    element: RtpByGameStatistics,
  },
  betWinDaily: {
    path: BASE_PATH + 'statistics/bet-win-daily',
    element: BetWinDaily,
  },
  testPlayers: {
    path: BASE_PATH + 'players/test-players',
    element: TestPlayers,
  },
  callbackTest: {
    path: BASE_PATH + 'callback/test',
    element: CallbackTest,
  },
  bingoReports: {
    path: BASE_PATH + 'bingo/reports',
    element: RoundsReports,
  },
  bingoPlayersReports: {
    path: BASE_PATH + 'bingo/players',
    element: PlayersReports,
  },
  invoiceGroups: {
    path: BASE_PATH + 'system/invoice',
    element: InvoiceGroups,
  },
  invoiceGroupItem: {
    path: BASE_PATH + 'system/invoice/:itemId',
    element: InvoiceGroupItem,
  },
};
