import React from 'react';

import PropTypes from 'prop-types';

import { ArrowDropDown } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers-pro';

const propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool,
        ]).isRequired,
      }),
    ]),
  ),
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

const renderMenuItem = (item, data) =>
  data ? item : <MenuItem disabled>No options</MenuItem>;

const Select = ({
  name,
  value,
  error = false,
  handleChange,
  options = [],
  multiple = false,
  required = true,
  label = null,
  disabled = false,
  loading,
}) => (
  <FormControl required={required} error={error}>
    <InputLabel id={`select-${name}-label`}>{label ?? name}</InputLabel>
    <MuiSelect
      multiple={multiple}
      name={name}
      labelId={`select-${name}-label`}
      value={value}
      label={label ?? name}
      disabled={disabled || loading}
      onChange={(event) => handleChange({ name, value: event.target.value })}
      IconComponent={(e) => (
        <Box
          sx={{
            position: 'absolute',
            right: 9,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {loading && (
            <CircularProgress
              size={20}
              sx={{
                position: 'absolute',
                right: 0,
              }}
            />
          )}
          {!loading && (
            <>
              <IconButton
                onClick={() => {
                  handleChange({ name, value: '' });
                }}
                sx={{
                  width: 28,
                  height: 28,
                  visibility: 'hidden',
                  zIndex: 1,
                  position: 'absolute',
                  right: 24,
                }}
                className="ClearIcon"
              >
                <ClearIcon sx={{ width: 20, height: 20 }} />
              </IconButton>
              <ArrowDropDown
                className={e.className}
                sx={{ right: '2px !important' }}
              />
            </>
          )}
        </Box>
      )}
      sx={{
        '&.Mui-focused .ClearIcon': {
          visibility: value.length > 0 ? 'visible' : 'hidden',
        },
        ':hover .ClearIcon': {
          visibility: value.length > 0 ? 'visible' : 'hidden',
        },
        ...(!loading && {
          '.MuiInputBase-input': {
            paddingRight: '55px !important',
          },
        }),
      }}
    >
      {!options?.length
        ? Array.isArray(value)
          ? renderMenuItem(
              value.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              )),
              value.length,
            )
          : renderMenuItem(<MenuItem value={value}>{value}</MenuItem>, value)
        : options.map((option) => {
            const label = typeof option === 'object' ? option.label : option;
            const value = typeof option === 'object' ? option.value : option;
            return (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            );
          })}
    </MuiSelect>
  </FormControl>
);

Select.propTypes = propTypes;
export default Select;
