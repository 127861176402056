export const HelperList = [
  {
    name: 'Game Name',
    text: 'Unique name of the game.',
  },
  {
    name: 'Game Code',
    text: 'A unique game code that will be displayed in the link to the game.',
  },
  {
    name: 'Game ID EU',
    text: 'An identification document for Europe, which is assigned to a game when it is created.',
  },
  {
    name: 'Game ID Asia',
    text: 'An identification document for Asia, which is assigned to a game when it is created.',
  },
  {
    name: 'Provider',
    text: 'The provider to which this game belongs.',
  },
  {
    name: 'Category',
    text: 'Category of the game.',
  },
  {
    name: 'Marker',
    text: 'Additional grouping for quick search in Client Area.',
  },
  {
    name: 'Description',
    text: 'A short description of the game.',
  },
];
