import { api } from 'store/api/api';

export const questTemplateController = api.injectEndpoints({
  endpoints: (build) => ({
    getQuestsTemplates: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v1/quests/templates',
        params,
      }),
      providesTags: () => [{ type: 'QuestsTemplates' }],
    }),
    getQuestsTemplatesItem: build.query({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/quests/templates/${id}`,
      }),
      providesTags: () => [{ type: 'QuestsTemplates' }],
    }),
    getAllQuestsTemplates: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v1/quests/templates/dictionary',
        params,
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, val) => [
            ...acc,
            { label: `${val.id}-${val.name}`, value: val.id },
          ],
          [],
        ),
      providesTags: () => [{ type: 'QuestsTemplates' }],
    }),
    updateQuestsTemplate: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/tournament-service/api/v1/quests/templates${id ? `/${id}` : ''}`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'QuestsTemplates' }],
    }),
    deleteQuestsTemplate: build.mutation({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/quests/templates/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'QuestsTemplates' }],
    }),
  }),
});

export const {
  useGetQuestsTemplatesQuery,
  useGetQuestsTemplatesItemQuery,
  useGetAllQuestsTemplatesQuery,
  useUpdateQuestsTemplateMutation,
  useDeleteQuestsTemplateMutation,
} = questTemplateController;
