import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import {
  useGetGamesQuery,
  useGetSystemListQuery,
} from 'store/api/hhs/recipe.api';
import { useGetRtpGameStatisticQuery } from 'store/api/hhsKeyCloack/analytics';

import { Box, Typography } from '@mui/material';

import DateRange from 'components/UI/DateRange/DateRange';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Table from 'components/UI/Table/Table';

import { ENV_LIST } from 'components/config/analytics';
import { columns } from 'components/config/rtpByGameStatistics';

const requiredFields = [
  'env',
  'date_from',
  'date_to',
  'operator_id',
  'currency',
];

const RtpByGameStatistics = () => {
  const [filter, setFilter] = useState({});

  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery({
      'type[]': 'agregator',
    });
  const { data: dataGames, isLoading: gamesLoading } = useGetGamesQuery();

  const handleChangeFilter = useCallback(
    ({ name, value }) =>
      setFilter((prevState) => {
        const newState = _.cloneDeep(prevState);
        const newValue = typeof value === 'string' ? value.trim() : value;

        !newValue ? _.unset(newState, name) : _.set(newState, name, newValue);

        return newState;
      }),
    [],
  );
  const handleClearFilter = useCallback(() => setFilter({}), []);

  const requestData = useMemo(
    () => requiredFields.every((item) => filter[item]),
    [filter],
  );

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h5" component="h2">
          Rtp by Game Statistics
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
          mb: 2,
        }}
      >
        <FilterBox>
          <Select
            label="ENV"
            name="env"
            value={filter.env ?? ''}
            handleChange={handleChangeFilter}
            options={ENV_LIST}
          />
        </FilterBox>
        <FilterBox>
          <DateRange filter={filter} handleChangeFilter={handleChangeFilter} />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            label="Operator Id"
            name="operator_id"
            value={filter.operator_id ?? null}
            handleChange={handleChangeFilter}
            options={dataSystems || []}
            loading={systemsLoading}
          />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            label="Currency"
            name="currency"
            value={filter.currency ?? null}
            handleChange={handleChangeFilter}
            options={dataCurrency || []}
            loading={currencyLoading}
          />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            label="Game code"
            name="game_code"
            required={false}
            value={filter.game_code ?? null}
            handleChange={handleChangeFilter}
            options={dataGames || []}
            loading={gamesLoading}
          />
        </FilterBox>
        <ClearBtn handleClick={handleClearFilter} filter={filter} />
      </Box>
      {requestData && (
        <Table
          actions={[]}
          getDataQuery={useGetRtpGameStatisticQuery}
          columns={columns}
          queryConditional={{
            ...filter,
            date_from: new Date(filter.date_from).valueOf(),
            date_to: new Date(filter.date_to).valueOf(),
          }}
          editableRow={false}
        />
      )}
    </>
  );
};

export default RtpByGameStatistics;
