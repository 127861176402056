import React, { useCallback } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import { DateFormat } from 'config/dates';

const shortcutsItems = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs.utc();
      return [today.isoWeekday(1), today.isoWeekday(7)];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs.utc();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.isoWeekday(1), prevWeek.isoWeekday(7)];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs.utc();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs.utc();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs.utc();
      const prevMonth = today.subtract(1, 'month');
      return [prevMonth.startOf('month'), prevMonth.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

const propTypes = {
  handleChangeFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    date_from: PropTypes.any,
    date_to: PropTypes.any,
  }).isRequired,
  props: PropTypes.shape({}),
};

const DateRange = ({ handleChangeFilter, filter, props = {} }) => {

  const handleDateChange = useCallback((newValue) => {
    const [startDate, endDate] = newValue;
    
    if (startDate && startDate.isValid()) {
      handleChangeFilter({
        name: 'date_from',
        value: dayjs.utc(startDate).startOf('day').format(),
      });
    }
    
    if (endDate && endDate.isValid()) {
      handleChangeFilter({
        name: 'date_to',
        value: dayjs.utc(endDate).endOf('day').format(),
      });
    }
  }, [handleChangeFilter]);

  return (
    <DateRangePicker
    label="Date Range"
    slots={{ field: SingleInputDateRangeField }}
    format={DateFormat}
    timezone="UTC"
    onChange={handleDateChange}
    slotProps={{
      textField: {
        required: true,
      },
      shortcuts: {
        items: shortcutsItems,
      },
      actionBar: { actions: [] },
    }}
    value={[
      filter.date_from ? dayjs(filter.date_from) : null,
      filter.date_to ? dayjs(filter.date_to) : null,
    ]}
    {...props}
  />
  )
};

DateRange.propTypes = propTypes;
export default DateRange;
