import { hhsKeyCloack } from 'store/api/hhsKeyCloack';
import { reportDownload } from 'utils/reportDownload';

const reports = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getProvidersList: build.query({
      query: () => ({
        url: '/reporting/v1/invoice/providers',
      }),
    }),
    getGGRPerProvider: build.query({
      query: (params = {}) => ({
        url: '/reporting/v1/invoice/ggr-per-provider',
        method: 'POST',
        body: params,
      }),
    }),
    generateInvoice: build.mutation({
      query: ({ name, ...body }) => ({
        url: '/reporting/v1/invoice/download',
        method: 'POST',
        body,
        responseHandler: async (response) =>
          await reportDownload({ response, name, format: 'xlsm' }),
      }),
    }),
  }),
});

export const {
  useGetProvidersListQuery,
  useGetGGRPerProviderQuery,
  useGenerateInvoiceMutation,
} = reports;
