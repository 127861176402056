import { Link } from 'react-router-dom';

import { PAGE_PATH } from './pages';
import dayjs from 'dayjs';

import { bingoReports } from 'store/api/api/bingoService/bingoReports';

import { findPatchPages } from 'utils/pages';

import PartDetails from 'components/pages/Bingo/RoundsReports/PartDetails/PartDetails.jsx';

const getFormatDate = (value) =>
  value ? dayjs(value).format('MMM D, YYYY h:mm A') : ' - ';

export const columns = [
  {
    name: 'Bingo Round ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'Status',
    sortLabel: 'status',
    value: (row) => row.status,
  },
  {
    name: 'Game Config Name (EN)',
    sortLabel: 'gameName',
    value: (row) => row.gameName,
  },
  {
    name: 'Room Name',
    sortLabel: 'roomName',
    value: (row) => row.roomName,
  },
  {
    name: 'Room ID',
    sortLabel: 'roomID',
    value: (row) => row.roomId,
  },
  {
    name: 'Bingo Type',
    sortLabel: 'bingoType',
    value: (row) => row.bingoType,
  },
  {
    name: 'Purchase Date/Time',
    sortLabel: 'roundStartDatetime',
    value: (row) => getFormatDate(row.roundStartDatetime),
  },
  {
    name: 'Playing Date/Time',
    sortLabel: 'roundEndDatetime',
    value: (row) => getFormatDate(row.roundEndDatetime),
  },
  {
    name: 'Round Details',
    value: () => {},
    collapse: {
      id: (row) => row.id,
      data: async (row, dispatch) => {
        return [
          {
            ...(await dispatch(
              bingoReports.endpoints.getReportDetails.initiate({
                round_id: row.id,
              }),
            ).unwrap()),
            bingoRoundId: row.id,
          },
        ];
      },
      columns: [
        {
          name: 'Players',
          value: (data) => (
            <Link
              to={`${findPatchPages(PAGE_PATH.BINGO_PLAYERS_REPORTS)}?round_id=${data.bingoRoundId}`}
            >
              {data.players}
            </Link>
          ),
        },
        {
          name: 'Purchased Tickets',
          value: (data) => data.purchasedTickets,
        },
        {
          name: 'Refunded Tickets',
          value: (data) => data.refundedTickets,
        },
        {
          name: 'Winners',
          value: (data) =>
            (
              <Link
                to={`${findPatchPages(PAGE_PATH.BINGO_PLAYERS_REPORTS)}?round_id=${data.bingoRoundId}`}
              >
                {data.winners}
              </Link>
            ) ?? ' - ',
        },
        {
          name: 'Prize Type',
          value: (data) => data.prizeType,
        },
        {
          name: 'Ticket Price',
          value: (data) => data.ticketPrice,
        },
        {
          name: 'Simple Prize Seed',
          value: (data) => data.simplePrizeSeed,
        },
        {
          name: 'Simple Prize Amount',
          value: (data) => data.simplePrizeAmount,
        },
        {
          name: 'Total Payout',
          value: (data) => data.totalPayout,
        },
        {
          name: 'Part Details',
          value: () => '',
          collapse: {
            id: (row) => row.id,
            data: (row) => [row],
            columns: [
              {
                name: '',
                value: (data) => PartDetails(data),
              },
            ],
          },
        },
      ],
    },
  },
];
