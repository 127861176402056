import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useToggle } from 'hooks/useToggle';

import {
  useGetInvoiceGroupItemQuery,
  useUpdateInvoiceGroupItemMutation,
} from 'store/api/configService/invoiceGroupController';
import { useGetOperatorsListByEnvQuery } from 'store/api/configService/operatorController';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import { InfoOutlined, KeyboardBackspace } from '@mui/icons-material';
import { Box, Button, IconButton, Stack } from '@mui/material';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import NotFound from 'components/pages/NotFound/NotFound';
import { HelperList } from 'components/pages/Systems/InvoiceGroups/helperList';

import { PAGE_PATH } from 'components/config/pages';
import { ENV_BY_STAGE } from 'config/api';

const initialValue = (editItem) =>
  editItem
    ? {
        ...editItem,
        operators: editItem.operatorConfigs
          ? editItem.operatorConfigs.map(({ id }) => id)
          : (editItem.operators ?? []),
      }
    : {
        name: '',
        env: '',
        operators: [],
        description: '',
      };

const InvoiceGroupItem = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { itemId } = useParams();

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const { data: operators, isLoading: operatorsLoading } =
    useGetOperatorsListByEnvQuery(
      { env: itemsValue?.env },
      { skip: !itemsValue?.env },
    );

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetInvoiceGroupItemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [updateOperatorConfig] = useUpdateInvoiceGroupItemMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.INVOICE_GROUPS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value, isCheckValue }) =>
      editItemClass.handleChange({
        name,
        value,
        errors,
        isCheckValue,
      }),
    [errors],
  );

  const handleSubmit = useCallback(
    () =>
      editItemClass.handleSubmit({
        itemsValue,
        textFields: ['name', 'env', 'description'],
        multipleFields: ['operators'],
      }),
    [itemsValue],
  );

  const updateData = useCallback(async () => {
    const result = await updateOperatorConfig({
      ...(editItem?.id && { id: editItem.id }),
      ...itemsValue,
    });

    editItemClass.checkUpdateData(result);
  }, [editItem, itemsValue]);

  useEffect(() => {
    submit && updateData();
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem, itemId));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  if (isError) return <NotFound />;

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}
      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem ? 'Update' : 'Create'} Invoice Group
                {editItem?.name && ` "${editItem.name}"`}
              </Box>
            </Box>
            <IconButton onClick={handleOpenHelperModal}>
              <InfoOutlined />
            </IconButton>
          </Box>

          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'auto',
            }}
          >
            <Text
              name="name"
              value={itemsValue.name}
              label="Group Name"
              required={true}
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value })
              }
              error={!!errors.includes('name')}
            />
            <Select
              name="env"
              value={itemsValue.env}
              label="ENV"
              options={ENV_BY_STAGE}
              handleChange={handleChange}
              error={!!errors.includes('env')}
            />
            <Autocomplete
              name="operators"
              value={itemsValue.operators}
              label="Operators"
              disabled={!itemsValue.env}
              options={operators}
              handleChange={handleChange}
              loading={operatorsLoading}
              error={!!errors.includes('operators')}
              multiple
              selectFilteredOptions
            />
            <Text
              name="description"
              value={itemsValue.description}
              label="Description"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value })
              }
              error={!!errors.includes('description')}
              required={true}
            />
          </Stack>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
          >
            {editItem ? 'Update' : 'Create'}
          </Button>
          {openHelperModal && (
            <HelperModal
              handleClose={handleCloseHelperModal}
              helperList={HelperList}
            />
          )}
        </>
      )}
    </>
  );
};

export default InvoiceGroupItem;
