import { Avatar } from '@mui/material';

export const columns = [
  {
    name: 'ID EU',
    value: (row) =>
      Array.isArray(row.regionId)
        ? row.regionId.find((item) => item?.region === 'EU')?.id
        : row.regionId,
  },
  {
    name: 'ID ASIA',
    value: (row) =>
      Array.isArray(row.regionId)
        ? row.regionId.length > 1
          ? row.regionId.find((item) => item?.region === 'ASIA')?.id
          : row.regionId[0]?.id
        : row.regionId,
  },
  {
    name: 'Name',
    value: (row) => row.name,
  },
  {
    name: 'Game Code',
    value: (row) => row.gameCode,
  },
  {
    name: 'Absolute Name',
    value: (row) => row.absoluteName,
  },
  {
    name: 'Release Date',
    value: (row) => row.releaseDate,
  },
  {
    name: 'Category',
    value: (row) => row.type,
  },
  {
    name: 'RTP',
    value: (row) => row.rtp.join(', '),
  },
  {
    name: 'RTP Options',
    value: (row) => row.rtpOptions.join(', '),
  },
  {
    name: 'Volatility',
    value: (row) => row.volatility,
  },
  {
    name: 'Min Bet, EUR',
    value: (row) => row.monetaryProperties?.minBet,
  },
  {
    name: 'Max Bet, EUR',
    value: (row) => row.monetaryProperties?.maxBet,
  },
  {
    name: 'Max Win, EUR',
    value: (row) => row.monetaryProperties?.maxWin,
  },
  {
    name: 'Max Exposure',
    value: (row) => row.monetaryProperties?.maxMultiplier,
  },
  {
    name: 'Hit Frequency',
    value: (row) => row.hitFrequency.join(', '),
  },
  {
    name: 'Paylines',
    value: (row) => row.paylines,
  },
  {
    name: 'Jurisdictions',
    value: (row) => row.miscellaneous?.jurisdictions.join(', '),
  },
  {
    name: 'Languages',
    value: (row) => row.miscellaneous?.languages.join(', '),
  },
  {
    name: 'Game Format',
    value: (row) => row.gameFormat,
  },
  {
    name: 'Mobile Weight',
    value: (row) => row.mobileWeight,
  },
  {
    name: 'Desktop Weight',
    value: (row) => row.desktopWeight,
  },
  {
    name: 'Icon',
    value: (row) =>
      row.images?.icon?.length > 0 && (
        <Avatar alt="Icon" src={row.images.icon} />
      ),
  },
  {
    name: 'Logo',
    value: (row) =>
      row.images?.logo?.length > 0 && (
        <Avatar alt="Logo" src={row.images.logo} />
      ),
  },
];

export const FEATURES = [
  'Auto Hold',
  'Bets History',
  'Betting Gameplay',
  'Betways Mechanic',
  'Bonus Achievements',
  'Bonus Buy Feature',
  'Bonus Field',
  'Bonus Game',
  'Bonus Symbols',
  'Bonus Zone',
  'Bonuses',
  'Booster',
  'Catch Area',
  'Character Selection',
  'Character Symbols',
  'Characters’ Abilities',
  'Collector Feature',
  'Complexity Level',
  'Dice Game',
  'Double Chance',
  'Double Wild Symbol',
  'Drops and Wins',
  'Expanding Reels Feature',
  'Expanding Symbols',
  'Expanding Wilds',
  'Extra Free Spins',
  'Extra Symbols',
  'Free Spins',
  'Free Spins Options',
  'Full Moon Feature',
  'Grand Reel',
  'Group Wins',
  'Growing Reels',
  'Hands',
  'In-Game Chat',
  'Jackpot Game',
  'Jackpot Symbols',
  'Jackpots',
  'Lucky Trees',
  'Magician Symbol',
  'Manual Mode',
  'Money Symbols',
  'Multiple Bets',
  'Multipliers',
  'Mystery Symbols',
  'Nudging Wild',
  'Pattern',
  'Progress Bar',
  'Progressive Jackpots',
  'Random Event',
  'Random Wilds',
  'Reels Synchronisation',
  'Replace Symbols',
  'Replace Wild',
  'Respin Feature',
  'Risk Level',
  'Shifting Wild Symbols',
  'Side Bets',
  'Skin Shop',
  'Special Wilds',
  'Stack Symbols',
  'Stacked Wild',
  'Sticky Wilds',
  'Symbol Transformation',
  'Third-Person Shooter',
  'Top 100 Leaderboard',
  'Two Bets',
  'Ultra Spin Bonus',
  'Unique Formula',
  'Unlimited Treasures Feature',
  'Walking Wild',
  'Wheel of Fortune',
  'Wild Attack Feature',
  'Wild Multiplier',
];
export const TAGS = [
  '3D',
  'Adventures',
  'Animals',
  'Arabic',
  'Asia',
  'Auction',
  'Aztec',
  'Baccarat',
  'Bingo',
  'Blackjack',
  'Book',
  'Buying Feature',
  'Car',
  'Cards',
  'Carnival',
  'Cartoonish',
  'Chests',
  'Circus/Cabaret',
  'CIS',
  'Classic',
  'Coins',
  'Crash Games',
  'Cross Channel',
  'Dice',
  'Egypt',
  'Farm',
  'Fight',
  'Fire',
  'Fishing',
  'Food',
  'Football',
  'Fruits',
  'Gangsters/Mafia',
  'Girls',
  'Gladiators',
  'Gods',
  'Halloween',
  'Holiday themed',
  'Innovations',
  'Irish themed',
  'Jackpots',
  'Jewellery',
  'Lottery',
  'Magical',
  'Match 3',
  'Mine Sweepers',
  'Money',
  'Mysterious',
  'Mythical',
  'Neon',
  'Nordic',
  'Other',
  'Pirates',
  'Poker',
  'Roulette',
  'Scratch',
  'Sea',
  'Sevens',
  'Spooky',
  'Sport',
  'Sports/Betting',
  'Summer',
  'Sweets',
  'Tetris',
  'Treasures',
  'Vegas',
  'Vikings',
  'Wheels',
  'Wild West',
  'Witches',
];
