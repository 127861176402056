import { testingTool } from 'store/api/testingTool';

const operatorController = testingTool.injectEndpoints({
  endpoints: (build) => ({
    getCheckIdempotency: build.query({
      query: (params) => ({
        url: '/testing-tool/bo/operator/check-idempotency',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const { useGetCheckIdempotencyQuery } = operatorController;
