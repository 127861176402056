import dayjs from 'dayjs';

import { DateTimeFormat } from 'config/dates';

export const columns = [
  {
    name: 'Id',
    value: (row) => row.id,
  },
  {
    name: 'Session Id',
    value: (row) => row.session_id,
  },
  {
    name: 'Operator Id',
    value: (row) => row.operator_id,
  },
  {
    name: 'Game Id',
    value: (row) => row.game_id,
  },
  {
    name: 'Round Id',
    value: (row) => row.round_id,
  },
  {
    name: 'Action Id',
    value: (row) => row.action_id,
  },
  {
    name: 'Player Id',
    value: (row) => row.player_id,
  },
  {
    name: 'State',
    value: (row) => row.state,
  },
  {
    name: 'Callback Version',
    value: (row) => row.callback_version,
  },
  {
    name: 'Bet',
    value: (row) => row.bet,
  },
  {
    name: 'Win',
    value: (row) => row.win,
  },
  {
    name: 'Refund',
    value: (row) => row.refund,
  },
  {
    name: 'Balance',
    value: (row) => row.balance,
  },
  {
    name: 'Currency',
    value: (row) => row.currency,
  },
  {
    name: 'Game Mode Code',
    value: (row) => row.game_mode_code,
  },
  {
    name: 'Final Action',
    value: (row) => row.final_action,
  },
  {
    name: 'Socket Game',
    value: (row) => row.socket_game,
  },
  {
    name: 'Provider Id',
    value: (row) => row.provider_id,
  },
  {
    name: 'Free Spins Left',
    value: (row) => row.freespins_left,
  },
  {
    name: 'Create Date',
    value: (row) =>
      dayjs.utc(row.start_dt.split('.')[0]).format(DateTimeFormat),
  },
  {
    name: 'Update Date',
    value: (row) =>
      dayjs.utc(row.update_dt.split('.')[0]).format(DateTimeFormat),
  },
  {
    name: 'Quote EUR',
    value: (row) => row.quote_eur,
  },
  {
    name: 'Quote User',
    value: (row) => row.quote_user,
  },
  {
    name: 'Request Id',
    value: (row) => row.request_id,
  },
  {
    name: 'Callback Id',
    value: (row) => row.callback_id,
  },
  {
    name: 'Env',
    value: (row) => row.env,
  },
];
