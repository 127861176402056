import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useToggle } from 'hooks/useToggle';

import {
  useGetQuestsEventTemplatesItemQuery,
  useUpdateQuestsEventTemplateMutation,
} from 'store/api/api/tournamentService/eventTemplateController';
import { useGetAllQuestsTemplatesQuery } from 'store/api/api/tournamentService/questTemplateController';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import { InfoOutlined, KeyboardBackspace } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import NotFound from 'components/pages/NotFound/NotFound';
import { HelperList } from 'components/pages/PromoTools/Quests/EventTemplates/helperList';

import { PAGE_PATH } from 'components/config/pages';

const initialValue = (editItem) =>
  editItem
    ? editItem
    : {
        name: '',
        quests: [],
      };

const EventTemplatesItem = () => {
  const dispatch = useDispatch();

  const { itemId } = useParams();
  const navigate = useNavigate();

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetQuestsEventTemplatesItemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const { data: dataQuests, isLoading: questsLoading } =
    useGetAllQuestsTemplatesQuery();

  const [updateEventTemplate] = useUpdateQuestsEventTemplateMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.QUESTS_EVENT_TEMPLATES));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const result = await updateEventTemplate({
      ...(editItem && editItem.id && { id: editItem.id }),
      ...itemsValue,
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue]);

  const handleChange = useCallback(
    ({ name, value }) => {
      editItemClass.handleChange({
        name,
        value,
        errors,
      });
    },
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    await editItemClass.handleSubmit({
      itemsValue,
      textFields: ['name'],
      multipleFields: ['quests'],
    });
  }, [itemsValue, errors]);

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (itemId === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [itemId, editItemStore]);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}

      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem
                  ? editItem.id
                    ? `Update "${editItem.name}"`
                    : `Copy by "${editItem.name}"`
                  : 'Create Event Template'}
              </Box>
            </Box>
            <IconButton onClick={handleOpenHelperModal}>
              <InfoOutlined />
            </IconButton>
          </Box>
          <Box component="div" sx={{ display: 'grid', rowGap: 2, mb: 2 }}>
            <Text
              required
              label="Event Template name"
              name="name"
              fullWidth
              onChange={({ target }) => handleChange(target)}
              error={!!errors.includes('name')}
              value={itemsValue.name}
            />
            <Autocomplete
              label="Quests list"
              name="quests"
              error={!!errors.includes('quests')}
              value={itemsValue.quests}
              handleChange={handleChange}
              options={dataQuests || []}
              loading={questsLoading}
              multiple
            />
          </Box>
          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button onClick={handleSubmit} variant="contained">
              {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
            </Button>
          </Box>
          {openHelperModal && (
            <HelperModal
              handleClose={handleCloseHelperModal}
              helperList={HelperList}
            />
          )}
        </>
      )}
    </>
  );
};

export default EventTemplatesItem;
