import { useSearch } from 'hooks/useSearch';

import { useGetSystemListTableDataQuery } from 'store/api/hhs/recipe.api';

import { Edit } from '@mui/icons-material';
import { TextField } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import AssignDialog from 'components/UI/AssignDialog/AssignDialog';

import { systemTagsColumns } from 'components/config/tags';

const SystemTags = () => {
  const { searchParams, handleSearch } = useSearch();

  return (
    <TableLayout
      title="Manage system tags"
      columns={systemTagsColumns}
      getDataQuery={useGetSystemListTableDataQuery}
      queryConditional={searchParams}
      actions={[{ label: 'Edit', icon: <Edit /> }]}
      dialogComponent={({ editItem, handleClose }) => (
        <AssignDialog
          type="tags"
          title={`Assign tags to game "${editItem.name}"`}
          handleSubmit={() => {}}
          handleClose={handleClose}
        />
      )}
    >
      <TextField
        name="search"
        label="Enter keyword"
        onChange={handleSearch}
        sx={{ alignSelf: 'flex-start', mb: 2 }}
      />
    </TableLayout>
  );
};

export default SystemTags;
