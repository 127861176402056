import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { FilterList as FilterListIcon } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  viewColumns: PropTypes.shape({}).isRequired,
};

const FilterList = ({ handleChange, viewColumns }) => {
  const [open, setOpen] = useState(false);

  const handleFilter = useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleListItemClick = useCallback(
    (item) => {
      handleChange({ ...viewColumns, [item]: !viewColumns[item] });
    },
    [viewColumns],
  );
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Tooltip title="Filter list" placement="top">
        <IconButton
          sx={{ position: 'absolute', bottom: 5, left: 2 }}
          onClick={handleFilter}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Set filter list</DialogTitle>
        <DialogContent>
          <List sx={{ pt: 0, display: 'flex', flexWrap: 'wrap', columnGap: 1 }}>
            {Object.keys(viewColumns).map((item) => (
              <ListItem key={item} disableGutters sx={{ width: 'fit-content' }}>
                <ListItemButton
                  onClick={() => handleListItemClick(item)}
                  selected={!!viewColumns[item]}
                >
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

FilterList.propTypes = propTypes;
export default FilterList;
