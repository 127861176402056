import React from 'react';

import PropTypes from 'prop-types';

import { isLoggedIn } from 'services/UserService';

const propTypes = {
  Component: PropTypes.func.isRequired,
};

const PrivateRoute = ({ Component }) => (isLoggedIn() ? <Component /> : null);

PrivateRoute.propTypes = propTypes;
export default PrivateRoute;
