import React from 'react';

import {
  useDeleteNewsItemMutation,
  useGetNewsQuery,
} from 'store/api/configService/newsController';

import { findPatchPages } from 'utils/pages';

import TableLayout from 'components/Layout/TableLayout';

import { columns } from 'components/config/news';
import { PAGE_PATH } from 'components/config/pages';

const News = () => {
  const [deleteNewsItem] = useDeleteNewsItemMutation();

  return (
    <TableLayout
      columns={columns}
      getDataQuery={useGetNewsQuery}
      title="News and Updates"
      deleteItemQuery={deleteNewsItem}
      itemComponentPath={findPatchPages(PAGE_PATH.CLIENT_AREA_NEWS_ITEM)}
    />
  );
};

export default News;
