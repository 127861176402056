import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetDomainAliasListQuery,
  useGetExternalOperatorConfigQuery,
  useUpdateExternalOperatorConfigMutation,
} from 'store/api/hhsKeyCloack/externalOperatorConfigs';
import { useGetOperatorListQuery } from 'store/api/hhsKeyCloack/operator';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import { KeyboardBackspace } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import NotFound from 'components/pages/NotFound/NotFound';

import { PAGE_PATH } from 'components/config/pages';

const initialValue = (editItem) =>
  editItem
    ? {
        operatorId: editItem.operatorId,
        hhsAlternateDomain: editItem.hhsAlternateDomain ?? '',
        externalOperatorId: editItem.externalOperatorId ?? '',
        apiUrl: editItem.apiUrl ?? '',
        apiKey: editItem.apiKey ?? '',
        apiSecret: editItem.apiSecret ?? '',
        timeZoneId: editItem.timeZoneId ?? '',
      }
    : {
        operatorId: null,
        hhsAlternateDomain: '',
        externalOperatorId: '',
        apiUrl: '',
        apiKey: '',
        apiSecret: '',
        timeZoneId: '',
      };

const ExternalOperatorConfigsItem = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { itemId } = useParams();

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetExternalOperatorConfigQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const { data: operators, isLoading: operatorsLoading } =
    useGetOperatorListQuery();
  const { data: domains, isLoading: domainsLoading } =
    useGetDomainAliasListQuery();

  const [updateOperatorConfig] = useUpdateExternalOperatorConfigMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.EXTERNAL_OPERATOR_CONFIGS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value, isCheckValue }) =>
      editItemClass.handleChange({
        name,
        value,
        errors,
        isCheckValue,
      }),
    [errors],
  );

  const handleSubmit = useCallback(
    () =>
      editItemClass.handleSubmit({
        itemsValue,
        textFields: ['operatorId'],
      }),
    [itemsValue],
  );

  const updateData = useCallback(async () => {
    const result = await updateOperatorConfig({
      ...(editItem?.id && { id: editItem.id }),
      ...itemsValue,
    });

    editItemClass.checkUpdateData(result);
  }, [editItem, itemsValue]);

  useEffect(() => {
    submit && updateData();
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem, itemId));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  if (isError) return <NotFound />;

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}
      {itemsValue && (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mb: 3,
            }}
          >
            <IconButton onClick={handleBack}>
              <KeyboardBackspace />
            </IconButton>
            <Box
              component="h3"
              sx={{
                m: 0,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
              }}
            >
              {editItem ? 'Update' : 'Create'} External operator
              {editItem?.externalOperatorId &&
                ` "${editItem.externalOperatorId}"`}
            </Box>
          </Box>
          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'auto',
            }}
          >
            {editItem?.integrationType && (
              <TextField
                value={editItem.integrationType}
                label="Integration Type"
                disabled
              />
            )}
            <Autocomplete
              name="operatorId"
              value={itemsValue.operatorId}
              label="Operator"
              options={operators}
              handleChange={handleChange}
              error={errors.includes('operatorId')}
              loading={operatorsLoading}
            />
            <Select
              name="hhsAlternateDomain"
              value={itemsValue.hhsAlternateDomain}
              label="HHS Alternate Domain"
              options={domains}
              handleChange={handleChange}
              required={false}
              loading={domainsLoading}
            />
            <Text
              name="externalOperatorId"
              value={itemsValue.externalOperatorId}
              label="External Operator ID"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
            />
            <Text
              name="apiUrl"
              value={itemsValue.apiUrl}
              label="API URL"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
            />
            <Text
              name="apiKey"
              value={itemsValue.apiKey}
              label="API Key"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
            />
            <Text
              name="apiSecret"
              value={itemsValue.apiSecret}
              label="API Secret"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
            />
            <Text
              name="timeZoneId"
              value={itemsValue.timeZoneId}
              label="Timezone"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
            />
          </Stack>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
          >
            {editItem ? 'Update' : 'Create'}
          </Button>
        </>
      )}
    </>
  );
};

export default ExternalOperatorConfigsItem;
