import React from 'react';

import PropTypes from 'prop-types';

import { useGetGamesQuery } from 'store/api/hhs/recipe.api';

import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@mui/material';

import FieldTooltip from 'components/UI/Dialog/FieldTooltip';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import {
  ActiveGame,
  BetAmount,
  ExpirationPeriod,
  FreeRounds,
  GameCode,
  MoneyPrize,
  PrizeContainer,
  PrizeQuantity,
  PrizeType,
  RankFrom,
  RankTo,
  TextPrize,
} from 'components/UI/PrizeSet/StyledComponents';

import { prizeTypes } from 'components/config/prizeSet';

const propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemsValue: PropTypes.shape({
    rankRangeStart: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rankRangeEnd: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    prizeQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    prize: PropTypes.shape({
      prizeType: PropTypes.string,
      freeRoundNumber: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      freeRoundGameCode: PropTypes.string,
      freeRoundExpirationPeriod: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      freeRoundBet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      moneyPrize: PropTypes.string,
      textPrize: PropTypes.string,
      isPrizeForActiveGame: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string,
      ]),
    }).isRequired,
  }).isRequired,
  objectPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf([
    'basic',
    'prize_drops',
    'wheel_of_fortune',
    'tournament',
    'quest_template',
  ]),
  showTooltip: PropTypes.bool,
  mainPrize: PropTypes.bool,
};

const PrizeSet = ({
  errors,
  itemsValue,
  objectPath,
  handleChange,
  type = 'basic',
  showTooltip = true,
  mainPrize,
}) => {
  const { data: dataGames, isLoading: gamesLoading } = useGetGamesQuery();

  return (
    <PrizeContainer
      type={type}
      prizeType={itemsValue.prize.prizeType}
      mainPrize={mainPrize}
    >
      {mainPrize && (
        <Box
          sx={{
            position: 'absolute',
            left: (theme) => theme.spacing(1),
            top: 0,
            transform: 'translateY(-50%)',
            backgroundColor: 'background.default',
          }}
          px={1}
        >
          <Typography variant="body2" color="text.secondary">
            Main Prize
          </Typography>
        </Box>
      )}
      <PrizeType>
        <FieldTooltip
          title="Type of prize for completing the action."
          showTooltip={showTooltip}
        >
          <Select
            label="Prize Type"
            name={`${objectPath}prize.prizeType`}
            error={!!errors.includes(`${objectPath}prize.prizeType`)}
            value={itemsValue.prize.prizeType || ''}
            handleChange={handleChange}
            options={prizeTypes}
          />
        </FieldTooltip>
      </PrizeType>
      {(type === 'prize_drops' || type === 'wheel_of_fortune') && (
        <PrizeQuantity>
          <FieldTooltip
            title="The number of one specific prize for the campaign."
            showTooltip={showTooltip}
          >
            <TextField
              required
              fullWidth
              label="Prize Quantity"
              onChange={(event) =>
                handleChange({
                  name: `${objectPath}prizeQuantity`,
                  value: event.target.value,
                })
              }
              error={!!errors.includes(`${objectPath}prizeQuantity`)}
              value={itemsValue.prizeQuantity}
            />
          </FieldTooltip>
        </PrizeQuantity>
      )}
      {type === 'tournament' && (
        <>
          <RankFrom>
            <FieldTooltip
              title="The initial intermediate position on the tournament leaderboard."
              showTooltip={showTooltip}
            >
              <TextField
                required
                fullWidth
                label="Rank from"
                onChange={(event) =>
                  handleChange({
                    name: `${objectPath}rankRangeStart`,
                    value: event.target.value,
                    type: 'number',
                  })
                }
                error={!!errors.includes(`${objectPath}rankRangeStart`)}
                value={itemsValue.rankRangeStart}
              />
            </FieldTooltip>
          </RankFrom>
          <RankTo>
            <FieldTooltip
              title="The final intermediate position on the tournament leaderboard."
              showTooltip={showTooltip}
            >
              <TextField
                required
                fullWidth
                label="Rank to"
                onChange={(event) =>
                  handleChange({
                    name: `${objectPath}rankRangeEnd`,
                    value: event.target.value,
                    type: 'number',
                  })
                }
                error={!!errors.includes(`${objectPath}rankRangeEnd`)}
                value={itemsValue.rankRangeEnd}
              />
            </FieldTooltip>
          </RankTo>
        </>
      )}
      {itemsValue.prize.prizeType === 'MONEY' && (
        <MoneyPrize>
          <FieldTooltip
            title="The amount of the money prize."
            showTooltip={showTooltip}
          >
            <TextField
              required
              fullWidth
              label="Amount"
              onChange={(event) =>
                handleChange({
                  name: `${objectPath}prize.moneyPrize`,
                  value: event.target.value,
                  type: 'number',
                })
              }
              error={!!errors.includes(`${objectPath}prize.moneyPrize`)}
              defaultValue={itemsValue.prize.moneyPrize}
            />
          </FieldTooltip>
        </MoneyPrize>
      )}
      {itemsValue.prize.prizeType === 'TEXT' && (
        <TextPrize>
          <FieldTooltip
            title="The name of the material prize (maximum 60 characters)."
            showTooltip={showTooltip}
          >
            <TextField
              required
              fullWidth
              label="Text"
              onChange={(event) =>
                handleChange({
                  name: `${objectPath}prize.textPrize`,
                  value: event.target.value,
                  maxLength: 60,
                })
              }
              slotProps={{
                input: { maxLength: 60 },
              }}
              error={!!errors.includes(`${objectPath}prize.textPrize`)}
              defaultValue={itemsValue.prize.textPrize}
            />
          </FieldTooltip>
        </TextPrize>
      )}
      {itemsValue.prize.prizeType === 'FREE_ROUND' && (
        <>
          <FreeRounds>
            <FieldTooltip
              title="The number of Free Rounds for one prize in the relevant game."
              showTooltip={showTooltip}
            >
              <TextField
                required
                fullWidth
                label="Number of Free Rounds"
                onChange={(event) =>
                  handleChange({
                    name: `${objectPath}prize.freeRoundNumber`,
                    value: event.target.value,
                  })
                }
                error={!!errors.includes(`${objectPath}prize.freeRoundNumber`)}
                defaultValue={itemsValue.prize.freeRoundNumber}
              />
            </FieldTooltip>
          </FreeRounds>
          <GameCode>
            <FieldTooltip
              title="The game in which the player will receive a prize."
              showTooltip={showTooltip}
            >
              <Autocomplete
                label="Game name"
                name={`${objectPath}prize.freeRoundGameCode`}
                error={
                  !!errors.includes(`${objectPath}prize.freeRoundGameCode`)
                }
                value={itemsValue.prize.freeRoundGameCode || null}
                handleChange={handleChange}
                options={dataGames || []}
                disabled={!!itemsValue.prize.isPrizeForActiveGame}
                required={!itemsValue.prize.isPrizeForActiveGame}
                loading={gamesLoading}
              />
            </FieldTooltip>
          </GameCode>
          <BetAmount>
            <FieldTooltip
              title="The bet at which Free Rounds are awarded."
              showTooltip={showTooltip}
            >
              <TextField
                required
                fullWidth
                label="Free Rounds bet amount"
                onChange={(event) =>
                  handleChange({
                    name: `${objectPath}prize.freeRoundBet`,
                    value: event.target.value,
                  })
                }
                error={!!errors.includes(`${objectPath}prize.freeRoundBet`)}
                defaultValue={itemsValue.prize.freeRoundBet}
              />
            </FieldTooltip>
          </BetAmount>
          <ExpirationPeriod>
            <FieldTooltip
              title="The validity period of Free Rounds from the moment they are credited to the player (1-999 days)."
              showTooltip={showTooltip}
            >
              <TextField
                label="Expiration Period (days)"
                onChange={(event) =>
                  handleChange({
                    name: `${objectPath}prize.freeRoundExpirationPeriod`,
                    value: event.target.value,
                    type: 'number',
                  })
                }
                error={errors.includes(
                  `${objectPath}prize.freeRoundExpirationPeriod`,
                )}
                defaultValue={itemsValue.prize.freeRoundExpirationPeriod}
                required
              />
            </FieldTooltip>
          </ExpirationPeriod>
          {type !== 'tournament' && (
            <ActiveGame>
              <FieldTooltip
                title="Free Rounds will be calculated in the game in which the user is playing"
                showTooltip={showTooltip}
              >
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={!!itemsValue.prize.isPrizeForActiveGame}
                      onChange={(event) => {
                        handleChange({
                          name: `${objectPath}prize.isPrizeForActiveGame`,
                          value: event.target.checked,
                        });
                        handleChange({
                          name: `${objectPath}prize.freeRoundGameCode`,
                          value: '',
                          isCheckValue: false,
                        });
                      }}
                    />
                  }
                  label="Active Game"
                  name={`${objectPath}prize.isPrizeForActiveGame`}
                  labelPlacement="end"
                  sx={{ ml: 0 }}
                />
              </FieldTooltip>
            </ActiveGame>
          )}
        </>
      )}
    </PrizeContainer>
  );
};

PrizeSet.propTypes = propTypes;
export default PrizeSet;
