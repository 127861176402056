import { cr } from 'store/api/cr';

const currencyRates = cr.injectEndpoints({
  endpoints: (build) => ({
    getCurrencyRates: build.query({
      query: (params) => ({
        url: '/cs/api/v1/client/currency-rates',
        params,
      }),
      transformResponse: (response) =>
        response?.data?.rates.reduce(
          (acc, val) => [...acc, { label: val.code, value: val.code }],
          [],
        ),
    }),
  }),
});

export const { useGetCurrencyRatesQuery } = currencyRates;
