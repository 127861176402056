import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { useGetCheckIdempotencyQuery } from 'store/api/testingTool/bo/operator';

import { Box, Typography } from '@mui/material';

import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import TableSimple from 'components/UI/Table/TableSimple';

const initFilter = {
  operatorCode: '',
  userToken: '',
  callbackVersion: '',
  amount: '',
  currency: null,
};

const CallbackTest = () => {
  const [filter, setFilter] = useState(initFilter);
  const isEmptyFilter = useMemo(() => _.isEqual(filter, initFilter), [filter]);

  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const {
    data: dataCheckIdempotency,
    isLoading,
    isFetching,
  } = useGetCheckIdempotencyQuery(filter, {
    skip: Object.keys(filter).find((item) => filter[item] === initFilter[item])
      ?.length,
  });

  const handleChangeFilter = useCallback(({ name, value }) => {
    setFilter((prevState) => {
      const newState = _.cloneDeep(prevState);
      const newValue = typeof value === 'string' ? value.trim() : value;
      _.set(newState, name, newValue);
      return newState;
    });
  }, []);
  const handleClearFilter = useCallback(() => {
    setFilter(initFilter);
  }, []);

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
        component="div"
      >
        <Typography variant="h5" component="h2">
          Callback Test
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          mb: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <FilterBox>
          <Text
            label="Operator Code"
            name="operatorCode"
            onChange={(event) =>
              handleChangeFilter({
                name: 'operatorCode',
                value: event.target.value,
              })
            }
            fullWidth
            required
            value={filter.operatorCode}
          />
        </FilterBox>
        <FilterBox>
          <Text
            label="Token"
            name="userToken"
            onChange={(event) =>
              handleChangeFilter({
                name: 'userToken',
                value: event.target.value,
              })
            }
            fullWidth
            required
            value={filter.userToken}
          />
        </FilterBox>
        <FilterBox>
          <Select
            label="Callback version"
            name="callbackVersion"
            value={filter.callbackVersion}
            handleChange={handleChangeFilter}
            options={['1', '2', '3']}
          />
        </FilterBox>
        <FilterBox>
          <Text
            label="Amount"
            name="amount"
            onChange={(event) =>
              handleChangeFilter({
                name: 'amount',
                value: event.target.value,
              })
            }
            fullWidth
            required
            value={filter.amount}
          />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            label="Currency"
            name="currency"
            value={filter.currency}
            handleChange={handleChangeFilter}
            options={dataCurrency || []}
            loading={currencyLoading}
          />
        </FilterBox>
        {!isEmptyFilter && <ClearBtn handleClick={handleClearFilter} />}
      </Box>
      {(isLoading || isFetching) && <FullPageLoader />}
      {!isEmptyFilter && (
        <>
          {dataCheckIdempotency?.initTestResult && (
            <TableSimple
              data={dataCheckIdempotency.initTestResult}
              title={'Init Test'}
            />
          )}
          {dataCheckIdempotency?.betTestResult && (
            <TableSimple
              data={dataCheckIdempotency.betTestResult}
              title={'Bet Test Result'}
            />
          )}
          {dataCheckIdempotency?.winTestResult && (
            <TableSimple
              data={dataCheckIdempotency.winTestResult}
              title={'Win Test Result'}
            />
          )}
          {dataCheckIdempotency?.refundTestResult && (
            <TableSimple
              data={dataCheckIdempotency.refundTestResult}
              title={'Refund Test Result'}
            />
          )}
        </>
      )}
    </>
  );
};

export default CallbackTest;
