import dayjs from 'dayjs';

import { DateFormat } from 'config/dates';

export const columns = [
  {
    name: 'ENV',
    value: (row) => row.env,
  },
  {
    name: 'Date',
    value: (row) => dayjs.utc(row.date).format(DateFormat),
  },
  {
    name: 'Operator Id',
    value: (row) => row.operator_id,
  },
  {
    name: 'Operator',
    value: (row) => row.operator,
  },
  {
    name: 'Currency',
    value: (row) => row.currency,
  },
  {
    name: 'Game Code',
    value: (row) => row.game_code,
  },
  {
    name: 'Game Mode Code',
    value: (row) => row.game_mode_code,
  },
  {
    name: 'Provider Id',
    value: (row) => row.provider_id,
  },
  {
    name: 'Provider',
    value: (row) => row.provider,
  },
  {
    name: 'Game Type',
    value: (row) => row.game_type,
  },
  {
    name: 'Rounds Count',
    value: (row) => row.rounds_count,
  },
  {
    name: 'Bet Sum',
    value: (row) => row.bet_sum,
  },
  {
    name: 'Win Sum',
    value: (row) => row.win_sum,
  },
  {
    name: 'Bet Sum USD',
    value: (row) => row.bet_sum_usd,
  },
  {
    name: 'Win Sum USD',
    value: (row) => row.win_sum_usd,
  },
  {
    name: 'Bet Sum EUR',
    value: (row) => row.bet_sum_eur,
  },
  {
    name: 'Win Sum EUR',
    value: (row) => row.win_sum_eur,
  },
  {
    name: 'Refund Sum',
    value: (row) => row.refund_sum,
  },
  {
    name: 'Refund Sum USD',
    value: (row) => row.refund_sum_usd,
  },
  {
    name: 'Refund Sum EUR',
    value: (row) => row.refund_sum_eur,
  },
];
