import React, { useCallback, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import dayjs from 'dayjs';
import _ from 'lodash';

import { useGetActionsByRoundQuery } from 'store/api/hhsKeyCloack/analytics';

import { findPatchPages } from 'utils/pages';

import { KeyboardBackspace, Visibility } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import DateRange from 'components/UI/DateRange/DateRange';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import Table from 'components/UI/Table/Table';
import Dialog from 'components/pages/Statistics/RoundHistory/ActionsByRound/Dialog';

import { columns } from 'components/config/actionsByRound';
import { ENV_LIST } from 'components/config/analytics';
import { PAGE_PATH } from 'components/config/pages';

const FilterBox = styled('div')`
  width: 250px;

  > div {
    width: 100%;
  }
`;

const ActionsByRound = () => {
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState({
    round_id: searchParams.get('round_id'),
    env: searchParams.get('env'),
    ...(searchParams.get('date_from') && {
      date_from: dayjs.utc(searchParams.get('date_from')),
      date_to: dayjs.utc(),
    }),
  });
  const [action, setAction] = useState(null);
  const navigate = useNavigate();

  const handleChangeFilter = useCallback(
    ({ name, value }) =>
      setFilter((prevState) => {
        const newState = _.cloneDeep(prevState);
        const newValue = typeof value === 'string' ? value.trim() : value;
        if (newValue === null || newValue === undefined || newValue === '') {
          _.unset(newState, name);
        } else {
          _.set(newState, name, newValue);
        }
        return newState;
      }),
    [],
  );
  const handleClearFilter = useCallback(() => setFilter({}), []);
  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.ROUND_HISTORY));
  }, []);
  const handleShowDetails = useCallback((action) => setAction(action), []);
  const handleCloseDetails = useCallback(() => setAction(null), []);

  return (
    <>
      <Box
        sx={{ display: 'flex', columnGap: 2, mb: 2, alignItems: 'center' }}
        component="div"
      >
        <IconButton onClick={handleBack}>
          <KeyboardBackspace />
        </IconButton>
        <Box>
          <Typography variant="h5" component="h2">
            Actions by Round
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Indicates the state of the last action, especially useful for
            reviewing errors. Please also refer to the &apos;Details&apos;
            field.
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          mb: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <FilterBox>
          <Select
            label="ENV"
            name="env"
            value={filter.env ?? ''}
            handleChange={handleChangeFilter}
            options={ENV_LIST}
          />
        </FilterBox>
        <FilterBox>
          <Text
            label="Round Id"
            name="round_id"
            onChange={(event) =>
              handleChangeFilter({
                name: 'round_id',
                value: event.target.value,
              })
            }
            fullWidth
            value={filter.round_id ?? ''}
            required={true}
          />
        </FilterBox>
        <FilterBox>
          <DateRange filter={filter} handleChangeFilter={handleChangeFilter} />
        </FilterBox>
        <ClearBtn handleClick={handleClearFilter} filter={filter} />
      </Box>
      {filter.round_id && filter.env && (
        <Table
          actions={[
            {
              label: 'Show Details',
              icon: <Visibility />,
              handleClick: handleShowDetails,
            },
          ]}
          getDataQuery={useGetActionsByRoundQuery}
          columns={columns}
          queryConditional={{
            ...filter,
            ...(filter.date_from && {
              date_from: new Date(filter.date_from).valueOf(),
            }),
            ...(filter.date_to && {
              date_to: new Date(filter.date_to).valueOf(),
            }),
          }}
          editableRow={false}
          showPagination={false}
        />
      )}
      {action && <Dialog handleClose={handleCloseDetails} action={action} />}
    </>
  );
};

export default ActionsByRound;
