import React from 'react';

import PropTypes from 'prop-types';

import { InfoOutlined } from '@mui/icons-material';
import { Box, Tooltip, tooltipClasses } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const propTypes = {
  placement: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showTooltip: PropTypes.bool,
};

const Wrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  columnGap: 8,
  alignItems: 'center',
});

const FieldTooltip = ({
  children,
  placement = 'top',
  showTooltip = true,
  ...props
}) =>
  !showTooltip ? (
    <Box component="div" sx={{ display: 'grid' }}>
      {children}
    </Box>
  ) : (
    <Wrapper>
      {children}
      <Tooltip
        placement={placement}
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                {
                  marginTop: '5px',
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                {
                  marginBottom: '5px',
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                {
                  marginLeft: '5px',
                },
              [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                {
                  marginRight: '5px',
                },
            },
          },
        }}
        {...props}
      >
        <InfoOutlined sx={{ color: grey[600] }} fontSize="small" />
      </Tooltip>
    </Wrapper>
  );

FieldTooltip.propTypes = propTypes;
export default FieldTooltip;
