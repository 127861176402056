export const HelperList = [
  {
    name: 'Event Template name',
    text: 'The name, which will be displayed in the list of other campaigns in the BO.',
  },
  {
    name: 'Quests list',
    text: 'The list of quests related to this event.',
  },
];
