import dayjs from 'dayjs';

import { Avatar } from '@mui/material';

import { DateTimeFormat } from 'config/dates';

export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: ({ id }) => id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: ({ name }) => name,
  },
  {
    name: 'Image',
    value: ({ mediumBanner }) => <Avatar alt="Icon" src={mediumBanner} />,
  },
  {
    name: 'Base Currency',
    value: ({ monetaryConfig }) => monetaryConfig.baseCurrency,
  },
  {
    name: 'Min bet for Participating',
    sortLabel: 'monetaryConfig.minimalBet',
    value: ({ monetaryConfig }) => monetaryConfig.minimalBet,
  },
  {
    name: 'Event prize pool',
    value: ({ monetaryConfig }) => monetaryConfig.eventPrizePool,
  },
  {
    name: 'Campaign prize pool',
    value: ({ monetaryConfig }) => monetaryConfig.campaignPrizePool,
  },
  {
    name: 'Participated Games',
    sortLabel: 'gameCodes',
    value: ({ gameCodes }) => gameCodes.length,
  },
  {
    name: 'Operators',
    sortLabel: 'operatorCodes',
    value: ({ operatorCodes }) => operatorCodes.length,
  },
  {
    name: 'Start',
    sortLabel: 'recurringRule.activeStatusStartDateTime',
    value: ({ recurringRule }) =>
      dayjs.utc(recurringRule.activeStatusStartDateTime).format(DateTimeFormat),
  },
  {
    name: 'End',
    value: ({ recurringRule }) =>
      dayjs
        .utc(recurringRule.activeStatusFinishDateTime)
        .format(DateTimeFormat),
  },
];
