import StorageCollection from 'utils/storage/StorageCollection';

const StorageKey = 'tableState';
const StorageData = {
  viewColumns: {},
  orderBy: {},
  page: {},
  rowsPerPage: {},
};
const storage = new StorageCollection(StorageKey, StorageData);
export const getStorageData = () => storage.getAll();
export const setStorageData = (data) => storage.add(data);
