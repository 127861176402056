import React from 'react';

import {
  useGetRoleGroupsQuery,
  useGetSystemGroupListQuery,
  useGetUsersListQuery,
} from 'store/api/hhs/users.api';

const UserSettings = () => {
  const { data: dataSystemsGroup } = useGetSystemGroupListQuery();
  const { data: dataUsers } = useGetUsersListQuery();
  const { data: dataRoleGroups } = useGetRoleGroupsQuery();

  console.log(dataSystemsGroup);
  console.log(dataUsers);
  console.log(dataRoleGroups);

  return <div>Settings</div>;
};

export default UserSettings;
