import React, { useCallback, useState } from 'react';

import { useSearch } from 'hooks/useSearch';

import { useGetFixedCurrenciesQuery } from 'store/api/cs/fixedCurrencyController';

import { Edit } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import Table from 'components/UI/Table/Table';
import Dialog from 'components/pages/PromoTools/FixedCurrency/Dialog';

import { columns } from 'components/config/fixedCurrency';

const FixedCurrency = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [filterRateDifferent, setFilterRateDifferent] = useState(false);

  const { searchParams, handleSearch } = useSearch();

  const handleEdit = useCallback((item) => {
    setEditItem(item);
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setEditItem(null);
  }, []);
  const customizeRow = useCallback(
    ({ rateDifferent }) =>
      rateDifferent
        ? {
            sx: {
              backgroundColor:
                theme.palette.mode === 'light' ? '#FFFF00' : '#CC7722',
            },
          }
        : {},
    [],
  );

  const changeFilterRateDifferent = useCallback(
    ({ target: { checked } }) => setFilterRateDifferent(checked),
    [],
  );

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          Fixed Currency Rates
        </Typography>
        <Typography variant="subtitle1" component="h3">
          The fixed rate is applied for the calculation and display of prizes in
          PROMO CAMPAIGNS. Changes in the exchange rate will affect active
          campaigns.
        </Typography>
      </Box>
      <Stack direction="row" gap={1} alignItems="center" sx={{ mb: 2 }}>
        <FormControlLabel
          label="Big rates diff only"
          control={
            <Switch
              color="primary"
              checked={filterRateDifferent}
              onChange={changeFilterRateDifferent}
            />
          }
        />
        <TextField name="code" label="Search by Code" onChange={handleSearch} />
      </Stack>
      <Table
        actions={[{ label: 'Edit', icon: <Edit /> }]}
        getDataQuery={useGetFixedCurrenciesQuery}
        queryConditional={{
          ...searchParams,
          ...(filterRateDifferent
            ? {
                query: `rateDifferent=${filterRateDifferent};`,
              }
            : {}),
        }}
        columns={columns}
        handleEdit={handleEdit}
        customizeRow={customizeRow}
      />
      {open && <Dialog handleClose={handleClose} editItem={editItem} />}
    </>
  );
};

export default FixedCurrency;
