import { useCallback, useState } from 'react';

import _debounce from 'lodash/debounce';

import {
  useDeleteLocalizationsItemMutation,
  useGetLocalizationsQuery,
} from 'store/api/api/localizationService/localizationsController';

import { Box, TextField } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import Dialog from 'components/pages/Games/Localizations/Dialog';

import { columns } from 'components/config/localizations';

const Localizations = () => {
  const [searchParams, setSearchParams] = useState({});

  const [deleteLocalization] = useDeleteLocalizationsItemMutation();

  const handleSearchChange = useCallback(
    _debounce(
      ({ target: { name, value } }) =>
        setSearchParams((prev) => ({
          ...prev,
          [name]: value,
        })),
      500,
    ),
    [],
  );

  return (
    <TableLayout
      columns={columns}
      getDataQuery={useGetLocalizationsQuery}
      deleteItemQuery={deleteLocalization}
      queryConditional={searchParams}
      title="Localizations"
      dialogComponent={(props) => <Dialog {...props} />}
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          name="groupCode"
          label="Search by code"
          onChange={handleSearchChange}
        />
        <TextField
          name="fieldCode"
          label="General search"
          onChange={handleSearchChange}
        />
      </Box>
    </TableLayout>
  );
};

export default Localizations;
