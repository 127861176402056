import { useState, useCallback } from 'react';

export const useToggle = (initialState = false) => {
  const [opened, setOpened] = useState(initialState);

  const handleOpen = useCallback(() => setOpened(true), []);

  const handleClose = useCallback(() => setOpened(false), []);

  const handleToggle = useCallback(() => setOpened((prev) => !prev), []);

  return {
    opened,
    handleOpen,
    handleClose,
    handleToggle,
  };
};
