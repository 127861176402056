export const convertToTitle = (str) =>
  str
    ? str.replace(/([A-Z])/g, ' $1').replace(/^./, (c) => c.toUpperCase())
    : '';

export const columns = [
  {
    name: 'Player ID',
    value: (row) => row.userId,
  },
  {
    name: 'Operator ID',
    sortLabel: 'operatorId',
    value: (row) => row.operatorId,
  },
  {
    name: 'Nickname',
    value: (row) => row.nickName,
  },
  {
    name: 'Purchased tickets',
    value: (row) => row.purchasedTickets,
  },
  {
    name: 'Refunded tickets',
    value: (row) => row.refundedTickets,
  },
  {
    name: 'Won tickets',
    value: (row) => row.wonTickets,
  },
  {
    name: 'Bets',
    value: (row) => (row.betsSum ? `${row.betsSum} ${row.currency}` : '-'),
  },
  {
    name: 'Refunds',
    value: (row) =>
      row.refundsSum ? `${row.refundsSum} ${row.currency}` : '-',
  },
  {
    name: 'Wins',
    sortLabel: 'winsSum',
    value: (row) => (row.winsSum ? `${row.winsSum} ${row.currency}` : '-'),
  },
];
