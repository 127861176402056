import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { useGetSystemListQuery } from 'store/api/hhs/recipe.api';
import { useCreateTestPlayersMutation } from 'store/api/hhsKeyCloack/testPlayers';

import { EditItem } from 'utils/EditItem';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

const initialValue = {
  operatorId: null,
  playerId: '',
};

const Dialog = ({ handleClose }) => {
  const dispatch = useDispatch();

  const [itemsValue, setItemsValue] = useState(initialValue);

  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery({
      'type[]': 'agregator',
    });

  const [createTestPlayers] = useCreateTestPlayersMutation();

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleClose,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value, isCheckValue }) =>
      editItemClass.handleChange({
        name,
        value,
        errors,
        isCheckValue,
      }),
    [errors],
  );

  const handleSubmit = useCallback(
    () =>
      editItemClass.handleSubmit({
        itemsValue,
        textFields: ['operatorId', 'playerId'],
      }),
    [itemsValue],
  );

  const updateData = useCallback(async () => {
    const result = await createTestPlayers(itemsValue);

    editItemClass.checkUpdateData(result);
  }, [itemsValue]);

  useEffect(() => {
    submit && updateData();
  }, [submit]);

  return (
    <MuiDialog maxWidth="md" open={true} fullWidth>
      <DialogTitle>Create new game user</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {process && <FullPageLoader />}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 1 }}>
          <Autocomplete
            name="operatorId"
            value={itemsValue.operatorId}
            label="System"
            options={dataSystems}
            handleChange={handleChange}
            error={errors.includes('operatorId')}
            loading={systemsLoading}
          />
          <Text
            name="playerId"
            value={itemsValue.playerId}
            label="Agregator user id"
            onChange={({ target: { name, value } }) =>
              handleChange({ name, value, isCheckValue: false })
            }
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Create
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

Dialog.propTypes = {
  editItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    operatorId: PropTypes.number.isRequired,
    hhsAlternateDomain: PropTypes.string,
    externalOperatorId: PropTypes.string,
    apiUrl: PropTypes.string,
    apiKey: PropTypes.string,
    apiSecret: PropTypes.string,
    timeZoneId: PropTypes.string,
  }),
  handleClose: PropTypes.func.isRequired,
};

export default Dialog;
