import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useSearch } from 'hooks/useSearch';

import {
  useDeleteQuestsTemplateMutation,
  useGetQuestsTemplatesQuery,
} from 'store/api/api/tournamentService/questTemplateController';
import { setEditItem } from 'store/slices/table';

import { findPatchPages } from 'utils/pages';

import { Delete, Difference, Edit } from '@mui/icons-material';
import { TextField } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';

import { PAGE_PATH } from 'components/config/pages';
import { templatesColumns } from 'components/config/questsTemplates';

const QuestTemplates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [deleteQuest] = useDeleteQuestsTemplateMutation();

  const { searchParams, handleSearch } = useSearch();

  const handleCopy = useCallback((row) => {
    dispatch(setEditItem(row));
    navigate(
      findPatchPages(PAGE_PATH.QUESTS_TEMPLATES_ITEM).replace(
        ':itemId',
        'copy',
      ),
    );
  }, []);

  return (
    <TableLayout
      actions={[
        { label: 'Edit', icon: <Edit /> },
        { label: 'Delete', icon: <Delete /> },
        {
          label: 'Copy',
          icon: <Difference />,
          handleClick: handleCopy,
        },
      ]}
      columns={templatesColumns}
      getDataQuery={useGetQuestsTemplatesQuery}
      queryConditional={searchParams}
      title="Quest Templates"
      deleteItemQuery={deleteQuest}
      itemComponentPath={findPatchPages(PAGE_PATH.QUESTS_TEMPLATES_ITEM)}
    >
      <TextField
        name="name"
        label="Search by Name"
        onChange={handleSearch}
        sx={{ alignSelf: 'flex-start', mb: 2 }}
      />
    </TableLayout>
  );
};

export default QuestTemplates;
