import { createSlice } from '@reduxjs/toolkit';
import { getStorageData, setStorageData } from 'utils/storage/table';

const initialState = {
  viewColumns: getStorageData().viewColumns,
  orderBy: getStorageData().orderBy,
  page: getStorageData().page,
  rowsPerPage: getStorageData().rowsPerPage,
  editItem: null,
};

export const tableMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);

    if (
      [
        'table/initialConfig',
        'table/filterColumns',
        'table/changePage',
        'table/changeRowsPerPage',
        'table/changeOrder',
      ].includes(result.type)
    ) {
      setStorageData({
        viewColumns: getState().table.viewColumns,
        orderBy: getState().table.orderBy,
        page: getState().table.page,
        rowsPerPage: getState().table.rowsPerPage,
      });
    }

    return result;
  };
};

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    initialConfig: (state, action) => {
      const { columnsName, viewColumns, orderBy, rowsPerPage } = action.payload;

      state.viewColumns[columnsName] = viewColumns;
      state.orderBy[columnsName] = orderBy;
      state.rowsPerPage[columnsName] = rowsPerPage;
      state.page[columnsName] = 0;
    },
    filterColumns: (state, action) => {
      const { columnsName, viewColumns } = action.payload;
      state.viewColumns[columnsName] = viewColumns;
    },
    changePage: (state, action) => {
      const { columnsName, page } = action.payload;
      state.page[columnsName] = page;
    },
    changeRowsPerPage: (state, action) => {
      const { columnsName, rowsPerPage } = action.payload;
      state.rowsPerPage[columnsName] = rowsPerPage;
      state.page[columnsName] = 0;
    },
    changeOrder: (state, action) => {
      const { columnsName, orderBy } = action.payload;
      state.orderBy[columnsName] = orderBy;
      state.page[columnsName] = 0;
    },
    setEditItem: (state, action) => {
      state.editItem = action.payload;
    },
  },
});

export const tableSelector = {
  getViewColumns: (columnsName) => (state) =>
    state.table.viewColumns[columnsName],
  getOrderBy: (columnsName) => (state) => state.table.orderBy[columnsName],
  getRowsPerPage: (columnsName) => (state) =>
    state.table.rowsPerPage[columnsName],
  getPage: (columnsName) => (state) => state.table.page[columnsName],
  getEditItem: (state) => state.table.editItem,
};

export const {
  initialConfig,
  filterColumns,
  changePage,
  changeRowsPerPage,
  changeOrder,
  setEditItem,
} = tableSlice.actions;
export default tableSlice.reducer;
