import { configService } from 'store/api/configService/index';

import { reportDownload } from 'utils/reportDownload';

const internalGameController = configService.injectEndpoints({
  endpoints: (build) => ({
    getGamesList: build.query({
      query: (params) => ({
        url: '/config-service/admin/v1/games',
        params,
      }),
      providesTags: () => [{ type: 'GamesList' }],
    }),
    getMasterGameItem: build.query({
      query: ({ id }) => ({
        url: `/config-service/admin/v1/games/${id}`,
      }),
      providesTags: () => [{ type: 'GamesList' }],
    }),
    deleteMasterGameItem: build.mutation({
      query: ({ id }) => ({
        url: `/config-service/admin/v1/games/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'GamesList' }],
    }),
    updateMasterGameItem: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/config-service/admin/v1/games${id ? `/${id}` : ''}`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'GamesList' }],
    }),
    downloadMasterGameList: build.query({
      query: () => ({
        url: `/config-service/admin/v1/games/download`,
        cache: 'no-cache',
        responseHandler: async (response) =>
          await reportDownload({ response, name: 'MasterGameList' }),
      }),
    }),
    downloadCertificateGame: build.query({
      query: ({ id, gameCode }) => ({
        url: `/config-service/admin/v1/games/download/cert`,
        params: { game_id: id },
        cache: 'no-cache',
        responseHandler: async (response) =>
          await reportDownload({ response, name: gameCode }),
      }),
    }),
  }),
});

export const {
  useGetGamesListQuery,
  useGetMasterGameItemQuery,
  useUpdateMasterGameItemMutation,
  useDeleteMasterGameItemMutation,
  useDownloadMasterGameListQuery,
  useDownloadCertificateGameQuery,
} = internalGameController;
