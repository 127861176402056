import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useGetSystemGroupQuery,
  useUpdateSystemGroupMutation,
} from 'store/api/hhsKeyCloack/systemGroups';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import { KeyboardBackspace } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
} from '@mui/material';

import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import NotFound from 'components/pages/NotFound/NotFound';

import { PAGE_PATH } from 'components/config/pages';

const initialState = {
  name: '',
  comment: '',
  disabled: false,
};

const initialValue = (editItem) =>
  editItem
    ? {
        name: editItem.name,
        comment: editItem.comment ?? '',
        disabled: !!editItem.disabled,
      }
    : initialState;

const SystemGroupsItem = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { itemId } = useParams();

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetSystemGroupQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [updateSystemGroup] = useUpdateSystemGroupMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.SYSTEM_GROUPS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value }) =>
      editItemClass.handleChange({
        name,
        value,
        errors,
      }),
    [errors],
  );

  const handleSubmit = useCallback(
    () =>
      editItemClass.handleSubmit({
        itemsValue,
        textFields: ['name'],
      }),
    [itemsValue, editItem],
  );

  const updateData = useCallback(async () => {
    const result = await updateSystemGroup({
      ...(editItem?.id && { id: editItem.id }),
      ...itemsValue,
    });

    editItemClass.checkUpdateData(result);
  }, [editItem, itemsValue]);

  useEffect(() => {
    submit && updateData();
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem, itemId));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  if (isError) return <NotFound />;

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}
      {itemsValue && (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mb: 3,
            }}
          >
            <IconButton onClick={handleBack}>
              <KeyboardBackspace />
            </IconButton>
            <Box
              component="h3"
              sx={{
                m: 0,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
              }}
            >
              {editItem
                ? `Update System Group "${editItem.name}"`
                : 'Create System Group'}
            </Box>
          </Box>
          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'auto',
            }}
          >
            <Text
              name="name"
              label="Name"
              value={itemsValue.name}
              onChange={({ target }) => handleChange(target)}
              error={errors.includes('name')}
              required
            />
            <Text
              name="comment"
              label="Comment"
              value={itemsValue.comment}
              onChange={({ target }) => handleChange(target)}
              error={errors.includes('comment')}
              rows={2}
              multiline
            />
            <FormControlLabel
              control={
                <Switch
                  name="disabled"
                  checked={itemsValue.disabled}
                  onChange={({ target: { name, checked } }) =>
                    handleChange({ name, value: checked })
                  }
                />
              }
              label="Disabled"
              sx={{ mx: 0 }}
            />
          </Stack>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
          >
            {editItem ? 'Update' : 'Create'}
          </Button>
        </>
      )}
    </>
  );
};

export default SystemGroupsItem;
