import { hhs } from 'store/api/hhs';

const usersApi = hhs.injectEndpoints({
  endpoints: (build) => ({
    getSystemGroupList: build.query({
      query: () => ({
        url: '/api/system/group/list',
      }),
    }),
    getUsersList: build.query({
      query: (params = {}) => ({
        url: '/api/user/data',
        method: 'POST',
        body: params,
      }),
    }),
    getUsers: build.query({
      query: (params = {}) => ({
        url: '/api/users',
        method: 'POST',
        body: params,
      }),
    }),
    getRoleGroups: build.query({
      query: (params = {}) => ({
        url: '/api/role/groups',
        method: 'POST',
        body: params,
      }),
    }),
  }),
});

export const {
  useGetSystemGroupListQuery,
  useGetUsersListQuery,
  useGetUsersQuery,
  useGetRoleGroupsQuery,
} = usersApi;
