export const HelperList = [
  {
    name: 'Quest name',
    text: 'The name, which will be displayed in the list of quest in the Bonus Room.',
  },
  {
    name: 'Game name',
    text: 'The game in which this quest will be available.',
  },
  {
    name: 'Base currency',
    text: 'The main currency in which the campaign will be conducted.',
  },
  {
    name: 'Min. bet to participating',
    text: 'The minimum bet to participate in this quest.',
  },
  {
    name: 'Scoring rule',
    text: 'The type of rules, depending on the task of the quest.',
  },
  {
    name: 'Goal Score',
    text: 'Goal score amount',
  },
  {
    name: 'Prize set',
    text: 'A set of prize that will be available in this quest template.',
  },
];
