import { useDeferredValue, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

const ItemsList = ({ title, items, checked, onChange }) => {
  const [keyword, setKeyword] = useState('');
  const deferredKeyword = useDeferredValue(keyword);

  const isAllChecked = useMemo(
    () => !!items.length && items.length === checked.length,
    [items.length, checked.length],
  );

  const filteredItems = useMemo(
    () =>
      items.filter(({ label }) =>
        label.toLowerCase().includes(deferredKeyword.toLowerCase()),
      ),
    [items, deferredKeyword],
  );

  const itemsList = useMemo(
    () =>
      filteredItems.map(({ label, value }) => (
        <FormControlLabel
          key={value}
          control={
            <Checkbox
              checked={checked.includes(value)}
              onChange={() => onChange(value)}
            />
          }
          label={label}
        />
      )),
    [filteredItems, checked, onChange],
  );

  useEffect(() => {
    keyword && setKeyword('');
  }, [items]);

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          px: 2,
          py: 1,
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isAllChecked}
              onChange={() =>
                onChange(!isAllChecked ? items.map(({ value }) => value) : [])
              }
              disabled={!items.length}
            />
          }
          label={title}
        />
        <Typography variant="body2">
          {checked.length}/{items.length}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          p: 2,
        }}
      >
        <TextField
          label="Enter keyword"
          value={keyword}
          onChange={({ target: { value } }) => setKeyword(value)}
          size="small"
          disabled={!items.length}
        />
        <Stack
          sx={{
            height: '250px',
            overflowY: 'auto',
          }}
        >
          {!filteredItems.length ? (
            <Typography align="center" sx={{ mt: 2 }}>
              {deferredKeyword ? 'No matching data' : 'No data'}
            </Typography>
          ) : (
            itemsList
          )}
        </Stack>
      </Box>
    </Paper>
  );
};

ItemsList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ).isRequired,
  checked: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ItemsList;
