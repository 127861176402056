import React, { useCallback, useState } from 'react';

import {
  useDeleteInvoiceGroupItemMutation,
  useGetInvoiceGroupsListQuery,
} from 'store/api/configService/invoiceGroupController';

import { findPatchPages } from 'utils/pages';

import { Box } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';

import { columns } from 'components/config/invoiceGroups';
import { PAGE_PATH } from 'components/config/pages';
import { ENV_BY_STAGE } from 'config/api';

const InvoiceGroups = () => {
  const [filter, setFilter] = useState({});

  const [deleteInvoiceGroupItem] = useDeleteInvoiceGroupItemMutation();

  const handleChangeFilter = useCallback(
    ({ name, value }) => setFilter((prev) => ({ ...prev, [name]: value })),
    [],
  );

  const handleClearFilter = useCallback(() => setFilter({}), []);

  return (
    <TableLayout
      title="Invoice Groups"
      subTitle="This section is used only by the finance department and is intended to group partner systems into one group for further use of these groups in the Generate Invoice tab. Changes made in this section only affect the Generate Invoice section. If you do not belong to this department, please do not change groups."
      columns={columns}
      getDataQuery={useGetInvoiceGroupsListQuery}
      queryConditional={filter}
      deleteItemQuery={deleteInvoiceGroupItem}
      itemComponentPath={findPatchPages(PAGE_PATH.INVOICE_GROUP_ITEM)}
    >
      <Box
        component="div"
        sx={{
          mb: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <FilterBox>
          <Text
            name="name"
            label="Search by Name"
            onChange={({ target }) => handleChangeFilter(target)}
            value={filter.name ?? ''}
            fullWidth
          />
        </FilterBox>
        <FilterBox>
          <Select
            name="env"
            label="Filter by ENV"
            value={filter.env ?? ''}
            options={ENV_BY_STAGE}
            handleChange={handleChangeFilter}
            required={false}
          />
        </FilterBox>
        <ClearBtn handleClick={handleClearFilter} filter={filter} />
      </Box>
    </TableLayout>
  );
};

export default InvoiceGroups;
