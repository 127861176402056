export const systemTagsColumns = [
  {
    name: 'ID',
    value: ({ systemId }) => systemId,
  },
  {
    name: 'Title',
    value: ({ title }) => title,
  },
  {
    name: 'Name',
    value: ({ name }) => name,
  },
  {
    name: 'Game',
    value: ({ totalGames }) => totalGames,
  },
  {
    name: 'Tag',
    value: ({ totalTags }) => totalTags,
  },
];

export const gameTagsColumns = [
  {
    name: 'ID',
    value: ({ gameId }) => gameId,
  },
  {
    name: 'Name',
    value: ({ name }) => name,
  },
  {
    name: 'Absolute Name',
    value: ({ absoluteName }) => absoluteName,
  },
  {
    name: 'Systems',
    value: ({ systems }) => systems.length,
  },
  {
    name: 'Tags',
    value: ({ tags }) => Object.keys(tags).length,
  },
];
