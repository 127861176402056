export const columns = [
  {
    name: 'ID',
    value: ({ id }) => id,
  },
  {
    name: 'Operator ID',
    value: ({ operatorId }) => operatorId,
  },
  {
    name: 'Operator Name',
    value: ({ operatorName }) => operatorName,
  },
  {
    name: 'Integration Type',
    value: ({ integrationType }) => integrationType,
  },
  {
    name: 'External Operator ID',
    value: ({ externalOperatorId }) => externalOperatorId,
  },
];
