import { createSlice } from '@reduxjs/toolkit';
import { getStorageData, setStorageData } from 'utils/storage/ui';

const initialState = {
  colorMode: getStorageData().colorMode,
  sidebarView: getStorageData().sidebarView,
};

export const uiMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action);

    switch (result.type) {
      case 'ui/toggleColorMode': {
        setStorageData({ colorMode: getState().ui.colorMode });
        break;
      }
      case 'ui/toggleSidebar': {
        setStorageData({ sidebarView: getState().ui.sidebarView });
        break;
      }
    }

    return result;
  };
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleColorMode: (state) => {
      state.colorMode = state.colorMode === 'light' ? 'dark' : 'light';
    },
    toggleSidebar: (state) => {
      state.sidebarView = state.sidebarView === 'true' ? 'false' : 'true';
    },
  },
});

export const uiSelector = {
  getColorMode: (state) => state.ui.colorMode,
  getSidebarView: (state) =>
    state.ui.sidebarView === true || state.ui.sidebarView === 'true',
};

export const { toggleColorMode, toggleSidebar } = uiSlice.actions;
export default uiSlice.reducer;
