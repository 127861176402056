import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const balanceIncrease = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getBalanceIncreaseTypes: build.query({
      query: () => ({
        url: '/balance-increase/v1/types',
      }),
    }),
    getBalanceIncreaseList: build.query({
      // TODO: backend task for changing to GET method: HHSDT-3960
      query: ({ size, page, ...params }) => ({
        url: '/balance-increase/v1/list',
        method: 'POST',
        body: {
          ...params,
          limit: size,
          offset: page * size,
          ...(params.sort.length &&
            params.sort.split(',')[0] !== 'undefined' && {
              order_by_field: params.sort.split(',')[0],
            }),
          ...(params.sort.length &&
            params.sort.split(',')[0] !== 'undefined' &&
            params.sort.split(',').length > 1 && {
              order_by_sort: params.sort.split(',')[1].toUpperCase(),
            }),
        },
      }),
      transformResponse: (response) => ({
        content: response,
        totalElements: -1, // TODO: fix when backed add meta data totalElements
      }),
      providesTags: () => [{ type: 'balanceIncrease' }],
    }),
    createBalanceIncrease: build.mutation({
      query: (data) => ({
        url: '/balance-increase/v1/request',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'balanceIncrease' }],
    }),
  }),
});

export const {
  useGetBalanceIncreaseTypesQuery,
  useGetBalanceIncreaseListQuery,
  useCreateBalanceIncreaseMutation,
} = balanceIncrease;
