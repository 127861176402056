import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

import { findPatchPages } from 'utils/pages';

import { PAGE_PATH } from 'components/config/pages';
import { DateTimeFormat } from 'config/dates';

const highlightWinning = (Win, Bet) => {
  const multiplier = Win / Bet ?? null;

  switch (true) {
    case multiplier >= 10 && multiplier < 99.99:
      return 'rgb(255,255,0)';
    case multiplier > 100 && multiplier < 199.99:
      return 'rgb(255,125,0)';
    case multiplier > 200 && multiplier < 499.99:
      return 'rgb(255, 192, 230)';
    case multiplier > 500:
      return 'rgb(255,0,0)';
    default:
      return null;
  }
};

const parseRoundId = (id) => {
  const [RoundId, BingoRoundId] = id?.split
    ? id.split('_bingo_')
    : [null, null];

  return {
    RoundId,
    BingoRoundId,
  };
};

export const columns = [
  {
    name: 'Round Id',
    value: ({ round_id, env, update_dt }) => {
      const { RoundId } = parseRoundId(round_id);
      return (
        <Link
          to={`${findPatchPages(PAGE_PATH.ACTIONS_BY_ROUND)}?env=${env}&round_id=${round_id}&date_from=${update_dt.split(' ')[0]}`}
        >
          {RoundId}
        </Link>
      );
    },
  },
  {
    name: 'Round Finish Date',
    value: ({ update_dt }) => dayjs.utc(update_dt).format(DateTimeFormat),
  },
  {
    name: 'Operator Id',
    value: (row) => row.operator_id,
  },
  {
    name: 'Player Id',
    value: (row) => row.player_id,
  },
  {
    name: 'Game Code',
    value: (row) => row.game_code,
  },
  {
    name: 'Round State',
    value: (row) => row.round_state,
  },
  {
    name: 'Action State',
    value: (row) => row.action_state,
  },
  {
    name: 'Currency',
    sortLabel: 'currency',
    value: (row) => row.currency,
  },
  {
    name: 'Bet',
    sortLabel: 'bet',
    value: (row) => row.bet,
  },
  {
    name: 'Win',
    sortLabel: 'win',
    value: ({ win, bet }, theme) => (
      <span
        style={{
          ...(highlightWinning(win, bet) && {
            backgroundColor: highlightWinning(win, bet),
            padding: 5,
            color: theme.palette.common.black,
          }),
        }}
      >
        {win}
      </span>
    ),
  },
  {
    name: 'Refund',
    sortLabel: 'refund',
    value: (row) => row.refund,
  },
  {
    name: 'GGR',
    sortLabel: 'ggr',
    value: ({ win, bet, ggr }, theme) => (
      <span
        style={{
          ...(highlightWinning(win, bet) && {
            backgroundColor: highlightWinning(win, bet),
            padding: 5,
            color: theme.palette.common.black,
          }),
        }}
      >
        {ggr}
      </span>
    ),
  },
  {
    name: 'Player Balance After',
    value: (row) => row.balance,
  },
  {
    name: 'Game Type',
    value: (row) => row.game_type,
  },
  {
    name: 'Operator',
    value: (row) => row.operator,
  },
  {
    name: 'Provider',
    value: (row) => row.provider,
  },
  {
    name: 'Country Code',
    value: (row) => row.country_code,
  },
  {
    name: 'User Type',
    value: (row) => row.user_type,
  },
  {
    name: 'Bet EUR',
    sortLabel: 'bet_eur',
    value: (row) => row.bet_eur,
  },
  {
    name: 'Win EUR',
    sortLabel: 'win_eur',
    value: (row) => row.win_eur,
  },
  {
    name: 'Refund EUR',
    sortLabel: 'refund_eur',
    value: (row) => row.refund_eur,
  },
  {
    name: 'Bet USD',
    sortLabel: 'bet_usd',
    value: (row) => row.bet_usd,
  },
  {
    name: 'Win USD',
    sortLabel: 'win_usd',
    value: (row) => row.win_usd,
  },
  {
    name: 'Refund USD',
    sortLabel: 'refund_usd',
    value: (row) => row.refund_usd,
  },
  {
    name: 'Free Spins Left',
    sortLabel: 'freespins_left',
    value: (row) => row.freespins_left,
  },
  {
    name: 'Session Id',
    value: (row) => row.session_id,
  },
  {
    name: 'Bingo Round Id',
    value: ({ round_id }) => {
      const { BingoRoundId } = parseRoundId(round_id);
      return BingoRoundId;
    },
  },
  {
    name: 'ENV',
    value: (row) => row.env,
  },
];
