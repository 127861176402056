import React from 'react';

import PropTypes from 'prop-types';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const propTypes = {
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.string,
};

const TableSimple = ({ data, title }) => (
  <>
    {title && (
      <Typography variant="h6" component="h4" sx={{ mb: 2 }}>
        {title}
      </Typography>
    )}
    <Paper>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {Object.keys(data).map((key) => (
                <TableCell key={`head_${key}`}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {Object.keys(data).map((key) => (
                <TableCell key={`body_${key}`}>
                  {typeof data[key] === 'boolean'
                    ? data[key]
                      ? 'True'
                      : 'False'
                    : data[key]}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    <Box sx={{ height: 16, flex: 'none' }} />
  </>
);

TableSimple.propTypes = propTypes;
export default TableSimple;
