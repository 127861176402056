import { hhs } from 'store/api/hhs';

const recipeApi = hhs.injectEndpoints({
  endpoints: (build) => ({
    getSystemList: build.query({
      // TODO: backend task for changing to GET method: HHSDT-3960
      query: (params = {}) => ({
        url: '/api/v2/system/list',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, { systemId, title }) => [
            ...acc,
            { label: `#${systemId}: ${title}`, value: systemId },
          ],
          [],
        ),
    }),
    //TODO: remove after BE will be ready
    getSystemListTableData: build.query({
      query: (params) => ({
        url: '/api/v2/system/list',
        method: 'POST',
        body: params,
      }),
      transformResponse: (response) => ({
        content: response,
        totalElements: -1,
      }),
    }),
    getGames: build.query({
      query: () => ({
        url: '/api/v2/games',
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, val) => [...acc, { label: val.gameName, value: val.gameCode }],
          [],
        ),
    }),
  }),
});

export const {
  useGetSystemListQuery,
  useGetSystemListTableDataQuery,
  useGetGamesQuery,
} = recipeApi;
