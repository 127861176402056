import { useCallback, useMemo, useState } from 'react';

import { useSearch } from 'hooks/useSearch';
import { useToggle } from 'hooks/useToggle';

import { useGetSystemListQuery } from 'store/api/hhs/recipe.api';
import { useGetGameListQuery } from 'store/api/hhsKeyCloack/game';
import {
  useDeleteTagMutation,
  useGetTagsQuery,
} from 'store/api/hhsKeyCloack/tags';

import { TextField } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import AssignDialog from 'components/UI/AssignDialog/AssignDialog';
import EditColumn from 'components/UI/Table/EditColumn';
import Dialog from 'components/pages/Tags/ManageTags/Dialog';

const ManageTags = () => {
  const [assignDialogItem, setAssignDialogItem] = useState(null);

  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery();

  const { data: dataGames, isLoading: gamesLoading } = useGetGameListQuery();

  const [deleteTag] = useDeleteTagMutation();

  const { opened: assignDialogOpened, handleOpen, handleClose } = useToggle();

  const { searchParams, handleSearch } = useSearch();

  const handleOpenAssignDialog = useCallback((item) => {
    setAssignDialogItem(item);
    handleOpen();
  }, []);

  const handleCloseAssignDialog = useCallback(() => {
    handleClose();
    setAssignDialogItem(null);
  }, []);

  const groupAssignedItems = useCallback((items, assigned) => {
    const assignedIds = Object.keys(assigned).map(Number);

    return items?.reduce(
      (acc, item) => ({
        ...acc,
        ...(assignedIds.includes(item.value)
          ? { assigned: [...acc.assigned, item] }
          : { available: [...acc.available, item] }),
      }),
      { available: [], assigned: [] },
    );
  }, []);

  const assignDialogProps = useMemo(() => {
    switch (assignDialogItem?.type) {
      case 'systems':
        return {
          title: `Assign systems to tag "${assignDialogItem.data.tagName}"`,
          data: groupAssignedItems(dataSystems, assignDialogItem.data.systems),
          handleSubmit: () => {},
          loading: systemsLoading,
        };
      case 'games':
        return {
          title: `Assign games to tag "${assignDialogItem.data.tagName}"`,
          data: groupAssignedItems(dataGames, assignDialogItem.data.games),
          handleSubmit: () => {},
          loading: gamesLoading,
        };
    }
  }, [
    assignDialogItem,
    groupAssignedItems,
    dataSystems,
    systemsLoading,
    dataGames,
    gamesLoading,
  ]);

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        value: ({ tagId }) => tagId,
      },
      {
        name: 'Name',
        value: ({ tagName }) => tagName,
      },
      {
        name: 'Systems',
        value: (item) => (
          <EditColumn
            label={Object.keys(item.systems).length}
            onClick={(e) => {
              e.stopPropagation();

              handleOpenAssignDialog({
                type: 'systems',
                data: item,
              });
            }}
          />
        ),
      },
      {
        name: 'Games',
        value: (item) => (
          <EditColumn
            label={Object.keys(item.games).length}
            onClick={(e) => {
              e.stopPropagation();

              handleOpenAssignDialog({
                type: 'games',
                data: item,
              });
            }}
          />
        ),
      },
    ],
    [handleOpenAssignDialog],
  );

  return (
    <>
      <TableLayout
        title="Manage system and game tags"
        columns={columns}
        getDataQuery={useGetTagsQuery}
        queryConditional={searchParams}
        deleteItemQuery={deleteTag}
        dialogComponent={(props) => <Dialog {...props} />}
      >
        <TextField
          name="search"
          label="Enter keyword"
          onChange={handleSearch}
          sx={{ alignSelf: 'flex-start', mb: 2 }}
        />
      </TableLayout>
      {assignDialogOpened && (
        <AssignDialog
          type={assignDialogItem.type}
          handleClose={handleCloseAssignDialog}
          {...assignDialogProps}
        />
      )}
    </>
  );
};

export default ManageTags;
