import { Avatar } from '@mui/material';

export const templatesColumns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: (row) => row.name,
  },
  {
    name: 'Image',
    value: (row) => <Avatar alt="Image" src={row.icon} />,
  },
  {
    name: 'Base Currency',
    value: (row) => row.baseCurrency,
  },
  {
    name: 'Game Code',
    value: (row) => row.gameCodes?.[0],
  },
  {
    name: 'Minimal Bet',
    sortLabel: 'minimalBet',
    value: (row) => row.minimalBet,
  },
  {
    name: 'Scoring Rule',
    sortLabel: 'scoringRule',
    value: (row) => row.scoringRule,
  },
  {
    name: 'Goal Score',
    sortLabel: 'goalScore',
    value: (row) => row.goalScore,
  },
];
