export const columns = [
  {
    name: 'Code',
    sortLabel: 'code',
    sortDirection: 'asc',
    value: (row) => row.code,
  },
  {
    name: 'Real rate, EUR',
    sortLabel: 'rate',
    value: (row) => row.rate,
  },
  {
    name: 'Fixed rate, EUR',
    sortLabel: 'fixedRate',
    value: (row) => row.fixedRate,
  },
];
