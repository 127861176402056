export const langs = [
  'EN',
  'ES',
  'IT',
  'UK',
  'ZH',
  'RU',
  'HR',
  'TR',
  'RO',
  'TH',
  'PT',
  'FR',
  'ENS',
  'PL',
  'DE',
  'BG',
  'JA',
  'KO',
  'ID',
  'VI',
];
