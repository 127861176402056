import { configService } from 'store/api/configService/index';

const newsController = configService.injectEndpoints({
  endpoints: (build) => ({
    getNews: build.query({
      query: (params) => ({
        url: '/config-service/api/v1/posts',
        params,
      }),
      providesTags: () => [{ type: 'News' }],
    }),
    getNewsItem: build.query({
      query: ({ id }) => ({
        url: `/config-service/api/v1/posts/${id}`,
      }),
      providesTags: () => [{ type: 'News' }],
    }),
    updateNewsItem: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/config-service/api/v1/posts${id ? `/${id}` : ''}`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'News' }],
    }),
    deleteNewsItem: build.mutation({
      query: ({ id }) => ({
        url: `/config-service/api/v1/posts/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'News' }],
    }),
  }),
});

export const {
  useGetNewsQuery,
  useGetNewsItemQuery,
  useUpdateNewsItemMutation,
  useDeleteNewsItemMutation,
} = newsController;
