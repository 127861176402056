import React from 'react';

import { useGetUsersQuery } from 'store/api/hhs/users.api';

const UserRoles = () => {
  const { data: dataUsers } = useGetUsersQuery({ 'roles[]': 'ROLE_ADMIN' });

  console.log(dataUsers);

  return <div>Roles</div>;
};

export default UserRoles;
