import React from 'react';

import { Cancel } from '@mui/icons-material';
import { Box } from '@mui/material';

const AccessDenied = () => (
  <>
    <Box
      component="div"
      sx={{ display: 'flex', columnGap: 2, alignItems: 'center', mb: 2 }}
    >
      <Box component="h2" sx={{ m: 0 }} data-testid="title">
        Access Denied
      </Box>
      <Cancel />
    </Box>
    <Box component="div" data-testid="description">
      We’re sorry, but you do not have required permissions to access this page.
      Please contact the site administrator.
    </Box>
  </>
);

export default AccessDenied;
