import { api } from 'store/api/api';
import { reportDownload } from 'utils/reportDownload';

const wheelOfFortuneController = api.injectEndpoints({
  endpoints: (build) => ({
    getWheelsOfFortune: build.query({
      query: (params) => ({
        url: '/tournament-service/admin/api/v1/wheels-of-fortune/campaigns',
        params,
      }),
      providesTags: () => [{ type: 'TournamentWheelOfFortune' }],
    }),
    getWheelOfFortuneItem: build.query({
      query: ({ id }) => ({
        url: `/tournament-service/admin/api/v1/wheels-of-fortune/campaigns/${id}`,
      }),
      providesTags: () => [{ type: 'TournamentWheelOfFortune' }],
    }),
    updateWheelOfFortune: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/tournament-service/admin/api/v1/wheels-of-fortune/campaigns${
          id ? `/${id}` : ''
        }`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'TournamentWheelOfFortune' }],
    }),
    deleteWheelOfFortune: build.mutation({
      query: ({ id }) => ({
        url: `/tournament-service/admin/api/v1/wheels-of-fortune/campaigns/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'TournamentWheelOfFortune' }],
    }),
    getWheelOfFortuneReport: build.query({
      query: ({ id, name, ...params }) => ({
        url: `/tournament-service/admin/api/v1/wheels-of-fortune/campaigns/${id}/report`,
        params: {
          currency: 'EUR',
          ...params,
        },
        cache: 'no-cache',
        responseHandler: async (response) =>
          await reportDownload({ response, id, name }),
      }),
    }),
    getAllWheelsOfFortune: build.query({
      query: (params) => ({
        url: '/tournament-service/admin/api/v1/wheels-of-fortune/campaigns/dictionary',
        params,
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, val) => [
            ...acc,
            { label: `${val.id}-${val.name}`, value: val.id },
          ],
          [],
        ),
      providesTags: () => [{ type: 'TournamentWheelOfFortune' }],
    }),
  }),
});

export const {
  useGetWheelsOfFortuneQuery,
  useGetWheelOfFortuneItemQuery,
  useUpdateWheelOfFortuneMutation,
  useDeleteWheelOfFortuneMutation,
  useGetWheelOfFortuneReportQuery,
  useGetAllWheelsOfFortuneQuery,
} = wheelOfFortuneController;
