import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAutoHide: true,
  items: {},
  title: '',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      const {
        text,
        type,
        isAutoHide = initialState.isAutoHide,
        name = '',
        title = '',
      } = action.payload;
      state.isAutoHide = isAutoHide;
      state.items[name || type] = {
        type,
        text,
        title,
      };
    },
    hideAlert: (state, action) => {
      if (action.payload) {
        if (state.items[action.payload]) {
          delete state.items[action.payload];
        }
      } else {
        state.items = initialState.items;
      }
    },
  },
});

export const alertSelector = {
  getData: createSelector(
    [(state) => state.alert.items, (state) => state.alert.isAutoHide],
    (items, isAutoHide) => ({
      items,
      isAutoHide,
    }),
  ),
};

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;
