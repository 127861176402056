import React from 'react';

import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
};

const Confirm = ({
  handleClose,
  content = 'Are you sure?',
  okText = 'Ok',
  cancelText = 'Cancel',
  disabled,
}) => {
  const handleOk = () => handleClose(true);
  const handleCancel = () => handleClose(false);

  return (
    <Dialog maxWidth="sm" open={true} onClose={handleCancel}>
      <DialogContent sx={{ minWidth: '300px' }} align="center">
        {content}
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
        <Button variant="outlined" onClick={handleCancel}>
          {cancelText}
        </Button>
        <Button variant="contained" onClick={handleOk} disabled={disabled}>
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Confirm.propTypes = propTypes;
export default Confirm;
