import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Collapse as MuiCollapse,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

const Collapse = ({ data, columns }) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(null);

  const dataWithUniqueKeys = useMemo(() => {
    return data.map((row) => ({
      ...row,
      __key: _.uniqueId(),
    }));
  }, [data]);

  const preventPropagation = useCallback((e) => e.stopPropagation(), []);

  const handleExpandRow = useCallback(
    async (e, collapse, row) => {
      preventPropagation(e);
      const value =
        expand?.__key !== row.__key
          ? {
              __key: row.__key,
              id: collapse.id(row),
              data: await collapse.data(row, dispatch),
              columns: collapse.columns,
            }
          : null;
      setExpand(value);
    },
    [expand],
  );

  return (
    <MuiCollapse in={true} timeout="auto" unmountOnExit>
      <Box sx={{ margin: 1, maxHeight: 500, overflowY: 'auto' }}>
        <MuiTable size="small" aria-label="purchases">
          <TableHead>
            <TableRow>
              {columns.map(({ name }) => (
                <TableCell key={name}>{name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataWithUniqueKeys.map((row) => (
              <React.Fragment key={row.__key}>
                <TableRow>
                  {columns.map(({ name, value, collapse }) => (
                    <TableCell key={name}>
                      {value(row)}
                      {collapse && (
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={(e) => handleExpandRow(e, collapse, row)}
                          sx={{ ml: 1 }}
                        >
                          {row.id && expand?.__key === row.__key ? (
                            <KeyboardArrowUp />
                          ) : (
                            <KeyboardArrowDown />
                          )}
                        </IconButton>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
                {expand?.__key === row.__key && (
                  <TableRow onClick={preventPropagation}>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0,
                      }}
                      colSpan={Object.keys(columns).length + 1}
                    >
                      <Collapse data={expand.data} columns={expand.columns} />
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </MuiTable>
      </Box>
    </MuiCollapse>
  );
};

Collapse.propTypes = propTypes;
export default Collapse;
