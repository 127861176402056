import { cs } from 'store/api/cs';

const fixedCurrencyController = cs.injectEndpoints({
  endpoints: (build) => ({
    getFixedCurrencies: build.query({
      query: (params) => ({
        url: `/currency-service/internal/api/v1/currencies`,
        params,
      }),
      providesTags: () => [{ type: 'FixedCurrencies' }],
    }),
    updateFixedCurrencies: build.mutation({
      query: ({ code, fixedRate }) => ({
        url: `/currency-service/internal/api/v1/currencies/${code}`,
        method: 'PUT',
        body: {
          fixedRate,
        },
      }),
      invalidatesTags: () => [{ type: 'FixedCurrencies' }],
    }),
  }),
});

export const { useGetFixedCurrenciesQuery, useUpdateFixedCurrenciesMutation } =
  fixedCurrencyController;
