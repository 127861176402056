import dayjs from 'dayjs';

import { DateTimeFormat } from 'config/dates';

export const columns = [
  {
    name: 'Name',
    value: (row) => row.name,
  },
  {
    name: 'Description',
    value: (row) => row.description,
  },
  {
    name: 'Date',
    value: (row) => dayjs.utc(new Date(row.time.date)).format(DateTimeFormat),
  },
];
