import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { MoreVert } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

const propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  ).isRequired,
  row: PropTypes.shape({}).isRequired,
  handleClick: PropTypes.func.isRequired,
};

const Actions = ({ actions, row, handleClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const open = Boolean(anchorEl);

  if (actions.length === 1) {
    return (
      <IconButton onClick={() => handleClick(actions[0], row)}>
        {actions[0].icon}
      </IconButton>
    );
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClickMenu}
      >
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            onClick={() => {
              handleCloseMenu();
              handleClick(action, row);
            }}
            sx={{ pt: '10px', pb: '10px' }}
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <Typography variant="inherit">{action.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

Actions.propTypes = propTypes;
export default Actions;
