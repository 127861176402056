import { api } from 'store/api/api';

import { reportDownload } from 'utils/reportDownload';

const campaignController = api.injectEndpoints({
  endpoints: (build) => ({
    getTournamentsCampaigns: build.query({
      query: (params) => ({
        url: '/tournament-service/admin/api/v3/tournaments/campaigns',
        params,
      }),
      providesTags: () => [{ type: 'TournamentCampaigns' }],
    }),
    getTournamentsCampaignsItem: build.query({
      query: ({ id }) => ({
        url: `/tournament-service/admin/api/v3/tournaments/campaigns/${id}`,
      }),
      providesTags: () => [{ type: 'TournamentCampaigns' }],
    }),
    updateTournamentsCampaign: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/tournament-service/admin/api/v3/tournaments/campaigns${
          id ? `/${id}` : ''
        }`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'TournamentCampaigns' }],
    }),
    deleteTournamentsCampaign: build.mutation({
      query: ({ id }) => ({
        url: `/tournament-service/admin/api/v3/tournaments/campaigns/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'TournamentCampaigns' }],
    }),
    getTournamentsReport: build.query({
      query: ({ id, name, ...params }) => ({
        url: `/tournament-service/admin/api/v3/tournaments/campaigns/${id}/report`,
        params: {
          currency: 'EUR',
          ...params,
        },
        cache: 'no-cache',
        responseHandler: async (response) =>
          await reportDownload({ response, id, name }),
      }),
    }),
    setTournamentsPayment: build.mutation({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/tournament/${id}/pay`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetTournamentsCampaignsQuery,
  useGetTournamentsCampaignsItemQuery,
  useUpdateTournamentsCampaignMutation,
  useDeleteTournamentsCampaignMutation,
  useGetTournamentsReportQuery,
  useSetTournamentsPaymentMutation,
} = campaignController;
