export const columns = [
  {
    name: 'Code',
    value: ({ groupCode }) => groupCode,
  },
  {
    name: 'Key',
    value: ({ fieldCode }) => fieldCode,
  },
  {
    name: 'EN',
    value: ({ en }) => en,
  },
];
