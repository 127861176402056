import React, { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import {
  useGetGamesQuery,
  useGetSystemListQuery,
} from 'store/api/hhs/recipe.api';
import { useGetBetWinDailyQuery } from 'store/api/hhsKeyCloack/analytics';

import { Box, Typography } from '@mui/material';

import DateRange from 'components/UI/DateRange/DateRange';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import Table from 'components/UI/Table/Table';

import { ENV_LIST, getProvidersIdList } from 'components/config/analytics';
import { columns } from 'components/config/betWinDaily';

const BetWinDaily = () => {
  const [filter, setFilter] = useState({});
  const PROVIDERS_ID_LIST = useMemo(getProvidersIdList, []);

  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery({
      'type[]': 'agregator',
    });
  const { data: dataGames, isLoading: gamesLoading } = useGetGamesQuery();

  const handleChangeFilter = useCallback(
    ({ name, value }) =>
      setFilter((prevState) => {
        const newState = _.cloneDeep(prevState);
        const newValue = typeof value === 'string' ? value.trim() : value;
        if (newValue === null || newValue === undefined || newValue === '') {
          if (name === 'operator_id') {
            _.unset(newState, 'currency');
            _.unset(newState, 'game_code');
            _.unset(newState, 'game_mode_code');
          }
          _.unset(newState, name);
        } else {
          _.set(newState, name, newValue);
        }
        return newState;
      }),
    [],
  );
  const handleClearFilter = useCallback(() => setFilter({}), []);

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}
        component="div"
      >
        <Typography variant="h5" component="h2">
          Bet-Win Daily Statistics
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          mb: 2,
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2,
        }}
      >
        <FilterBox>
          <Select
            label="ENV"
            name="env"
            value={filter.env ?? ''}
            handleChange={handleChangeFilter}
            options={ENV_LIST}
          />
        </FilterBox>
        <FilterBox>
          <DateRange filter={filter} handleChangeFilter={handleChangeFilter} />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            label="Operator Id"
            name="operator_id"
            value={filter.operator_id ?? null}
            handleChange={handleChangeFilter}
            required={false}
            options={dataSystems || []}
            loading={systemsLoading}
          />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            label="Game code"
            name="game_code"
            disabled={!filter.operator_id}
            required={false}
            value={filter.game_code ?? null}
            handleChange={handleChangeFilter}
            options={dataGames || []}
            loading={gamesLoading}
          />
        </FilterBox>
        <FilterBox>
          <Autocomplete
            label="Currency"
            name="currency"
            disabled={!filter.operator_id}
            value={filter.currency ?? null}
            handleChange={handleChangeFilter}
            required={false}
            options={dataCurrency || []}
            loading={currencyLoading}
          />
        </FilterBox>
        <FilterBox>
          <Text
            label="Game Mode Code"
            name="game_mode_code"
            disabled={!filter.operator_id}
            onChange={(event) =>
              handleChangeFilter({
                name: 'game_mode_code',
                value: event.target.value,
              })
            }
            fullWidth
            value={filter.game_mode_code ?? ''}
          />
        </FilterBox>
        <FilterBox>
          <Select
            label="Provider Id"
            name="provider_id"
            disabled={!filter.operator_id}
            required={false}
            value={filter.provider_id ?? ''}
            handleChange={handleChangeFilter}
            options={PROVIDERS_ID_LIST}
          />
        </FilterBox>
        <ClearBtn handleClick={handleClearFilter} filter={filter} />
      </Box>
      {filter.env && filter.date_from && filter.date_to && (
        <Table
          actions={[]}
          getDataQuery={useGetBetWinDailyQuery}
          columns={columns}
          queryConditional={{
            ...filter,
            date_from: new Date(filter.date_from).valueOf(),
            date_to: new Date(filter.date_to).valueOf(),
          }}
          editableRow={false}
        />
      )}
    </>
  );
};

export default BetWinDaily;
