import { hhs } from 'store/api/hhs';
import { getToken } from 'services/UserService';

const eventLogsController = hhs.injectEndpoints({
  endpoints: (build) => ({
    getEvents: build.query({
      query: (params) => ({
        url: '/api/v1/all-log-records',
        params,
        headers: {
          authorization: `Bearer ${getToken()}`,
        },
      }),
      transformResponse: (response) => {
        response.content = response.data || [];
        response.totalElements = response.content.length;
        response.content.reduce(
          (acc, val) => [
            ...acc,
            {
              id: val.name,
              name: val.name,
              description: val.description,
              date: val.time.date,
              isError: val.isError,
            },
          ],
          [],
        );
        return response;
      },
    }),
  }),
});

export const { useGetEventsQuery } = eventLogsController;
