export const HelperList = [
  {
    name: 'Campaign name',
    text: 'The name, which will be displayed in the list of other campaigns in the Bonus Room.',
  },
  {
    name: 'Start date',
    text: 'Date and time of the campaign start.',
  },
  {
    name: 'Campaign duration',
    text: 'The duration of  the campaign, in days. Determines events quantity in the campaign.',
  },
  {
    name: 'Event duration',
    text: 'The duration of the event within the campaign, in hours.',
  },
  {
    name: 'Pending time',
    text: 'The time in hours during which the campaign will be displayed in the Bonus Room before the campaign starts.',
  },
  {
    name: 'Base currency',
    text: 'The main currency in which the campaign will be conducted.',
  },
  {
    name: 'Min. bet to participating',
    text: 'The minimum bet to participate in this campaign.',
  },
  {
    name: 'Player prizes limit per event',
    text: 'The player prizes limit per event within this campaign.',
  },
  {
    name: 'Prize set',
    text: 'A set of prizes that will be available in this campaign for each event.',
  },
  {
    name: 'Participating games',
    text: 'Games in which this campaign will be available.',
  },
  {
    name: 'Operator codes',
    text: 'The list of operator codes for which this campaign will be available.',
  },
  {
    name: 'Limit to base currency',
    text: 'Only users, that playing with the same currency, as campaigns base currency, can participate in a given campaign. Rounds in the other currencies will be ignored.',
  },
];
