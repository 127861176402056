import React from 'react';

import PropTypes from 'prop-types';

import { useGetActionDetailsQuery } from 'store/api/hhsKeyCloack/analytics';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog as MuiDialog,
} from '@mui/material';

import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import PrettyPrintJson from 'components/UI/PrettyPrintJson/PrettyPrintJson';

const propTypes = {
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.shape({
    env: PropTypes.string.isRequired,
    action_id: PropTypes.string.isRequired,
    operator_id: PropTypes.string.isRequired,
    player_id: PropTypes.string.isRequired,
    start_dt: PropTypes.string.isRequired,
    update_dt: PropTypes.string.isRequired,
  }).isRequired,
};

const Dialog = ({ handleClose, action }) => {
  const { data, isLoading } = useGetActionDetailsQuery({
    env: action.env,
    action_id: action.action_id,
    operator_id: action.operator_id,
    player_id: action.player_id,
    date_from: action.start_dt.split(' ')[0],
    date_to: action.update_dt.split(' ')[0],
  });

  return (
    <>
      {isLoading && <FullPageLoader />}
      {data && (
        <MuiDialog maxWidth="md" open={true}>
          <DialogTitle>Action Details</DialogTitle>
          <DialogContent sx={{ pb: 1 }}>
            <PrettyPrintJson data={data} />
          </DialogContent>
          <DialogActions sx={{ p: 3, pt: 1 }}>
            <Button onClick={handleClose} variant="outlined">
              Ok
            </Button>
          </DialogActions>
        </MuiDialog>
      )}
    </>
  );
};

Dialog.propTypes = propTypes;
export default Dialog;
