import { api } from 'store/api/api';
import { reportDownload } from 'utils/reportDownload';

const prizeDropController = api.injectEndpoints({
  endpoints: (build) => ({
    getPrizeDrops: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v1/prize-drops/campaigns',
        params,
      }),
      providesTags: () => [{ type: 'TournamentPrizeDrops' }],
    }),
    getPrizeDropsItem: build.query({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/prize-drops/campaigns/${id}`,
      }),
      providesTags: () => [{ type: 'TournamentPrizeDrops' }],
    }),
    updatePrizeDrops: build.mutation({
      query: ({ id, ...body }) => ({
        url: `/tournament-service/api/v1/prize-drops/campaigns${
          id ? `/${id}` : ''
        }`,
        method: id ? 'PUT' : 'POST',
        body,
      }),
      invalidatesTags: () => [{ type: 'TournamentPrizeDrops' }],
    }),
    deletePrizeDrops: build.mutation({
      query: ({ id }) => ({
        url: `/tournament-service/api/v1/prize-drops/campaigns/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'TournamentPrizeDrops' }],
    }),
    getPrizeDropsReport: build.query({
      query: ({ id, name, ...params }) => ({
        url: `/tournament-service/api/v1/prize-drops/campaigns/${id}/report`,
        params: {
          currency: 'EUR',
          ...params,
        },
        cache: 'no-cache',
        responseHandler: async (response) =>
          await reportDownload({ response, id, name }),
      }),
    }),
    getAllPrizeDrops: build.query({
      query: (params) => ({
        url: '/tournament-service/api/v1/prize-drops/campaigns/dictionary',
        params,
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, val) => [
            ...acc,
            { label: `${val.id}-${val.name}`, value: val.id },
          ],
          [],
        ),
      providesTags: () => [{ type: 'TournamentPrizeDrops' }],
    }),
  }),
});

export const {
  useGetPrizeDropsQuery,
  useGetPrizeDropsItemQuery,
  useUpdatePrizeDropsMutation,
  useDeletePrizeDropsMutation,
  useGetPrizeDropsReportQuery,
  useGetAllPrizeDropsQuery,
} = prizeDropController;
