export const HelperList = [
  {
    name: 'Group name',
    text: 'Name',
  },
  {
    name: 'Environment',
    text: 'The environment on which the operators of this group are located',
  },
  {
    name: 'Operators',
    text: 'List of operators that belong to Invoice Group',
  },
  {
    name: 'Description',
    text: 'Brief description of the group, important information, etc',
  },
];
