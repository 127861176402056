import { getENV } from 'utils/getEnv';
import { findPatchPages } from 'utils/pages';

import {
  AccountBalanceWallet,
  BarChart,
  Bookmark,
  Casino,
  EmojiEvents,
  GppBad,
  HdrAuto,
  HomeRepairService,
  Loop,
  People,
  Person,
  SportsEsports,
} from '@mui/icons-material';

import { PAGE_PATH } from 'components/config/pages';
import { externalBO } from 'config/externalBO';

export const drawerWidth = 240;
const EXTERNAL_BO_PATH = externalBO[getENV()];

export const menu = [
  {
    title: 'Casino systems',
    icon: (props) => <Casino {...props} />,
    subNav: [
      {
        title: 'System settings',
        path: `${EXTERNAL_BO_PATH}/system/settings`,
        external: true,
      },
      {
        title: 'System groups',
        path: `${EXTERNAL_BO_PATH}/system/groups`,
        external: true,
      },
      {
        title: 'Invoice groups',
        path: findPatchPages(PAGE_PATH.INVOICE_GROUPS),
      },
      {
        title: 'External Operator configs',
        path: findPatchPages(PAGE_PATH.EXTERNAL_OPERATOR_CONFIGS),
      },
    ],
  },
  {
    title: 'Users',
    icon: (props) => <People {...props} />,
    feature: 'users-tab',
    show: (enabled) => !enabled,
    subNav: [
      {
        title: 'User settings',
        path: `${EXTERNAL_BO_PATH}/user/settings`,
        external: true,
      },
      {
        title: 'User global roles',
        path: `${EXTERNAL_BO_PATH}/user/global-roles`,
        external: true,
      },
    ],
  },
  {
    title: 'Users',
    icon: (props) => <People {...props} />,
    feature: 'users-tab',
    show: (enabled) => !!enabled,
    subNav: [
      {
        title: 'User settings',
        path: findPatchPages(PAGE_PATH.USER_SETTINGS),
      },
      {
        title: 'User global roles',
        path: findPatchPages(PAGE_PATH.USER_ROLES),
      },
    ],
  },
  {
    title: 'Games',
    icon: (props) => <SportsEsports {...props} />,
    subNav: [
      {
        title: 'Master Game List',
        path: findPatchPages(PAGE_PATH.MASTER_GAME_LIST).replace(
          ':provider?',
          '',
        ),
        feature: 'master-game-list-page',
        show: (enabled) => !!enabled,
      },
      {
        title: 'Game settings',
        path: `${EXTERNAL_BO_PATH}/game/settings`,
        external: true,
      },
      {
        title: 'Game bet settings',
        path: `${EXTERNAL_BO_PATH}/game/bets`,
        external: true,
      },
      {
        title: 'Game lent packs',
        path: `${EXTERNAL_BO_PATH}/game/lents`,
        external: true,
      },
      {
        title: 'Bonuses',
        path: `${EXTERNAL_BO_PATH}/game/gifts_spins`,
        external: true,
      },
      {
        title: 'Resend Events',
        path: `${EXTERNAL_BO_PATH}/game/resend-events`,
        external: true,
      },
      {
        title: 'Localizations',
        path: findPatchPages(PAGE_PATH.LOCALIZATIONS),
      },
    ],
  },
  {
    title: 'Tags',
    icon: (props) => <Bookmark {...props} />,
    subNav: [
      {
        title: 'Manage tags',
        path: `${EXTERNAL_BO_PATH}/tag/settings`,
        external: true,
      },
      {
        title: 'System tags',
        path: `${EXTERNAL_BO_PATH}/tag/systems`,
        external: true,
      },
      {
        title: 'Game tags',
        path: `${EXTERNAL_BO_PATH}/tag/games`,
        external: true,
      },
    ],
  },
  {
    title: 'Callbacks',
    icon: (props) => <Loop {...props} />,
    subNav: [
      {
        title: 'Callback logs',
        path: `${EXTERNAL_BO_PATH}/callback/logs`,
        external: true,
      },
      {
        title: 'Failed callbacks',
        path: `${EXTERNAL_BO_PATH}/callback/failures`,
        external: true,
      },
      {
        title: 'Callback errors',
        path: `${EXTERNAL_BO_PATH}/callback/errors`,
        external: true,
      },
      {
        title: 'Callback test',
        path: findPatchPages(PAGE_PATH.CALLBACK_TEST),
      },
    ],
  },
  {
    title: 'Statistics',
    icon: (props) => <BarChart {...props} />,
    subNav: [
      {
        title: 'Rtp by game statistics',
        path: `${EXTERNAL_BO_PATH}/statistic/game/payouts`,
        external: true,
        feature: 'analytics',
        show: (enabled) => !enabled,
      },
      {
        title: 'Rtp by game statistics',
        path: findPatchPages(PAGE_PATH.RTP_BY_GAME_STATISTICS),
        feature: 'analytics',
        show: (enabled) => enabled,
      },
      {
        title: 'Profit report by systems',
        path: `${EXTERNAL_BO_PATH}/statistic/system/profits`,
        external: true,
      },
      {
        title: 'Bet-Win daily statistics',
        path: `${EXTERNAL_BO_PATH}/statistic/betwinapi/daily`,
        external: true,
        feature: 'analytics',
        show: (enabled) => !enabled,
      },
      {
        title: 'Bet-Win daily statistics',
        path: findPatchPages(PAGE_PATH.BET_WIN_DAILY),
        feature: 'analytics',
        show: (enabled) => enabled,
      },
      {
        title: 'Generate Invoice',
        path: findPatchPages(PAGE_PATH.GENERATE_INVOICE),
      },
      {
        title: "User's Payout by Game",
        path: `${EXTERNAL_BO_PATH}/statistic/user/game/payouts`,
        external: true,
      },
      {
        title: 'Round History',
        feature: 'analytics',
        show: (enabled) => enabled,
        path: findPatchPages(PAGE_PATH.ROUND_HISTORY),
      },
    ],
  },
  {
    title: 'Players',
    icon: (props) => <Person {...props} />,
    subNav: [
      {
        title: 'Game events history',
        path: `${EXTERNAL_BO_PATH}/player/game/events`,
        external: true,
      },
      {
        title: 'Game replays',
        path: `${EXTERNAL_BO_PATH}/player/game/replays`,
        external: true,
      },
      {
        title: 'Test players',
        path: findPatchPages(PAGE_PATH.TEST_PLAYERS),
      },
      {
        title: 'User info',
        path: `${EXTERNAL_BO_PATH}/player/user/info`,
        external: true,
      },
      {
        title: 'Provider transactions',
        path: `${EXTERNAL_BO_PATH}/player/provider/transactions`,
        external: true,
      },
    ],
  },
  {
    title: 'Error logs',
    icon: (props) => <GppBad {...props} />,
    path: `${EXTERNAL_BO_PATH}/log/errors`,
    external: true,
  },
  {
    title: 'Balance Increase',
    icon: (props) => <AccountBalanceWallet {...props} />,
    path: findPatchPages(PAGE_PATH.BALANCE_INCREASE),
  },
  {
    title: 'Promo Tools',
    icon: (props) => <HomeRepairService {...props} />,
    subNav: [
      {
        title: 'Campaigns',
        path: findPatchPages(PAGE_PATH.PROMO_CAMPAIGNS),
        feature: 'promo-campaigns',
        show: (enabled) => enabled,
      },
      {
        title: 'Tournaments',
        path: findPatchPages(PAGE_PATH.TOURNAMENTS),
      },
      {
        title: 'Prize Drops',
        path: findPatchPages(PAGE_PATH.PRIZE_DROPS),
      },
      {
        title: 'Quests',
        path: findPatchPages(PAGE_PATH.QUESTS),
      },
      {
        title: 'Wheel of Fortune',
        path: findPatchPages(PAGE_PATH.WHEEL_OF_FORTUNE),
      },
      {
        divider: true,
      },
      {
        title: 'Fixed Currency Rates',
        path: findPatchPages(PAGE_PATH.FIXED_CURRENCY),
      },
      {
        title: 'Audit',
        path: findPatchPages(PAGE_PATH.AUDIT),
      },
    ],
  },
  {
    title: 'Bingo',
    icon: (props) => <EmojiEvents {...props} />,
    subNav: [
      {
        title: 'Rounds Report',
        path: findPatchPages(PAGE_PATH.BINGO_REPORTS),
      },
      {
        title: 'Players Report',
        path: findPatchPages(PAGE_PATH.BINGO_PLAYERS_REPORTS),
      },
    ],
  },
  {
    title: 'Event logs',
    icon: (props) => <GppBad {...props} />,
    path: findPatchPages(PAGE_PATH.EVENT_LOGS),
  },
  {
    title: 'Client Area',
    icon: (props) => <HdrAuto {...props} />,
    feature: 'client-area-tab',
    show: (enabled) => !!enabled,
    subNav: [
      {
        title: 'News and Updates',
        path: findPatchPages(PAGE_PATH.CLIENT_AREA_NEWS),
      },
      // {
      //   title: 'Promotions',
      //   path: findPatchPages(PAGE_PATH.CLIENT_AREA_PROMOTIONS).replace(
      //     ':provider?',
      //     '',
      //   ),
      // },
    ],
  },
];
