import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { toggleColorMode } from 'store/slices/ui';
import { userSelector } from 'store/slices/user';

import { doLogout } from 'services/UserService';

import {
  AccountCircle,
  DarkMode,
  LightMode,
  Logout,
  Menu as MenuIcon,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { PAGE_PATH } from 'components/config/pages';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#304156' : '#121212',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const propTypes = {
  handleSidebarToggle: PropTypes.func.isRequired,
};

const Header = ({ handleSidebarToggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const userInfo = useSelector(userSelector.getInfo);

  const handleMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  const handleProfile = useCallback(() => {
    navigate(PAGE_PATH.PROFILE);
    handleClose();
  }, []);
  const handleColorMode = useCallback(() => dispatch(toggleColorMode()), []);

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleSidebarToggle}
          edge="start"
          sx={{ marginRight: 4 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Back Office
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
          {userInfo?.username && <Typography>{userInfo.username}</Typography>}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleProfile}>
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={doLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleColorMode}>
              <ListItemIcon>
                {theme.palette.mode === 'dark' ? (
                  <LightMode fontSize="small" />
                ) : (
                  <DarkMode fontSize="small" />
                )}
              </ListItemIcon>
              {theme.palette.mode === 'dark' ? 'light' : 'dark'} theme
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = propTypes;
export default Header;
