import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import dayjs from 'dayjs';
import { useToggle } from 'hooks/useToggle';
import _ from 'lodash';

import {
  useGetTournamentsCampaignsItemQuery,
  useUpdateTournamentsCampaignMutation,
} from 'store/api/api/tournamentService/campaignController';
import { useUploadImageMutation } from 'store/api/api/tournamentService/imageController';
import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { useGetGamesQuery } from 'store/api/hhs/recipe.api';
import { showAlert } from 'store/slices/alert';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { getEventDurationInMs, getStartOfTheDay } from 'utils/durations';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import {
  AddCircle,
  Delete,
  InfoOutlined,
  KeyboardBackspace,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import { DateTimePicker, TimePicker } from '@mui/x-date-pickers-pro';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import PrizeSet from 'components/UI/PrizeSet/PrizeSet';
import { validatePrizeSet } from 'components/UI/PrizeSet/validate';
import PromoTranslations from 'components/UI/PromoTranslations/PromoTranslations';
import Columns from 'components/UI/Table/Columns';
import NotFound from 'components/pages/NotFound/NotFound';
import { HelperList } from 'components/pages/PromoTools/Tournaments/Campaigns/helperList';

import { tournamentSubTypes } from 'components/config/campaigns';
import { PAGE_PATH } from 'components/config/pages';
import { defaultExpirationPeriodInDays } from 'components/config/prizeSet';
import { scoringRules } from 'components/config/scoringRule';
import { fields } from 'components/config/tournamentsTranslations';
import { DateTimeFormat } from 'config/dates';

const initPrizeSet = {
  prize: {
    freeRoundExpirationPeriod: defaultExpirationPeriodInDays,
  },
  rankRangeStart: '',
  rankRangeEnd: '',
};

const initialValue = (editItem, racesEnabled) =>
  editItem
    ? {
        ...editItem,
        operatorCodes: editItem.operatorCodes?.join(', ') ?? '',
        monetaryConfig: {
          ...editItem.monetaryConfig,
          distributionEntries:
            editItem.monetaryConfig.distributionEntries?.map((item) => ({
              ...item,
              id: _.uniqueId(),
            })) ?? [],
        },
        prizeSet: initPrizeSet,
        translations: editItem.translations || [],
        temporalDetails: {
          ...editItem.temporalDetails,
          eventDurationMs: editItem.temporalDetails?.eventDurationMs
            ? new Date(
                getStartOfTheDay().getTime() +
                  editItem.temporalDetails.eventDurationMs,
              )
            : '',
        },
      }
    : {
        name: '',
        monetaryConfig: {
          scoringRule: '',
          baseCurrency: null,
          minimalBet: '',
          distributionEntries: [],
          subType: 'REGULAR',
          ...(racesEnabled && { goalScore: '' }),
        },
        temporalDetails: {
          pendingHours: '',
          showAfterFinishHours: '',
          startDateTime: '',
          finishDateTime: '',
          eventDurationMs: '',
        },
        smallBanner: null,
        mediumBanner: null,
        largeBanner: null,
        operatorCodes: '',
        gameCodes: [],
        crossEnv: false,
        clientAreaTournament: false,
        limitToBaseCurrency: false,
        testCampaign: false,
        prizeSet: initPrizeSet,
        translations: [],
      };

const CampaignsItem = () => {
  const dispatch = useDispatch();

  const { itemId } = useParams();
  const navigate = useNavigate();

  const editItemStore = useSelector(tableSelector.getEditItem);
  const racesEnabled = useFeatureIsOn('races');
  const eventDurationEnabled = useFeatureIsOn('event-duration');

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetTournamentsCampaignsItemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();
  const { data: dataGames, isLoading: gamesLoading } = useGetGamesQuery();

  const [uploadImage] = useUploadImageMutation();
  const [updateCampaigns] = useUpdateTournamentsCampaignMutation();

  const racesSubType = useMemo(
    () => racesEnabled && itemsValue?.monetaryConfig.subType === 'RACES',
    [racesEnabled, itemsValue?.monetaryConfig.subType],
  );

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.TOURNAMENTS_CAMPAIGNS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
        uploadImage,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const cloneItemsValue = _.cloneDeep(itemsValue);
    const result = await updateCampaigns({
      ...(editItem?.id && { id: editItem.id }),
      ..._.omit(cloneItemsValue, 'prizeSet'),
      operatorCodes: cloneItemsValue.operatorCodes
        .split(',')
        .flatMap((item) => {
          const value = item.trim();
          return value ? [value] : [];
        }),
      monetaryConfig: {
        ...cloneItemsValue.monetaryConfig,
        distributionEntries:
          cloneItemsValue.monetaryConfig.distributionEntries.map((item) =>
            _.omit(item, 'id'),
          ),
        ...(racesEnabled && {
          goalScore: racesSubType
            ? cloneItemsValue.monetaryConfig.goalScore
            : null,
        }),
      },
      temporalDetails: {
        ...cloneItemsValue.temporalDetails,
        ...(eventDurationEnabled && {
          eventDurationMs: getEventDurationInMs(
            cloneItemsValue.temporalDetails.eventDurationMs,
            getStartOfTheDay(),
          ),
        }),
      },
    });

    editItemClass.checkUpdateData(result);
  }, [editItem, itemsValue, racesSubType, racesEnabled, eventDurationEnabled]);

  const updateImg = useCallback(
    async () =>
      await editItemClass.updateImg(
        ['smallBanner', 'mediumBanner', 'largeBanner'],
        itemsValue,
      ),
    [itemsValue],
  );

  const handleChange = useCallback(
    ({ name, value, ...props }) =>
      editItemClass.handleChange({
        name,
        value,
        errors,
        ...props,
      }),
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    let errorsSubmit = [];

    if (itemsValue.translations.length < 1) {
      errorsSubmit.push('translations');
    }

    if (itemsValue.monetaryConfig.distributionEntries.length) {
      itemsValue.monetaryConfig.distributionEntries.map((item, index) => {
        errorsSubmit.concat(
          validatePrizeSet({
            itemPrize: item,
            itemPath: `monetaryConfig.distributionEntries.${index}.`,
            type: 'tournament',
            errorsSubmit,
          }),
        );
      });
    } else {
      dispatch(
        showAlert({
          type: 'error',
          text: 'You must add at least one distribution entry',
        }),
      );
      errorsSubmit.concat(
        validatePrizeSet({
          itemPrize: itemsValue.prizeSet,
          itemPath: 'prizeSet.',
          type: 'tournament',
          errorsSubmit,
        }),
      );
    }

    await editItemClass.handleSubmit({
      itemsValue,
      updateImg,
      errorsSubmit,
      imageFields: ['mediumBanner'],
      textFields: [
        'name',
        'monetaryConfig.scoringRule',
        'monetaryConfig.baseCurrency',
        'temporalDetails.startDateTime',
        'temporalDetails.finishDateTime',
        'operatorCodes',
        ...(racesEnabled ? ['monetaryConfig.subType'] : []),
      ],
      numericFields: [
        'temporalDetails.pendingHours',
        'temporalDetails.showAfterFinishHours',
        'monetaryConfig.minimalBet',
        ...(racesSubType ? ['monetaryConfig.goalScore'] : []),
      ],
      multipleFields: ['gameCodes'],
    });
  }, [itemsValue, racesSubType, racesEnabled]);

  const handleAdd = useCallback(() => {
    const errorsSubmit = [
      ...validatePrizeSet({
        itemPrize: itemsValue.prizeSet,
        itemPath: 'prizeSet.',
        type: 'tournament',
        errorsSubmit: [],
      }),
    ];

    errorsSubmit.length
      ? setErrors(errorsSubmit)
      : setItemsValue((prevState) => ({
          ...prevState,
          monetaryConfig: {
            ...prevState.monetaryConfig,
            distributionEntries: [
              ...prevState.monetaryConfig.distributionEntries,
              {
                ...itemsValue.prizeSet,
                id: _.uniqueId(),
              },
            ],
          },
          prizeSet: initPrizeSet,
        }));
  }, [itemsValue]);

  const handleDelete = useCallback(
    (row) =>
      setItemsValue((prevState) => {
        const modifyEntries = _.cloneDeep(
          prevState.monetaryConfig.distributionEntries,
        );
        modifyEntries.splice(row, 1);

        return {
          ...prevState,
          monetaryConfig: {
            ...prevState.monetaryConfig,
            distributionEntries: modifyEntries,
          },
        };
      }),
    [],
  );

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem));
      } else {
        setItemsValue(initialValue(null, racesEnabled));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (itemId === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [itemId, editItemStore]);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}

      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem
                  ? editItem.id
                    ? `Update "${editItem.name}"`
                    : `Copy by "${editItem.name}"`
                  : 'Create Tournament Campaign'}
              </Box>
            </Box>
            <IconButton onClick={handleOpenHelperModal}>
              <InfoOutlined />
            </IconButton>
          </Box>

          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'scroll',
            }}
          >
            <Columns count={3}>
              <Text
                required
                label="Campaign name"
                name="name"
                fullWidth
                onChange={({ target }) => handleChange(target)}
                error={errors.includes('name')}
                value={itemsValue.name}
              />

              <DateTimePicker
                sx={{ width: '100%' }}
                label="Start date"
                format={DateTimeFormat}
                timeSteps={{ minutes: 1 }}
                timezone="UTC"
                onChange={(newValue) => {
                  if (newValue && newValue.format() !== 'Invalid Date') {
                    handleChange({
                      name: 'temporalDetails.startDateTime',
                      value: newValue.format(),
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                    name: 'Start date',
                    error: errors.includes('temporalDetails.startDateTime'),
                  },
                }}
                {...(editItem && {
                  defaultValue: dayjs.utc(
                    itemsValue.temporalDetails.startDateTime,
                  ),
                })}
              />
              <DateTimePicker
                sx={{ width: '100%' }}
                label="End date"
                format={DateTimeFormat}
                timeSteps={{ minutes: 1 }}
                timezone="UTC"
                onChange={(newValue) => {
                  if (newValue && newValue.format() !== 'Invalid Date') {
                    handleChange({
                      name: 'temporalDetails.finishDateTime',
                      value: newValue.format(),
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                    name: 'End date',
                    error: errors.includes('temporalDetails.finishDateTime'),
                  },
                }}
                {...(editItem && {
                  defaultValue: dayjs.utc(
                    itemsValue.temporalDetails.finishDateTime,
                  ),
                })}
              />
              <Text
                required
                label="Pending time"
                name="temporalDetails.pendingHours"
                onChange={({ target }) => handleChange(target)}
                error={errors.includes('temporalDetails.pendingHours')}
                value={itemsValue.temporalDetails.pendingHours}
              />

              <Text
                required
                label="Show after finish"
                name="temporalDetails.showAfterFinishHours"
                onChange={({ target }) => handleChange(target)}
                error={errors.includes('temporalDetails.showAfterFinishHours')}
                value={itemsValue.temporalDetails.showAfterFinishHours}
              />
              <Autocomplete
                label="Base currency"
                name="monetaryConfig.baseCurrency"
                error={errors.includes('monetaryConfig.baseCurrency')}
                value={itemsValue.monetaryConfig.baseCurrency}
                handleChange={handleChange}
                options={dataCurrency || []}
                loading={currencyLoading}
              />

              <Text
                required
                fullWidth
                label="Min. bet to participating"
                name="monetaryConfig.minimalBet"
                onChange={({ target }) => handleChange(target)}
                error={errors.includes('monetaryConfig.minimalBet')}
                value={itemsValue.monetaryConfig.minimalBet}
              />

              <Select
                label="Scoring rule"
                name="monetaryConfig.scoringRule"
                error={errors.includes('monetaryConfig.scoringRule')}
                value={itemsValue.monetaryConfig.scoringRule}
                handleChange={handleChange}
                options={scoringRules}
              />

              {racesEnabled && (
                <>
                  <Select
                    name="monetaryConfig.subType"
                    label="Tournament subType"
                    value={itemsValue.monetaryConfig.subType}
                    options={tournamentSubTypes}
                    handleChange={handleChange}
                    error={!!errors.includes('monetaryConfig.subType')}
                  />

                  {racesSubType && (
                    <Text
                      name="monetaryConfig.goalScore"
                      label="Goal score for event"
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('monetaryConfig.goalScore')}
                      value={itemsValue.monetaryConfig.goalScore}
                      required
                    />
                  )}
                </>
              )}

              {eventDurationEnabled && (
                <TimePicker
                  sx={{ width: '100%' }}
                  label="Event duration"
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  timeSteps={{ minutes: 1 }}
                  onChange={(newValue) => {
                    if (newValue && newValue.format() !== 'Invalid Date') {
                      handleChange({
                        name: 'temporalDetails.eventDurationMs',
                        value: newValue.format(),
                      });
                    }
                  }}
                  slotProps={{
                    textField: {
                      error: !!errors.includes(
                        'temporalDetails.eventDurationMs',
                      ),
                    },
                  }}
                  {...(editItem && {
                    defaultValue: dayjs(
                      itemsValue.temporalDetails.eventDurationMs,
                    ),
                  })}
                />
              )}
            </Columns>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={itemsValue.clientAreaTournament}
                    checked={itemsValue.crossEnv}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({
                        name,
                        value: checked,
                      })
                    }
                  />
                }
                label="Cross environment"
                name="crossEnv"
                sx={{ mx: 0 }}
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={itemsValue.crossEnv}
                    checked={itemsValue.clientAreaTournament}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({
                        name,
                        value: checked,
                      })
                    }
                  />
                }
                label="Client Area Tournament"
                name="clientAreaTournament"
                sx={{ mx: 0 }}
              />
              <Tooltip
                title="Only users, that playing with the same currency, as campaigns base currency, can participate in a given campaign"
                arrow
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={itemsValue.limitToBaseCurrency}
                      onChange={({ target: { name, checked } }) =>
                        handleChange({
                          name,
                          value: checked,
                        })
                      }
                    />
                  }
                  label="Limit to base currency"
                  name="limitToBaseCurrency"
                  sx={{ mx: 0 }}
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    checked={itemsValue.testCampaign}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({
                        name,
                        value: checked,
                      })
                    }
                  />
                }
                label="Test campaign"
                name="testCampaign"
                sx={{ mx: 0 }}
              />
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }}>Prize distribution</Divider>

            {itemsValue.monetaryConfig.distributionEntries.map(
              (item, index) => (
                <React.Fragment key={item.id}>
                  {!!index && <Divider sx={{ mb: 2 }} />}
                  <Box
                    component="div"
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 50px',
                      gap: 2,
                    }}
                  >
                    <PrizeSet
                      handleChange={handleChange}
                      itemsValue={item}
                      objectPath={`monetaryConfig.distributionEntries.${index}.`}
                      errors={errors}
                      type="tournament"
                      showTooltip={false}
                      {...(editItem && {
                        editItem:
                          editItem.monetaryConfig.distributionEntries[index],
                      })}
                    />
                    <Box
                      component="div"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <IconButton onClick={() => handleDelete(index)}>
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                </React.Fragment>
              ),
            )}

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Box
              component="div"
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 40px',
                columnGap: 1,
              }}
            >
              <PrizeSet
                objectPath="prizeSet."
                handleChange={handleChange}
                itemsValue={itemsValue.prizeSet}
                errors={errors}
                type="tournament"
                showTooltip={false}
              />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={handleAdd}>
                  <AddCircle />
                </IconButton>
              </Box>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />

            <Autocomplete
              label="Participating games"
              name="gameCodes"
              error={errors.includes('gameCodes')}
              value={itemsValue.gameCodes}
              handleChange={handleChange}
              options={dataGames || []}
              loading={gamesLoading}
              multiple
            />

            <Text
              required
              fullWidth
              label="Operator codes"
              name="operatorCodes"
              onChange={({ target }) => handleChange(target)}
              error={errors.includes('operatorCodes')}
              value={itemsValue.operatorCodes}
              multiline
              maxRows={3}
            />

            <Divider
              sx={{
                my: 2,
                color: errors.includes('translations') ? 'error.main' : '',
              }}
            >
              Translations
            </Divider>

            <PromoTranslations
              fields={fields}
              values={itemsValue.translations}
              handleChange={(value) =>
                handleChange({ name: 'translations', value })
              }
              required={
                !itemsValue.translations.length ||
                errors.includes('translations')
              }
            />

            <Divider sx={{ mt: 2, mb: 2 }}>Banners</Divider>

            <Columns count={3}>
              <ImageUpload
                label="Small"
                value={itemsValue.smallBanner}
                handleChange={(value) =>
                  handleChange({ name: 'smallBanner', value })
                }
                hasError={errors.includes('smallBanner')}
                required={false}
              />
              <ImageUpload
                label="Medium"
                value={itemsValue.mediumBanner}
                handleChange={(value) =>
                  handleChange({ name: 'mediumBanner', value })
                }
                hasError={errors.includes('mediumBanner')}
              />
              <ImageUpload
                label="Large"
                value={itemsValue.largeBanner}
                handleChange={(value) =>
                  handleChange({ name: 'largeBanner', value })
                }
                hasError={errors.includes('largeBanner')}
                required={false}
              />
            </Columns>
          </Stack>
          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button onClick={handleSubmit} variant="contained">
              {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
            </Button>
          </Box>
          {openHelperModal && (
            <HelperModal
              handleClose={handleCloseHelperModal}
              helperList={HelperList}
            />
          )}
        </>
      )}
    </>
  );
};

export default CampaignsItem;
