export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'User ID',
    sortLabel: 'user_id',
    value: (row) => row.user_id,
  },
  {
    name: 'Partner ID',
    sortLabel: 'partner_id',
    value: (row) => row.partner_id,
  },
  {
    name: 'Event ID',
    sortLabel: 'event_id',
    value: (row) => row.event_id,
  },
  {
    name: 'Type',
    sortLabel: 'type',
    value: (row) => row.type,
  },
  {
    name: 'Create',
    sortLabel: 'created_at',
    value: (row) => row.created_at,
  },
  {
    name: 'Wallet Type',
    sortLabel: 'wallet_type',
    value: (row) => row.wallet_type,
  },
  {
    name: 'Currency',
    sortLabel: 'currency',
    value: (row) => row.currency,
  },
  {
    name: 'Original Currency',
    sortLabel: 'original_currency',
    value: (row) => row.original_currency,
  },
  {
    name: 'Amount',
    sortLabel: 'amount',
    value: (row) => row.amount,
  },
  {
    name: 'Original Amount',
    sortLabel: 'original_amount',
    value: (row) => row.original_amount,
  },
  {
    name: 'Conversion Rate',
    value: (row) => row.conversion_rate,
  },
  {
    name: 'User Message',
    value: (row) => row.user_message,
  },
];

export const filters = {
  idempotency_key: 'ID',
  user_id: 'User ID',
  partner_id: 'Select system',
  wallet_type: 'Wallet Type',
  amount: 'Amount',
  currency: 'Currency',
  event_id: 'Event ID',
  user_message: 'User Message',
  is_sent: 'Is Sent',
  type: 'Type',
  created_from: 'Created From',
  created_to: 'Created To',
};
