import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const game = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getGameList: build.query({
      query: () => ({
        url: '/game/v1/list',
      }),
      transformResponse: (response) =>
        response?.map(({ gameId, name, absoluteName }) => ({
          label: `${gameId} ${name} ${absoluteName}`,
          value: gameId,
        })),
    }),
  }),
});

export const { useGetGameListQuery } = game;
