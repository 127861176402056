import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  changeOrder,
  changePage,
  changeRowsPerPage,
  filterColumns,
  initialConfig,
  tableSelector,
} from 'store/slices/table';

import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Table as MuiTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import Actions from 'components/UI/Table/Actions';
import Collapse from 'components/UI/Table/Collapse';
import FilterList from 'components/UI/Table/FilterList';

const StyledTableContainer = styled(TableContainer)`
  .MuiTableSortLabel-root:not(.Mui-active) .MuiTableSortLabel-icon {
    opacity: 0.4;
    zoom: 0.8;
  }
`;

const propTypes = {
  getDataQuery: PropTypes.func.isRequired,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.func.isRequired,
      sortLabel: PropTypes.string,
      sortDirection: PropTypes.oneOf(['asc', 'desc']),
      collapse: PropTypes.shape({
        id: PropTypes.func.isRequired,
        data: PropTypes.func.isRequired,
        columns: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string.isRequired,
            value: PropTypes.func.isRequired,
          }),
        ).isRequired,
      }),
    }),
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      handleClick: PropTypes.func,
    }),
  ),
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  queryConditional: PropTypes.shape({}),
  editableRow: PropTypes.bool,
  customizeRow: PropTypes.func,
  showPagination: PropTypes.bool,
  resetPage: PropTypes.bool,
};

const Table = ({
  getDataQuery,
  columns,
  rowsPerPageOptions = [25, 50, 100],
  handleEdit = () => {},
  handleDelete = () => {},
  queryConditional = null,
  actions = null,
  editableRow = true,
  customizeRow = null,
  showPagination = true,
  resetPage = true,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [expand, setExpand] = useState(null);
  const tableRef = useRef(null);

  const columnsName = useMemo(
    () => columns.reduce((acc, value) => [...acc, value.name], []).join(','),
    [columns],
  );
  const firstSortColumn = useMemo(
    () => columns.find((column) => !!column.sortLabel),
    [columns],
  );
  const initialTableConfig = useMemo(
    () => ({
      page: 0,
      rowsPerPage: rowsPerPageOptions[0],
      ...(firstSortColumn && {
        orderBy: {
          name: firstSortColumn.sortLabel,
          direction: firstSortColumn.sortDirection ?? 'desc',
        },
      }),
    }),
    [columns],
  );

  const viewColumns = useSelector(tableSelector.getViewColumns(columnsName));
  const page =
    useSelector(tableSelector.getPage(columnsName)) || initialTableConfig.page;
  const rowsPerPage =
    useSelector(tableSelector.getRowsPerPage(columnsName)) ||
    initialTableConfig.rowsPerPage;
  const orderBy =
    useSelector(tableSelector.getOrderBy(columnsName)) ||
    initialTableConfig.orderBy;

  const { data, isLoading, isFetching } = getDataQuery(
    {
      page,
      size: rowsPerPage,
      ...(orderBy?.name && { sort: `${orderBy.name},${orderBy.direction}` }),
      ...queryConditional,
    },
    { skip: !viewColumns },
  );

  const handleChangePage = useCallback((event, newPage) => {
    dispatch(changePage({ columnsName, page: newPage }));
  }, []);
  const handleChangeRowsPerPage = useCallback((event) => {
    dispatch(
      changeRowsPerPage({
        columnsName,
        rowsPerPage: parseInt(event.target.value, 10),
      }),
    );
  }, []);
  const handleChangeFilter = useCallback(
    (viewColumns) => dispatch(filterColumns({ columnsName, viewColumns })),
    [],
  );
  const handleOrderBy = useCallback(
    (columnName) =>
      dispatch(
        changeOrder({
          columnsName,
          orderBy: {
            name: columnName,
            direction:
              orderBy.name === columnName
                ? orderBy.direction === 'asc'
                  ? 'desc'
                  : 'asc'
                : 'asc',
          },
        }),
      ),
    [orderBy],
  );
  const handleClickAction = useCallback((action, row) => {
    if (action.handleClick) {
      return action.handleClick(row);
    }
    if (action.label === 'Edit') {
      return handleEdit(row);
    }
    if (action.label === 'Delete') {
      return handleDelete(row);
    }
    if (action.label === 'Copy') {
      const copyRow = JSON.parse(JSON.stringify(row));
      delete copyRow.id;
      return handleEdit(copyRow);
    }
  }, []);

  const handleExpandRow = useCallback(
    async (e, column, row) => {
      preventPropagation(e);
      const value =
        expand?.id !== row.id
          ? {
              id: column.collapse.id(row),
              data: await column.collapse.data(row, dispatch),
              columns: column.collapse.columns,
            }
          : null;
      setExpand(value);
    },
    [expand],
  );

  const preventPropagation = useCallback((e) => e.stopPropagation(), []);

  const getRowOptions = useCallback((row) => {
    let options = editableRow
      ? {
          hover: true,
          sx: { cursor: 'pointer' },
        }
      : {};

    if (customizeRow) {
      options = _.merge(options, customizeRow(row));
    }
    return options;
  }, []);

  const isEmptyResponse = useMemo(
    () => !isLoading && !isFetching && (!data || !data?.content.length),
    [isLoading, isFetching, data],
  );

  useEffect(() => {
    if (!viewColumns) {
      dispatch(
        initialConfig({
          columnsName,
          viewColumns: columns.reduce(
            (acc, value) => ({ ...acc, [value.name]: true }),
            {},
          ),
          ...initialTableConfig,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (isEmptyResponse && page !== 0) {
      handleChangePage(null, 0);
    }
  }, [isEmptyResponse, page]);

  useEffect(() => {
    setExpand(null);
    if (tableRef.current) {
      tableRef.current.scrollIntoView();
    }
  }, [data, tableRef?.current]);

  useEffect(() => {
    if (page > 0 && resetPage && queryConditional) {
      handleChangePage(null, 0);
    }
  }, [queryConditional]);

  if (!viewColumns) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100px',
        width: '100%',
      }}
    >
      {(isLoading || isFetching) && <FullPageLoader />}
      {isEmptyResponse && (
        <Typography variant="h6" component="h3">
          Empty List
        </Typography>
      )}
      {!isLoading && data && data.content.length > 0 && (
        <Paper
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            maxHeight: '100%',
          }}
        >
          <StyledTableContainer>
            <MuiTable ref={tableRef} aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((item) =>
                    viewColumns[item.name] ? (
                      <TableCell key={item.name}>
                        {item.sortLabel && (
                          <TableSortLabel
                            active={orderBy.name === item.sortLabel}
                            direction={
                              orderBy.name === item.sortLabel
                                ? orderBy.direction
                                : 'asc'
                            }
                            onClick={() => handleOrderBy(item.sortLabel)}
                          >
                            {item.name}
                          </TableSortLabel>
                        )}
                        {!item.sortLabel && item.name}
                      </TableCell>
                    ) : null,
                  )}
                  {actions && actions.length > 0 && (
                    <TableCell align="center" sx={{ width: '100px' }}>
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.content.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      {...getRowOptions(row)}
                      onClick={() => editableRow && handleEdit(row)}
                    >
                      {columns.map((item) =>
                        viewColumns[item.name] ? (
                          <TableCell key={item.name}>
                            <Box
                              component="div"
                              sx={{
                                overflow: 'hidden',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {item.value(row, theme)}
                              {item.collapse && (
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={(e) => handleExpandRow(e, item, row)}
                                  sx={{ ml: 1 }}
                                >
                                  {row.id && expand?.id === row.id ? (
                                    <KeyboardArrowUp />
                                  ) : (
                                    <KeyboardArrowDown />
                                  )}
                                </IconButton>
                              )}
                            </Box>
                          </TableCell>
                        ) : null,
                      )}
                      {actions && actions.length > 0 && (
                        <TableCell align="center" onClick={preventPropagation}>
                          <Actions
                            actions={actions}
                            row={row}
                            handleClick={handleClickAction}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                    {row.id && expand?.id === row.id && (
                      <TableRow onClick={preventPropagation}>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={Object.keys(viewColumns).length + 1}
                        >
                          <Collapse
                            data={expand.data}
                            columns={expand.columns}
                          />
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))}
              </TableBody>
            </MuiTable>
          </StyledTableContainer>
          <Box>
            {showPagination ? (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={data.totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            ) : (
              <Box component="div" sx={{ height: '52px' }} />
            )}
          </Box>
          <FilterList
            handleChange={handleChangeFilter}
            viewColumns={viewColumns}
          />
        </Paper>
      )}
    </Box>
  );
};

Table.propTypes = propTypes;
export default Table;
