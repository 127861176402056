export const reportDownload = async ({
  response,
  id = null,
  name,
  format = 'xlsx',
}) => {
  if (response.ok) {
    const result = await response.blob();
    const hiddenElement = document.createElement('a');
    const url = window.URL || window.webkitURL;

    hiddenElement.href = url.createObjectURL(result);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${id ? `${id}_` : ''}${name}_report.${format}`;
    hiddenElement.click();

    return { data: null };
  }
  const result = await response.text();
  try {
    const parseResult = JSON.parse(result);
    if (parseResult.errorMessage) {
      return { errorMessage: parseResult.errorMessage };
    }
    if (parseResult.title && parseResult.detail) {
      return {
        errorMessage: `${parseResult.title}: ${parseResult.detail}`,
      };
    }
    return {
      errorMessage: 'Something went wrong',
    };
  } catch {
    return { errorMessage: result };
  }
};
