import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { useToggle } from 'hooks/useToggle';
import _ from 'lodash';

import { useGetAllQuestsEventTemplatesQuery } from 'store/api/api/tournamentService/eventTemplateController';
import {
  useGetQuestsCampaignsItemQuery,
  useUpdateQuestsCampaignMutation,
} from 'store/api/api/tournamentService/questCampaignController';
import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { showAlert } from 'store/slices/alert';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { checkValue, isNumeric } from 'utils/validate';

import { InfoOutlined, KeyboardBackspace } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers-pro';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import Columns from 'components/UI/Table/Columns';
import NotFound from 'components/pages/NotFound/NotFound';
import { HelperList } from 'components/pages/PromoTools/Quests/Campaigns/helperList';

import { PAGE_PATH } from 'components/config/pages';
import { DateTimeFormat } from 'config/dates';

const initialValue = (editItem) =>
  editItem
    ? {
        ...editItem,
        operatorCodes: editItem.operatorCodes
          ? editItem.operatorCodes?.join(', ')
          : '',
        campaignDuration: editItem.eventTemplates.length,
      }
    : {
        name: '',
        operatorCodes: '',
        eventTemplates: [],
        startDateTime: '',
        campaignDuration: '',
        baseCurrency: null,
        crossEnv: false,
        limitToBaseCurrency: false,
        testCampaign: false,
      };

const CampaignsItem = () => {
  const dispatch = useDispatch();

  const { itemId } = useParams();
  const navigate = useNavigate();

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetQuestsCampaignsItemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const { data: dataEventTemplates, isLoading: eventTemplatesLoading } =
    useGetAllQuestsEventTemplatesQuery();
  const { data: dataCurrencies, isLoading: currenciesLoading } =
    useGetCurrencyRatesQuery();

  const [updateCampaign] = useUpdateQuestsCampaignMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.QUESTS_CAMPAIGNS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const result = await updateCampaign({
      ...(editItem?.id && { id: editItem.id }),
      operatorCodes: itemsValue.operatorCodes
        .split(',')
        .map((item) => item.trim())
        .filter((item) => item),
      name: itemsValue.name,
      eventTemplates: itemsValue.eventTemplates,
      startDateTime: itemsValue.startDateTime,
      baseCurrency: itemsValue.baseCurrency,
      crossEnv: itemsValue.crossEnv,
      limitToBaseCurrency: itemsValue.limitToBaseCurrency,
      testCampaign: itemsValue.testCampaign,
    });

    editItemClass.checkUpdateData(result);
  }, [editItem, itemsValue]);

  const handleChange = useCallback(
    ({ name, value }) => {
      setItemsValue((prevState) => {
        const newState = _.cloneDeep(prevState);
        _.set(newState, name, value);
        if (name === 'campaignDuration') {
          if (value > 50) {
            dispatch(
              showAlert({
                type: 'warning',
                text: 'The number of companies cannot be more than 50',
              }),
            );
          } else {
            _.set(
              newState,
              'eventTemplates',
              isNumeric(value) ? Array(Math.trunc(value)).fill(null) : [],
            );
          }
        }
        return newState;
      });
      if (name === 'campaignDuration') {
        let newErrors = errors.filter(
          (error) => error.indexOf('eventTemplates') === -1,
        );
        if (checkValue({ value, type: 'number' })) {
          newErrors.splice(newErrors.indexOf(name), 1);
        }
        if (newErrors.length !== errors.length) {
          setErrors(newErrors);
        }
      } else {
        checkValue({ value, name, setErrors, errors });
      }
    },
    [errors],
  );

  const handleSubmit = useCallback(async () => {
    let errorsSubmit = [];

    const eventTemplates = _.get(itemsValue, 'eventTemplates');
    if (eventTemplates.length === 0) {
      errorsSubmit.push('eventTemplates');
    } else {
      eventTemplates.map((template, index) =>
        template === null
          ? errorsSubmit.push(`eventTemplates[${index}]`)
          : null,
      );
    }

    await editItemClass.handleSubmit({
      itemsValue,
      errorsSubmit,
      textFields: ['name', 'operatorCodes', 'startDateTime', 'baseCurrency'],
      numericFields: ['campaignDuration'],
    });
  }, [itemsValue, errors]);

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (itemId === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [itemId, editItemStore]);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}

      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem
                  ? editItem.id
                    ? `Update "${editItem.name}"`
                    : `Copy by "${editItem.name}"`
                  : 'Create Campaign'}
              </Box>
            </Box>
            <IconButton onClick={handleOpenHelperModal}>
              <InfoOutlined />
            </IconButton>
          </Box>

          <Box component="div" sx={{ display: 'grid', rowGap: 2, mb: 2 }}>
            <Columns count={2}>
              <Text
                required
                label="Campaign name"
                name="name"
                fullWidth
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('name')}
                value={itemsValue.name}
              />

              <DateTimePicker
                sx={{ width: '100%' }}
                label="Start date"
                format={DateTimeFormat}
                timeSteps={{ minutes: 1 }}
                interval={1}
                timezone="UTC"
                onChange={(newValue) => {
                  if (newValue && newValue.format() !== 'Invalid Date') {
                    handleChange({
                      name: 'startDateTime',
                      value: newValue.format(),
                    });
                  }
                }}
                slotProps={{
                  textField: {
                    required: true,
                    name: 'Start date',
                    error: !!errors.includes('startDateTime'),
                  },
                }}
                {...(editItem && {
                  defaultValue: dayjs(itemsValue.startDateTime),
                })}
              />
              <Text
                required
                fullWidth
                label="Campaign duration"
                name="campaignDuration"
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('campaignDuration')}
                value={itemsValue.campaignDuration}
              />
              <Autocomplete
                label="Base currency"
                name="baseCurrency"
                value={itemsValue.baseCurrency}
                handleChange={handleChange}
                options={dataCurrencies || []}
                error={errors.includes('baseCurrency')}
                loading={currenciesLoading}
              />
            </Columns>

            <Text
              required
              fullWidth
              label="Operator codes"
              name="operatorCodes"
              onChange={({ target }) => handleChange(target)}
              error={!!errors.includes('operatorCodes')}
              value={itemsValue.operatorCodes}
              multiline
              maxRows={3}
            />

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={itemsValue.crossEnv}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({
                        name,
                        value: checked,
                      })
                    }
                  />
                }
                label="Cross environment"
                name="crossEnv"
                sx={{ mx: 0 }}
              />
              <Tooltip
                title="Only users, that playing with the same currency, as campaigns base currency, can participate in a given campaign"
                arrow
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={itemsValue.limitToBaseCurrency}
                      onChange={({ target: { name, checked } }) =>
                        handleChange({
                          name,
                          value: checked,
                        })
                      }
                    />
                  }
                  label="Limit to base currency"
                  name="limitToBaseCurrency"
                  sx={{ mx: 0 }}
                />
              </Tooltip>
              <FormControlLabel
                control={
                  <Switch
                    checked={itemsValue.testCampaign}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({
                        name,
                        value: checked,
                      })
                    }
                  />
                }
                label="Test campaign"
                name="testCampaign"
                sx={{ mx: 0 }}
              />
            </Box>

            {itemsValue.eventTemplates.length > 0 && (
              <>
                <Divider sx={{ mt: 2, mb: 2 }}>Events Template list</Divider>
                {itemsValue.eventTemplates.map((template, index) => (
                  <Autocomplete
                    key={index}
                    label={`Event Template ${index + 1}`}
                    name={`eventTemplates[${index}]`}
                    error={
                      !!errors.includes('eventTemplates') ||
                      !!errors.includes(`eventTemplates[${index}]`)
                    }
                    value={template || null}
                    handleChange={handleChange}
                    options={dataEventTemplates || []}
                    loading={eventTemplatesLoading}
                  />
                ))}
              </>
            )}
          </Box>
          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button onClick={handleSubmit} variant="contained">
              {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
            </Button>
          </Box>
          {openHelperModal && (
            <HelperModal
              handleClose={handleCloseHelperModal}
              helperList={HelperList}
            />
          )}
        </>
      )}
    </>
  );
};

export default CampaignsItem;
