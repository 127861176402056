import { hhs } from 'store/api/hhs';

const integrationTypes = hhs.injectEndpoints({
  endpoints: (build) => ({
    getIntegrationTypes: build.query({
      query: () => ({
        url: '/api/v2/integration/types',
      }),
      transformResponse: (response) =>
        Object.entries(response ?? {}).map(([label, value]) => ({
          label,
          value,
        })),
    }),
  }),
});

export const { useGetIntegrationTypesQuery } = integrationTypes;
