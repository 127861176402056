import { checkValue } from 'utils/validate';

export const validatePrizeSet = ({
  itemPrize,
  errorsSubmit,
  itemPath,
  type,
}) => {
  if (!Array.isArray(errorsSubmit)) {
    return [];
  }

  try {
    if (!checkValue({ value: itemPrize.prize.prizeType })) {
      errorsSubmit.push(`${itemPath}prize.prizeType`);
    }
    if (type === 'prize_drops' || type === 'wheel_of_fortune') {
      if (!checkValue({ value: itemPrize?.prizeQuantity })) {
        errorsSubmit.push(`${itemPath}prizeQuantity`);
      }
    }
    if (type === 'tournament') {
      if (
        !checkValue({
          value: itemPrize?.rankRangeStart,
          type: 'number',
        })
      ) {
        errorsSubmit.push(`${itemPath}rankRangeStart`);
      }
      if (
        !checkValue({
          value: itemPrize?.rankRangeEnd,
          type: 'number',
        })
      ) {
        errorsSubmit.push(`${itemPath}rankRangeEnd`);
      }
    }
    if (itemPrize.prize.prizeType === 'MONEY') {
      if (
        !checkValue({
          value: itemPrize.prize.moneyPrize,
          type: 'number',
        })
      ) {
        errorsSubmit.push(`${itemPath}prize.moneyPrize`);
      }
    }
    if (itemPrize.prize.prizeType === 'FREE_ROUND') {
      ['freeRoundNumber', 'freeRoundBet', 'freeRoundExpirationPeriod'].map(
        (name) => {
          if (!checkValue({ value: itemPrize.prize[name], type: 'number' })) {
            errorsSubmit.push(`${itemPath}prize.${name}`);
          }
        },
      );

      const isPrizeForActiveGame = itemPrize.prize.isPrizeForActiveGame;
      if (
        !isPrizeForActiveGame &&
        !checkValue({ value: itemPrize.prize.freeRoundGameCode })
      ) {
        errorsSubmit.push(`${itemPath}prize.freeRoundGameCode`);
      }
    }
    if (itemPrize.prize.prizeType === 'TEXT') {
      if (
        !checkValue({
          value: itemPrize.prize.textPrize,
          maxLength: 60,
        })
      ) {
        errorsSubmit.push(`${itemPath}prize.textPrize`);
      }
    }
  } catch (e) {
    return [e.messages];
  }

  return errorsSubmit;
};
