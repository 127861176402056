import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { userSelector } from 'store/slices/user';

import { getENV } from 'utils/getEnv';

import { devEnvs } from 'config/api';

const useAccessView = () => {
  const userInfo = useSelector(userSelector.getInfo);
  const bo_name = devEnvs.includes(getENV()) ? 'bo' : `bo-${getENV()}`;

  return useMemo(
    () => userInfo?.tokenParsed?.resource_access[bo_name]?.roles?.length > 0,
    [userInfo],
  );
};

export default useAccessView;
