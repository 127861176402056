import { useCallback } from 'react';

import { useSearch } from 'hooks/useSearch';

import {
  useDeleteSystemMutation,
  useGetSystemsQuery,
} from 'store/api/hhsKeyCloack/systemSettings';

import { findPatchPages } from 'utils/pages';

import { Delete, Difference, Edit } from '@mui/icons-material';
import { TextField } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';

import { PAGE_PATH } from 'components/config/pages';
import { columns } from 'components/config/systemSettings';

const SystemSettings = () => {
  const [deleteSystem] = useDeleteSystemMutation();

  const { searchParams, handleSearch } = useSearch();

  const customizeRow = useCallback(
    ({ disabled }) =>
      +disabled
        ? {
            sx: {
              opacity: 0.5,
              pointerEvents: 'none',
            },
          }
        : {},
    [],
  );

  return (
    <TableLayout
      title="System Settings"
      columns={columns}
      getDataQuery={useGetSystemsQuery}
      queryConditional={searchParams}
      deleteItemQuery={deleteSystem}
      customizeRow={customizeRow}
      actions={[
        { label: 'Edit', icon: <Edit /> },
        { label: 'Delete', icon: <Delete /> },
        { label: 'Copy', icon: <Difference /> },
      ]}
      itemComponentPath={findPatchPages(PAGE_PATH.SYSTEM_SETTINGS_ITEM)}
    >
      <TextField
        name="system"
        label="Enter keyword"
        onChange={handleSearch}
        sx={{ alignSelf: 'flex-start', mb: 2 }}
      />
    </TableLayout>
  );
};

export default SystemSettings;
