import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { setEditItem, tableSelector } from 'store/slices/table';

import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import Confirm from 'components/UI/Confirm/Confirm';
import Subtitle from 'components/UI/Subtitle/Subtitle';
import Table from 'components/UI/Table/Table';

const propTypes = {
  getDataQuery: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  deleteItemQuery: PropTypes.func,
  dialogComponent: PropTypes.func,
  queryConditional: PropTypes.shape({}),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.node,
      handleClick: PropTypes.func,
    }),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  editableRow: PropTypes.bool,
  itemComponentPath: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  customizeRow: PropTypes.func,
};

const TableLayout = ({
  children = null,
  getDataQuery,
  columns,
  deleteItemQuery = () => {},
  title,
  subTitle = null,
  dialogComponent = null,
  queryConditional = {},
  actions = [
    { label: 'Edit', icon: <Edit /> },
    { label: 'Delete', icon: <Delete /> },
  ],
  editableRow = true,
  itemComponentPath = null,
  customizeRow,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const editItem = useSelector(tableSelector.getEditItem);

  const handleCreate = useCallback(() => {
    dispatch(setEditItem(null));
    setOpen(true);
  }, []);
  const handleEdit = useCallback((item) => {
    dispatch(setEditItem(item));
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    dispatch(setEditItem(null));
    setOpen(false);
  }, []);
  const handleDelete = useCallback((item) => {
    dispatch(setEditItem(item));
    setConfirm(true);
  }, []);
  const handleCloseDelete = useCallback(
    (result) => {
      if (result) {
        deleteItemQuery(editItem);
      }
      dispatch(setEditItem(null));
      setConfirm(false);
    },
    [editItem],
  );

  useEffect(() => {
    if (itemComponentPath && open) {
      if (typeof itemComponentPath === 'function') {
        navigate(itemComponentPath(editItem));
      } else {
        const itemId = editItem ? (editItem?.id ? editItem.id : 'copy') : 'add';
        navigate(itemComponentPath.replace(':itemId', itemId));
      }
    }
  }, [editItem, open, itemComponentPath]);

  return (
    <>
      <Box
        sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, mb: 2 }}
        component="div"
      >
        <Box>
          <Typography variant="h5" component="h2">
            {title}
          </Typography>
          {subTitle && <Subtitle subTitle={subTitle} />}
        </Box>
        {(dialogComponent || itemComponentPath) && (
          <Box>
            <Button variant="contained" onClick={handleCreate}>
              Add
            </Button>
          </Box>
        )}
      </Box>
      {children}
      <Table
        actions={actions}
        getDataQuery={getDataQuery}
        columns={columns}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        queryConditional={queryConditional}
        editableRow={editableRow}
        customizeRow={customizeRow}
      />
      {open && dialogComponent && dialogComponent({ handleClose, editItem })}
      {confirm && <Confirm handleClose={handleCloseDelete} />}
    </>
  );
};

TableLayout.propTypes = propTypes;
export default TableLayout;
