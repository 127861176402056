import { configService } from 'store/api/configService/index';

const operatorController = configService.injectEndpoints({
  endpoints: (build) => ({
    getOperatorsListByEnv: build.query({
      query: ({ env }) => ({
        url: '/config-service/admin/v1/operators',
        params: { env },
      }),
      transformResponse: (response) =>
        response?.reduce(
          (acc, { title, id }) => [
            ...acc,
            { label: `#${id}: ${title}`, value: id },
          ],
          [],
        ),
    }),
  }),
});

export const { useGetOperatorsListByEnvQuery } = operatorController;
