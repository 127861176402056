import dayjs from 'dayjs';

import { Chip, Stack } from '@mui/material';

import { DateTimeFormat } from 'config/dates';

const getCampaignStatus = (row) => {
  const startDate = dayjs.utc(new Date(row.temporalDetails.startDateTime));
  const endDate = dayjs.utc(new Date(row.temporalDetails.finishDateTime));
  const currentDate = dayjs.utc(new Date());

  if (startDate.diff(currentDate) > 0) {
    return 'Pending';
  }

  if (endDate.diff(currentDate) < 0) {
    return 'Finished';
  }

  return 'Live';
};

export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: (row) => row.name,
  },
  {
    name: 'Geography',
    sortLabel: 'geography',
    value: () => 'Network', // TODO: replace with real data
  },
  {
    name: 'Market',
    sortLabel: 'market',
    value: () => 'Europe', // TODO: replace with real data
  },
  {
    name: 'Type',
    sortLabel: 'type',
    value: () => 'Real', // TODO: replace with real data
  },
  {
    name: 'Status',
    sortLabel: 'status',
    value: (row) => getCampaignStatus(row),
  },
  {
    name: 'Promo',
    sortLabel: 'promo',
    value: () => (
      // TODO: replace with real data
      <Stack direction="row" spacing={1}>
        <Chip label="Tournament" color="info" size="small" />
        <Chip label="Prize Drops" color="warning" size="small" />
      </Stack>
    ),
  },
  {
    name: 'Prize pool',
    sortLabel: 'prizePool.prizePool',
    value: (row) => row.prizePool?.prizePool,
  },
  {
    name: 'Games',
    value: (row) => row.gameCodes?.length ?? 0,
  },
  {
    name: 'Operators',
    value: (row) => row.operatorCodes?.length ?? 0,
  },
  {
    name: 'Start',
    sortLabel: 'temporalDetails.startDateTime',
    value: (row) =>
      dayjs
        .utc(new Date(row.temporalDetails?.startDateTime))
        .format(DateTimeFormat),
  },
  {
    name: 'End',
    sortLabel: 'temporalDetails.finishDateTime',
    value: (row) =>
      dayjs
        .utc(new Date(row.temporalDetails?.finishDateTime))
        .format(DateTimeFormat),
  },
];
