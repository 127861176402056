export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: (row) => row.id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: (row) => row.name,
  },
  {
    name: 'Quests',
    value: (row) => row.quests?.length,
  },
];
