import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { findPatchPages } from 'utils/pages';

import { Tab, Tabs } from '@mui/material';

import GameTags from 'components/pages/Tags/GameTags/GameTags';
import ManageTags from 'components/pages/Tags/ManageTags/ManageTags';
import SystemTags from 'components/pages/Tags/SystemTags/SystemTags';

import { PAGE_PATH } from 'components/config/pages';

const Tags = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(location.pathname);

  const handleChange = useCallback((event, newValue) => {
    navigate(newValue);
    setActiveTab(newValue);
  }, []);

  const tabComponent = useMemo(() => {
    switch (location.pathname) {
      case findPatchPages(PAGE_PATH.MANAGE_TAGS):
        return ManageTags;
      case findPatchPages(PAGE_PATH.SYSTEM_TAGS):
        return SystemTags;
      case findPatchPages(PAGE_PATH.GAME_TAGS):
        return GameTags;
      default:
        return null;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!tabComponent) {
      handleChange(undefined, findPatchPages(PAGE_PATH.MANAGE_TAGS));
    }
  }, [tabComponent]);

  if (!tabComponent) return null;

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ bgcolor: 'background.paper', mb: 3 }}
      >
        <Tab
          label="Manage Tags"
          value={findPatchPages(PAGE_PATH.MANAGE_TAGS)}
        />
        <Tab
          label="System Tags"
          value={findPatchPages(PAGE_PATH.SYSTEM_TAGS)}
        />
        <Tab label="Game Tags" value={findPatchPages(PAGE_PATH.GAME_TAGS)} />
      </Tabs>
      {React.createElement(tabComponent)}
    </>
  );
};

export default Tags;
