import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import 'dayjs/locale/en-gb';
import useAccessView from 'hooks/useAccessView';

import { toggleSidebar, uiSelector } from 'store/slices/ui';
import { setUserInfo, userSelector } from 'store/slices/user';

import { getUserProfile } from 'services/UserService';
import { findPatchPages } from 'utils/pages';

import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import Content from 'components/Content/Content';
import Header from 'components/Header/Header';
import DrawerHeader from 'components/Sidebar/DrawerHeader';
import Sidebar from 'components/Sidebar/Sidebar';
import Alert from 'components/UI/Alert/Alert';

import { PAGE_PATH } from 'components/config/pages';
import { drawerWidth } from 'components/config/sidebar';
import growthbook from 'config/growthbook';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sidebarView = useSelector(uiSelector.getSidebarView);
  const colorMode = useSelector(uiSelector.getColorMode);
  const userInfo = useSelector(userSelector.getInfo);
  const hasAccessView = useAccessView();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode,
        },
      }),
    [colorMode],
  );

  const handleSidebarToggle = useCallback(() => dispatch(toggleSidebar()), []);

  useEffect(() => {
    getUserProfile().then((data) => dispatch(setUserInfo(data)));
    growthbook.init({ streaming: true });
  }, []);

  useEffect(() => {
    if (userInfo?.id) {
      growthbook.setAttributes({
        loggedIn: true,
        id: userInfo.id,
        url: window.location.href,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo && !hasAccessView) {
      navigate(findPatchPages(PAGE_PATH.ACCESS_DENIED));
    }
  }, [hasAccessView, userInfo]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={'en-gb'}
        >
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header handleSidebarToggle={handleSidebarToggle} />
            <Sidebar open={sidebarView} />
            <Box
              component="main"
              sx={(theme) => ({
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                p: 3,
                width: sidebarView
                  ? `calc(100% - ${drawerWidth}px)`
                  : `calc(100% - ${theme.spacing(7)} + 1px)`,
                ...(!sidebarView && {
                  [theme.breakpoints.up('sm')]: {
                    width: `calc(100% - ${theme.spacing(8)} + 1px)`,
                  },
                }),
              })}
            >
              <DrawerHeader />
              <Content />
            </Box>
            <Alert />
          </Box>
        </LocalizationProvider>
      </ThemeProvider>
    </GrowthBookProvider>
  );
};
export default App;
