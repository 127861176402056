import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFeatureIsOn } from '@growthbook/growthbook-react';
import useAccessView from 'hooks/useAccessView';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { findPatchPages } from 'utils/pages';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import DrawerHeader from 'components/Sidebar/DrawerHeader';

import { PAGE_PATH } from 'components/config/pages';
import { drawerWidth, menu } from 'components/config/sidebar';

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
  component: 'nav',
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const propTypes = {
  open: PropTypes.bool.isRequired,
};

const Sidebar = ({ open }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const hasAccessView = useAccessView();

  const [openMenu, setOpenMenu] = useState('');

  const handleClick = useCallback(
    (event, item) => {
      event.preventDefault();
      event.stopPropagation();

      if (item.subNav && open) {
        setOpenMenu((prevState) =>
          prevState !== item.title ? item.title : '',
        );
      }

      if (!hasAccessView) {
        navigate(findPatchPages(PAGE_PATH.ACCESS_DENIED));
        return;
      }

      if (item.path) {
        if (item.external) {
          window.location.href = item.path;
          return;
        }

        navigate(item.path);
      }
    },
    [open, hasAccessView],
  );

  useEffect(() => {
    const activeMenu = menu.find((item) =>
      location.pathname.indexOf(item.path) !== -1
        ? true
        : item.subNav
          ? item.subNav.some(
              (subItem) => location.pathname.indexOf(subItem.path) !== -1,
            )
          : false,
    );

    if (activeMenu) {
      setOpenMenu(activeMenu.title);
    }
  }, []);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader />
      <Divider />
      <List>
        {menu.map((item) => {
          if (item.feature && !item.show(useFeatureIsOn(item.feature))) {
            return null;
          }

          if (item.divider) {
            return (
              <Divider key={_.uniqueId()} variant="middle" component="li" />
            );
          }
          return (
            <ListItem
              key={item.title}
              disablePadding
              sx={{ display: 'block' }}
              onClick={(event) => handleClick(event, item)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                selected={location.pathname === item.path}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {item.icon()}
                </ListItemIcon>
                <ListItemText
                  primary={item.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {item.subNav &&
                  open &&
                  (openMenu === item.title ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
              {item.subNav && open && (
                <Collapse
                  in={openMenu === item.title}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === 'light' ? '#fafafa' : '#121212',
                  }}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.subNav.map((subItem) => {
                      if (
                        subItem.feature &&
                        !subItem.show(useFeatureIsOn(subItem.feature))
                      ) {
                        return null;
                      }

                      if (subItem.divider) {
                        return <Divider key={_.uniqueId()} variant="middle" />;
                      }

                      return (
                        <ListItemButton
                          key={subItem.title}
                          sx={{ pl: 3 }}
                          onClick={(event) => handleClick(event, subItem)}
                          selected={
                            location.pathname.indexOf(subItem.path) !== -1
                          }
                        >
                          <ListItemText primary={subItem.title} />
                        </ListItemButton>
                      );
                    })}
                  </List>
                </Collapse>
              )}
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};

Sidebar.propTypes = propTypes;
export default Sidebar;
