import { useCallback, useLayoutEffect, useState } from 'react';

import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Dialog as MuiDialog,
} from '@mui/material';

import ItemsList from 'components/UI/AssignDialog/ItemsList/ItemsList';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

const initialState = {
  available: [],
  assigned: [],
};

const defaultHandleSort = (a, b) => parseInt(a.label) - parseInt(b.label);

const AssignDialog = ({
  type,
  title,
  data,
  handleClose,
  handleSubmit,
  handleSort = defaultHandleSort,
  loading,
}) => {
  const [items, setItems] = useState(data ?? initialState);
  const [checked, setChecked] = useState(initialState);

  const handleChange = useCallback(
    (type) => (value) => {
      if (Array.isArray(value)) {
        setChecked((prev) => ({
          ...prev,
          [type]: value,
        }));

        return;
      }

      setChecked((prev) => {
        const current = prev[type];
        return {
          ...prev,
          [type]: current.includes(value)
            ? current.filter((item) => item !== value)
            : [...current, value],
        };
      });
    },
    [],
  );

  const handleReplace = useCallback(
    (from, to) => () => {
      setItems((prev) => ({
        ...prev,
        [from]: prev[from].filter(
          ({ value }) => !checked[from].includes(value),
        ),
        [to]: [
          ...prev[to],
          ...prev[from].filter(({ value }) => checked[from].includes(value)),
        ].sort(handleSort),
      }));

      setChecked((prev) => ({ ...prev, [from]: [] }));
    },
    [checked, handleSort],
  );

  useLayoutEffect(() => {
    if (data && !_isEqual(items, data)) {
      setItems(data);
    }
  }, [data]);

  return (
    <MuiDialog maxWidth="lg" open={true} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ pb: 1 }}>
        {loading && <FullPageLoader />}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 51px 1fr',
            gap: 2,
          }}
        >
          <ItemsList
            title={`Available ${type}`}
            items={items.available}
            checked={checked.available}
            onChange={handleChange('available')}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'center',
            }}
          >
            <IconButton
              onClick={handleReplace('assigned', 'available')}
              disabled={!checked.assigned.length}
            >
              <ArrowCircleLeft fontSize="large" />
            </IconButton>
            <IconButton
              onClick={handleReplace('available', 'assigned')}
              disabled={!checked.available.length}
            >
              <ArrowCircleRight fontSize="large" />
            </IconButton>
          </Box>
          <ItemsList
            title={`Assigned ${type}`}
            items={items.assigned}
            checked={checked.assigned}
            onChange={handleChange('assigned')}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, pt: 1 }}>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleSubmit(items.assigned)}
          disabled={!data || _isEqual(items, data)}
        >
          Save
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

const itemsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
);

AssignDialog.propTypes = {
  type: PropTypes.oneOf(['systems', 'games', 'tags', 'users']).isRequired,
  title: PropTypes.string,
  data: PropTypes.exact({
    available: itemsPropType,
    assigned: itemsPropType,
  }),
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSort: PropTypes.func,
  loading: PropTypes.bool,
};

export default AssignDialog;
