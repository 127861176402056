export const config = (env) => {
  switch (env) {
    case 'local':
    case 'sb':
    case 'gt':
    case 'rc':
      return {
        url: 'https://keycloack.evoent.tech',
        realm: 'auth-services',
        clientId: 'bo',
      };
    default:
      return {
        url: 'https://auth.evoent.tech',
        realm: 'auth-services',
        clientId: `bo-${env}`,
      };
  }
};
