import { isRejectedWithValue } from '@reduxjs/toolkit';

import { showAlert } from 'store/slices/alert';

import { doLogout } from 'services/UserService';

export const rtkQueryErrorLogger =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isRejectedWithValue(action)) {
      if ([403, 401].includes(action.payload.status)) {
        doLogout();
        return next(action);
      }

      console.warn('We got a rejected action!', action);
      if (action.payload) {
        let errorMessage = 'Something went wrong';
        if (action.payload.data === 'System not found') {
          errorMessage = `System not found: ${action?.meta?.baseQueryMeta?.request?.url}`;
        } else if (action.payload.data?.errorMessage) {
          errorMessage = action.payload.data.errorMessage;
        } else if (action.payload.data?.error) {
          errorMessage = action.payload.data.error;
        } else if (action.payload.error) {
          errorMessage = action.payload.error;
        }

        dispatch(showAlert({ type: 'error', text: errorMessage }));
      }
    }

    return next(action);
  };
