import { api } from 'store/api/api';

const imageController = api.injectEndpoints({
  endpoints: (build) => ({
    uploadImage: build.mutation({
      query: (body) => ({
        url: '/tournament-service/api/v1/image',
        method: 'POST',
        body,
        formData: true,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useUploadImageMutation } = imageController;
