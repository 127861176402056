export const HelperList = [
  {
    name: 'Use Invoice Groups',
    text: 'Changing this field affects the selection of operators for which an invoice is generated: each operator separately, or an invoice group that includes one or more operators connected by a certain feature',
  },
  {
    name: 'Date range',
    text: 'The period for which the report will be generated',
  },
  {
    name: 'Target currency',
    text: 'Affects the currency in which the invoice will be issued, also affects the generated XLSX report',
  },
  {
    name: 'Ignore negative',
    text: 'Affects whether negative GGRs will be taken into account in the invoice',
  },
];
