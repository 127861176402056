import React from 'react';

import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

const propTypes = {
  subTitle: PropTypes.string.isRequired,
};

const Subtitle = ({ subTitle }) => (
  <Typography variant="subtitle1" gutterBottom sx={{ lineHeight: 1.3 }}>
    {subTitle}
  </Typography>
);

Subtitle.propTypes = propTypes;
export default Subtitle;
