export const columns = [
  {
    name: 'ID',
    value: ({ systemId }) => systemId,
  },
  {
    name: 'Name',
    value: ({ name }) => name,
  },
  {
    name: 'Title',
    value: ({ title }) => title,
  },
  {
    name: 'Api Key',
    value: ({ systemKey }) => systemKey,
  },
  {
    name: 'Labels',
    value: ({ labels }) => labels?.join(', '),
  },
];

export const settingSwitches = [
  {
    name: 'enabled',
    label: 'Is Enabled',
    initialChecked: true,
  },
  {
    name: 'released',
    label: 'Is Released',
  },
  {
    name: 'balanceIncreaseEnabled',
    label: 'Balance Increase Enabled',
  },
  {
    name: 'sendSystemId',
    label: 'Send System ID',
  },
  {
    name: 'showHistory',
    label: 'Show History',
  },
  {
    name: 'disableBbModule',
    label: 'Disable Bonus Buy',
  },
  {
    name: 'sendCallbackTime',
    label: 'Send Callback Time',
  },
  {
    name: 'sendZeroWins',
    label: 'Send Zero Wins',
    initialChecked: true,
  },
  {
    name: 'isSocial',
    label: 'Is Social (AntiWager limits, ENS by default)',
  },
];
