import dayjs from 'dayjs';

import { DateTimeFormat } from 'config/dates';

export const columns = [
  {
    name: 'ID',
    value: ({ id }) => id,
    sortLabel: 'id',
    sortDirection: 'desc',
  },
  {
    name: 'System',
    value: ({ operatorId }) => operatorId,
    sortLabel: 'operatorId',
  },
  {
    name: 'Agregator user id',
    value: ({ playerId }) => playerId,
  },
  {
    name: 'Created',
    value: ({ createdAt }) => dayjs(new Date(createdAt)).format(DateTimeFormat),
  },
];
