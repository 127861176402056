import React from 'react';
import {
  JsonView,
  allExpanded,
  darkStyles,
  defaultStyles,
} from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

import PropTypes from 'prop-types';

import { useTheme } from '@mui/material';

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
};

const PrettyPrintJson = ({ data }) => {
  const theme = useTheme();

  let stringifyData = data;
  if (typeof data === 'string') {
    try {
      stringifyData = JSON.parse(
        data
          .replaceAll(/\\u0022/g, '')
          .replaceAll(/"{\\"/g, '{"')
          .replaceAll(/}"/g, '}')
          .replaceAll(/\\"/g, '"'),
      );
    } catch {
      stringifyData = null;
    }
  }

  return (
    <JsonView
      data={stringifyData}
      shouldExpandNode={allExpanded}
      style={theme.palette.mode === 'light' ? defaultStyles : darkStyles}
    />
  );
};

PrettyPrintJson.propTypes = propTypes;
export default PrettyPrintJson;
