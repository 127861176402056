import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';
import { useToggle } from 'hooks/useToggle';
import _ from 'lodash';

import { useUploadImageMutation } from 'store/api/api/tournamentService/imageController';
import {
  useGetGameJurisdictionsQuery,
  useGetGameLanguagesQuery,
  useGetGameMarkersQuery,
  useGetGamePromoToolsQuery,
  useGetGameProvidersQuery,
  useGetGameTypesQuery,
  useGetGameVolatilitiesQuery,
} from 'store/api/configService/enumsController';
import {
  useGetMasterGameItemQuery,
  useUpdateMasterGameItemMutation,
} from 'store/api/configService/internalGameController';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { getArrayFromString } from 'utils/dataFormat';
import { findPatchPages } from 'utils/pages';
import { checkValue } from 'utils/validate';

import {
  AddCircle,
  Delete,
  ExpandMore,
  InfoOutlined,
  KeyboardBackspace,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import HelperModal from 'components/UI/HelperModal/HelperModal';
import ImageUpload from 'components/UI/ImageUpload/ImageUpload';
import Columns from 'components/UI/Table/Columns';
import { HelperList } from 'components/pages/Games/MasterGameList/helperList';
import NotFound from 'components/pages/NotFound/NotFound';

import { FEATURES, TAGS, columns } from 'components/config/masterGamesList';
import { PAGE_PATH } from 'components/config/pages';
import { DateFormat } from 'config/dates';

const defaultGameFeature = {
  name: '',
  description: '',
  media: '',
};

const initialValue = (editItem) =>
  editItem
    ? {
        ...editItem,
        regionId: [
          {
            id: columns.find(({ name }) => name === 'ID EU')?.value(editItem),
            region: 'EU',
          },
          {
            id: columns.find(({ name }) => name === 'ID ASIA')?.value(editItem),
            region: 'ASIA',
          },
        ],
        rtp: editItem.rtp.join(', '),
        rtpOptions: editItem.rtpOptions.join(', '),
        hitFrequency: editItem.hitFrequency.join(', '),
        miscellaneous: {
          gameFeatures: [
            ...editItem.miscellaneous.gameFeatures.map((item) => ({
              ...item,
              id: _.uniqueId(),
            })),
            { ...defaultGameFeature, id: _.uniqueId() },
          ],
          marker: editItem.miscellaneous.marker ?? '',
          jurisdictions: editItem.miscellaneous.jurisdictions.filter(
            (item) => item.length,
          ),
          features: editItem.miscellaneous.features.filter(
            (item) => item.length,
          ),
          languages: editItem.miscellaneous.languages.filter(
            (item) => item.length,
          ),
          promoTools: editItem.miscellaneous.promoTools.filter(
            (item) => item.length,
          ),
          tags: editItem.miscellaneous.tags.filter((item) => item.length),
        },
      }
    : {
        absoluteName: '',
        demo: '',
        description: '',
        desktopWeight: '',
        gameCode: '',
        gameFormat: '',
        hitFrequency: '',
        images: {
          icon: '',
          background: '',
          sliderLarge: '',
          sliderSmall: '',
          logo: '',
          media: '',
        },
        regionId: [
          {
            id: '',
            region: 'EU',
          },
          {
            id: '',
            region: 'ASIA',
          },
        ],
        monetaryProperties: {
          minBet: '',
          maxBet: '',
          maxWin: '',
          maxMultiplier: '',
        },
        miscellaneous: {
          marker: '',
          jurisdictions: [],
          languages: [],
          promoTools: [],
          tags: [],
          features: [],
          gameFeatures: [{ ...defaultGameFeature, id: _.uniqueId() }],
        },
        mobileWeight: '',
        name: '',
        paylines: '',
        provider: '',
        releaseDate: '',
        rtp: '',
        rtpOptions: '',
        type: '',
        volatility: '',
        recommended: false,
      };

const MasterGameListItem = () => {
  const dispatch = useDispatch();

  const { provider, gameCode } = useParams();
  const navigate = useNavigate();

  const editItemStore = useSelector(tableSelector.getEditItem);

  const [uploadImage] = useUploadImageMutation();
  const [updateMasterGameItem] = useUpdateMasterGameItemMutation();

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetMasterGameItemQuery(
    { id: gameCode },
    {
      skip: Boolean(['copy', 'add'].includes(gameCode) || editItemStore),
    },
  );
  const { data: dataGameTypes, isLoading: isLoadingGameTypes } =
    useGetGameTypesQuery();
  const { data: dataMarkers, isLoading: isLoadingMarkers } =
    useGetGameMarkersQuery();
  const { data: dataJurisdictions, isLoading: isLoadingJurisdictions } =
    useGetGameJurisdictionsQuery();
  const { data: dataLanguages, isLoading: isLoadingLanguages } =
    useGetGameLanguagesQuery();
  const { data: dataPromoTools, isLoading: isLoadingPromoTools } =
    useGetGamePromoToolsQuery();
  const { data: dataProviders, isLoading: isLoadingProviders } =
    useGetGameProvidersQuery();
  const { data: dataVolatilities, isLoading: isLoadingVolatilities } =
    useGetGameVolatilitiesQuery();

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const {
    opened: openHelperModal,
    handleOpen: handleOpenHelperModal,
    handleClose: handleCloseHelperModal,
  } = useToggle();

  const handleBack = useCallback(() => {
    navigate(
      findPatchPages(PAGE_PATH.MASTER_GAME_LIST).replace(
        ':provider?',
        provider,
      ),
    );
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
        uploadImage,
      }),
    [],
  );

  const updateData = useCallback(async () => {
    const prepareItemsValue = _.cloneDeep(itemsValue);
    Object.keys(prepareItemsValue).forEach((key) => {
      if (prepareItemsValue[key] === '') {
        prepareItemsValue[key] = null;
      } else if (typeof prepareItemsValue[key] === 'object') {
        Object.keys(prepareItemsValue[key]).forEach((subKey) => {
          if (prepareItemsValue[key][subKey] === '') {
            prepareItemsValue[key][subKey] = null;
          }
        });
      }
    });
    const result = await updateMasterGameItem({
      ...(editItem && editItem.id && { id: editItem.id }),
      ...prepareItemsValue,
      rtp: getArrayFromString(prepareItemsValue.rtp),
      rtpOptions: getArrayFromString(prepareItemsValue.rtpOptions),
      hitFrequency: getArrayFromString(prepareItemsValue.hitFrequency),
      miscellaneous: {
        ...prepareItemsValue.miscellaneous,
        gameFeatures: prepareItemsValue.miscellaneous.gameFeatures
          .slice(0, -1)
          .map((item) => {
            delete item.id;
            return item;
          }),
      },
    });

    editItemClass.checkUpdateData(result);
  }, [itemsValue, editItem]);

  const handleChange = useCallback(
    ({ name, value }) => {
      editItemClass.handleChange({ name, value, errors });
    },
    [errors],
  );

  const updateImg = useCallback(async () => {
    const gameFeaturesImages = new Array(
      itemsValue.miscellaneous.gameFeatures.length - 1,
    )
      .fill('')
      .map((_, index) => `miscellaneous.gameFeatures[${index}].media`);
    return await editItemClass.updateImg(
      [
        'images.logo',
        'images.icon',
        'images.background',
        'images.sliderLarge',
        'images.sliderSmall',
        'images.media',
        ...gameFeaturesImages,
      ],
      itemsValue,
    );
  }, [itemsValue]);

  const handleSubmit = useCallback(async () => {
    await editItemClass.handleSubmit({
      itemsValue,
      updateImg,
      imageFields: ['images.logo', 'images.icon', 'images.background'],
      textFields: [
        'name',
        'gameCode',
        'regionId[0].id',
        'regionId[1].id',
        'provider',
        'type',
        'description',
      ],
    });
  }, [itemsValue]);

  const handleAddFeature = useCallback(() => {
    const addedFeature = itemsValue.miscellaneous.gameFeatures.slice(-1)[0];
    const errorsAdd = [];
    Object.keys(addedFeature).map((name) => {
      if (!checkValue({ value: addedFeature[name] })) {
        errorsAdd.push(
          `miscellaneous.gameFeatures[${itemsValue.miscellaneous.gameFeatures.length - 1}].${name}`,
        );
      }
    });

    if (errorsAdd.length) {
      setErrors(errorsAdd);
    } else {
      setItemsValue((prevState) => {
        return {
          ...prevState,
          miscellaneous: {
            ...prevState.miscellaneous,
            gameFeatures: [
              ...prevState.miscellaneous.gameFeatures,
              { ...defaultGameFeature, id: _.uniqueId() },
            ],
          },
        };
      });
    }
  }, [itemsValue]);
  const handleDeleteFeature = useCallback(
    (row) =>
      setItemsValue((prevState) => {
        const modifyEntries = _.cloneDeep(prevState.miscellaneous.gameFeatures);
        modifyEntries.splice(row, 1);

        return {
          ...prevState,
          miscellaneous: {
            ...prevState.miscellaneous,
            gameFeatures: modifyEntries,
          },
        };
      }),
    [],
  );

  useEffect(() => {
    if (submit) {
      updateData();
    }
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (gameCode === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [gameCode, editItemStore]);

  if (isError) {
    return <NotFound />;
  }

  return (
    <>
      {(process || (gameCode !== 'add' && !itemsValue)) && <FullPageLoader />}

      {itemsValue && (
        <>
          <Box
            component="div"
            sx={{
              mb: 3,
              display: 'flex',
              columnGap: 2,
              justifyContent: 'space-between',
            }}
          >
            <Box component="div" sx={{ display: 'flex', columnGap: 2 }}>
              <IconButton onClick={handleBack}>
                <KeyboardBackspace />
              </IconButton>
              <Box
                component="h3"
                sx={{
                  m: 0,
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 500,
                }}
              >
                {editItem
                  ? editItem.id
                    ? `Update "${editItem.name}"`
                    : `Copy by "${editItem.name}"`
                  : 'Create Game'}
              </Box>
            </Box>
            <IconButton onClick={handleOpenHelperModal}>
              <InfoOutlined />
            </IconButton>
          </Box>

          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'scroll',
            }}
          >
            {/*Main Info*/}
            <Columns>
              <Text
                required
                label="Game Name"
                name="name"
                fullWidth
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('name')}
                value={itemsValue.name}
              />

              <Text
                required
                label="Game Code"
                name="gameCode"
                fullWidth
                onChange={(event) => {
                  if (!editItem?.gameCode) {
                    handleChange({
                      name: 'gameCode',
                      value: event.target.value,
                    });
                  }
                }}
                error={!!errors.includes('gameCode')}
                value={itemsValue.gameCode}
                disabled={!!editItem?.gameCode}
              />
            </Columns>
            <Columns>
              <Text
                required
                label="Game ID EU"
                name="regionId[0].id"
                fullWidth
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('regionId[0].id')}
                value={itemsValue.regionId[0].id}
              />

              <Text
                required
                label="Game ID ASIA"
                name="regionId[1].id"
                fullWidth
                onChange={({ target }) => handleChange(target)}
                error={!!errors.includes('regionId[1].id')}
                value={itemsValue.regionId[1].id}
              />
            </Columns>
            <Columns count={4}>
              <Select
                label="Provider"
                name="provider"
                error={!!errors.includes('provider')}
                value={itemsValue.provider}
                handleChange={handleChange}
                options={dataProviders || []}
                loading={isLoadingProviders}
              />
              <Select
                label="Category"
                name="type"
                error={!!errors.includes('type')}
                value={itemsValue.type}
                handleChange={handleChange}
                options={dataGameTypes || []}
                loading={isLoadingGameTypes}
              />
              <Select
                label="Marker"
                name="miscellaneous.marker"
                error={!!errors.includes('miscellaneous.marker')}
                value={itemsValue.miscellaneous.marker}
                handleChange={handleChange}
                options={dataMarkers || []}
                loading={isLoadingMarkers}
                required={false}
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={!!itemsValue.recommended}
                    onChange={(event) =>
                      handleChange({
                        name: 'recommended',
                        value: event.target.checked,
                      })
                    }
                  />
                }
                label="Recommended"
                name="recommended"
                labelPlacement="end"
              />
            </Columns>

            {/*Description*/}
            <Box sx={{ p: '1px' }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="description-content"
                  id="description-header"
                >
                  Description
                </AccordionSummary>
                <AccordionDetails>
                  <Text
                    required
                    label="Description"
                    name="description"
                    fullWidth
                    onChange={({ target }) => handleChange(target)}
                    error={!!errors.includes('description')}
                    value={itemsValue.description}
                    multiline
                    maxRows={10}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>

            {/*Technical information*/}
            <Box sx={{ p: '1px' }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="technicalInfo-content"
                  id="technicalInfo-header"
                >
                  Technical information
                </AccordionSummary>
                <AccordionDetails>
                  <Columns count={4}>
                    <DatePicker
                      sx={{ width: '100%' }}
                      label="Release date"
                      format={DateFormat}
                      timezone="UTC"
                      onChange={(newValue) => {
                        if (newValue && newValue.format() !== 'Invalid Date') {
                          handleChange({
                            name: 'releaseDate',
                            value: newValue.format(),
                          });
                        }
                      }}
                      slotProps={{
                        textField: {
                          name: 'Marketing Release date',
                          error: !!errors.includes('releaseDate'),
                        },
                      }}
                      {...(editItem && {
                        defaultValue: dayjs.utc(itemsValue.releaseDate),
                      })}
                    />

                    <Text
                      label="RTP"
                      name="rtp"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('rtp')}
                      value={itemsValue.rtp}
                    />

                    <Text
                      label="RTP Options"
                      name="rtpOptions"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('rtpOptions')}
                      value={itemsValue.rtpOptions}
                    />

                    <Select
                      label="Volatility"
                      name="volatility"
                      error={!!errors.includes('volatility')}
                      value={itemsValue.volatility}
                      handleChange={handleChange}
                      options={dataVolatilities || []}
                      loading={isLoadingVolatilities}
                      required={false}
                    />

                    <Text
                      label="Min Bet in Eur"
                      name="monetaryProperties.minBet"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('monetaryProperties.minBet')}
                      value={itemsValue.monetaryProperties.minBet}
                    />

                    <Text
                      label="Max Bet in Eur"
                      name="monetaryProperties.maxBet"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('monetaryProperties.maxBet')}
                      value={itemsValue.monetaryProperties.maxBet}
                    />

                    <Text
                      label="Max Exposure"
                      name="monetaryProperties.maxMultiplier"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={
                        !!errors.includes('monetaryProperties.maxMultiplier')
                      }
                      value={itemsValue.monetaryProperties.maxMultiplier}
                    />

                    <Text
                      label="Max Win in EUR"
                      name="monetaryProperties.maxWin"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('monetaryProperties.maxWin')}
                      value={itemsValue.monetaryProperties.maxWin}
                    />

                    <Text
                      label="Hit Frequency"
                      name="hitFrequency"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('hitFrequency')}
                      value={itemsValue.hitFrequency}
                    />

                    <Select
                      label="List of countries with certificates"
                      name="miscellaneous.jurisdictions"
                      error={!!errors.includes('miscellaneous.jurisdictions')}
                      value={itemsValue.miscellaneous.jurisdictions}
                      handleChange={handleChange}
                      options={dataJurisdictions || []}
                      loading={isLoadingJurisdictions}
                      required={false}
                      multiple
                    />

                    <Text
                      label="Paylines"
                      name="paylines"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('paylines')}
                      value={itemsValue.paylines}
                    />

                    <Text
                      label="Game Model"
                      name="gameFormat"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('gameFormat')}
                      value={itemsValue.gameFormat}
                    />

                    <Text
                      label="Absolute Name"
                      name="absoluteName"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('absoluteName')}
                      value={itemsValue.absoluteName}
                    />

                    <Text
                      label="Desktop Weight"
                      name="desktopWeight"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('desktopWeight')}
                      value={itemsValue.desktopWeight}
                    />

                    <Text
                      label="Mobile Weight"
                      name="mobileWeight"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('mobileWeight')}
                      value={itemsValue.mobileWeight}
                    />

                    <Select
                      label="Promo Tools"
                      name="miscellaneous.promoTools"
                      error={!!errors.includes('miscellaneous.promoTools')}
                      value={itemsValue.miscellaneous.promoTools}
                      handleChange={handleChange}
                      options={dataPromoTools || []}
                      loading={isLoadingPromoTools}
                      required={false}
                      multiple
                    />
                  </Columns>
                  <Columns sx={{ mt: 2 }}>
                    <Autocomplete
                      label="Tags"
                      name="miscellaneous.tags"
                      error={!!errors.includes('miscellaneous.tags')}
                      value={itemsValue.miscellaneous.tags}
                      handleChange={handleChange}
                      options={TAGS}
                      required={false}
                      multiple
                    />

                    <Autocomplete
                      label="Features"
                      name="miscellaneous.features"
                      error={!!errors.includes('miscellaneous.features')}
                      value={itemsValue.miscellaneous.features}
                      handleChange={handleChange}
                      options={FEATURES}
                      required={false}
                      multiple
                    />
                  </Columns>
                  <Autocomplete
                    label="Supported Languages"
                    name="miscellaneous.languages"
                    error={!!errors.includes('miscellaneous.languages')}
                    value={itemsValue.miscellaneous.languages}
                    handleChange={handleChange}
                    options={dataLanguages || []}
                    loading={isLoadingLanguages}
                    required={false}
                    sx={{ mt: 2 }}
                    multiple
                  />
                </AccordionDetails>
              </Accordion>
            </Box>

            {/*Settings*/}
            {/*<Box sx={{ p: '1px' }}>*/}
            {/*  <Accordion defaultExpanded>*/}
            {/*    <AccordionSummary*/}
            {/*      expandIcon={<ExpandMore />}*/}
            {/*      aria-controls="settings-content"*/}
            {/*      id="settings-header"*/}
            {/*    >*/}
            {/*      Settings*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*      <Columns count={4}>*/}
            {/*        {SETTINGS_LIST.map(({ name, label }) => (*/}
            {/*          <FormControlLabel*/}
            {/*            key={label}*/}
            {/*            control={*/}
            {/*              <Switch*/}
            {/*                color="primary"*/}
            {/*                checked={!!itemsValue[name]}*/}
            {/*                onChange={(event) =>*/}
            {/*                  handleChange({*/}
            {/*                    name,*/}
            {/*                    value: event.target.checked,*/}
            {/*                  })*/}
            {/*                }*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label={label}*/}
            {/*            name={name}*/}
            {/*            labelPlacement="end"*/}
            {/*          />*/}
            {/*        ))}*/}
            {/*      </Columns>*/}
            {/*    </AccordionDetails>*/}
            {/*  </Accordion>*/}
            {/*</Box>*/}

            {/*Promo Tools*/}
            {/*<Box sx={{ p: '1px' }}>*/}
            {/*  <Accordion defaultExpanded>*/}
            {/*    <AccordionSummary*/}
            {/*      expandIcon={<ExpandMore />}*/}
            {/*      aria-controls="settings-content"*/}
            {/*      id="settings-header"*/}
            {/*    >*/}
            {/*      Promo Tools*/}
            {/*    </AccordionSummary>*/}
            {/*    <AccordionDetails>*/}
            {/*      <Columns count={4}>*/}
            {/*        {PROMO_TOOLS_LIST.map(({ name, label }) => (*/}
            {/*          <FormControlLabel*/}
            {/*            key={label}*/}
            {/*            control={*/}
            {/*              <Switch*/}
            {/*                color="primary"*/}
            {/*                checked={!!itemsValue[name]}*/}
            {/*                onChange={(event) =>*/}
            {/*                  handleChange({*/}
            {/*                    name,*/}
            {/*                    value: event.target.checked,*/}
            {/*                  })*/}
            {/*                }*/}
            {/*              />*/}
            {/*            }*/}
            {/*            label={label}*/}
            {/*            name={name}*/}
            {/*            labelPlacement="end"*/}
            {/*          />*/}
            {/*        ))}*/}
            {/*      </Columns>*/}
            {/*    </AccordionDetails>*/}
            {/*  </Accordion>*/}
            {/*</Box>*/}

            {/*Game Features*/}
            <Box sx={{ p: '1px' }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="gameFeatures-content"
                  id="gameFeatures-header"
                >
                  Game Features
                </AccordionSummary>
                <AccordionDetails>
                  {itemsValue.miscellaneous.gameFeatures.map((item, index) => {
                    const isLastItem =
                      index ===
                      itemsValue.miscellaneous.gameFeatures.length - 1;
                    return (
                      <React.Fragment key={item.id}>
                        {(isLastItem || index > 0) && (
                          <Divider sx={{ m: '16px 0' }} />
                        )}
                        <Box
                          component="div"
                          sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 50px',
                            gap: 2,
                          }}
                        >
                          <Box
                            component="div"
                            sx={{
                              display: 'grid',
                              gridTemplateColumns: '1fr 1fr',
                              columnGap: 2,
                            }}
                          >
                            <Box
                              conponent="div"
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: 2,
                              }}
                            >
                              <Text
                                label="Name"
                                name={`miscellaneous.gameFeatures[${index}].name`}
                                fullWidth
                                onChange={({ target }) => handleChange(target)}
                                error={
                                  !!errors.includes(
                                    `miscellaneous.gameFeatures[${index}].name`,
                                  )
                                }
                                value={
                                  itemsValue.miscellaneous.gameFeatures[index]
                                    .name
                                }
                              />

                              <ImageUpload
                                label="Screenshot"
                                value={
                                  itemsValue.miscellaneous.gameFeatures[index]
                                    .media
                                }
                                handleChange={(value) =>
                                  handleChange({
                                    name: `miscellaneous.gameFeatures[${index}].media`,
                                    value,
                                  })
                                }
                                hasError={
                                  !!errors.includes(
                                    `miscellaneous.gameFeatures[${index}].media`,
                                  )
                                }
                                required={false}
                              />
                            </Box>

                            <Text
                              label="Description"
                              name={`miscellaneous.gameFeatures[${index}].description`}
                              fullWidth
                              onChange={({ target }) => handleChange(target)}
                              error={
                                !!errors.includes(
                                  `miscellaneous.gameFeatures[${index}].description`,
                                )
                              }
                              value={
                                itemsValue.miscellaneous.gameFeatures[index]
                                  .description
                              }
                              multiline
                              minRows={4}
                              maxRows={4}
                            />
                          </Box>
                          <Box
                            component="div"
                            sx={{ display: 'flex', alignItems: 'center' }}
                          >
                            {isLastItem ? (
                              <IconButton onClick={handleAddFeature}>
                                <AddCircle />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => handleDeleteFeature(index)}
                              >
                                <Delete />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </Box>

            {/*Media / Demo Link*/}
            <Box sx={{ p: '1px' }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="mediaLink-content"
                  id="mediaLink-header"
                >
                  Media / Demo Link
                </AccordionSummary>
                <AccordionDetails>
                  <Columns>
                    <Text
                      label="Media"
                      name="media"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('media')}
                      value={itemsValue.media}
                    />

                    <Text
                      label="Demo"
                      name="demo"
                      fullWidth
                      onChange={({ target }) => handleChange(target)}
                      error={!!errors.includes('demo')}
                      value={itemsValue.demo}
                    />
                  </Columns>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/*Images*/}
            <Box sx={{ p: '1px' }}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="images-content"
                  id="images-header"
                >
                  Images
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    component="div"
                    sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}
                  >
                    <ImageUpload
                      label="Slider Large"
                      value={itemsValue.sliderLarge}
                      handleChange={(value) =>
                        handleChange({ name: 'sliderLarge', value })
                      }
                      hasError={!!errors.includes('sliderLarge')}
                      required={false}
                    />

                    <ImageUpload
                      label="Slider Small"
                      value={itemsValue.sliderSmall}
                      handleChange={(value) =>
                        handleChange({ name: 'sliderSmall', value })
                      }
                      hasError={!!errors.includes('sliderSmall')}
                      required={false}
                    />

                    <ImageUpload
                      label="Media"
                      value={itemsValue.images.media}
                      handleChange={(value) =>
                        handleChange({ name: 'images.media', value })
                      }
                      hasError={!!errors.includes('images.media')}
                      required={false}
                    />

                    <ImageUpload
                      label="Logo"
                      value={itemsValue.images.logo}
                      handleChange={(value) =>
                        handleChange({ name: 'images.logo', value })
                      }
                      hasError={!!errors.includes('images.logo')}
                    />

                    <ImageUpload
                      label="Icon"
                      value={itemsValue.images.icon}
                      handleChange={(value) =>
                        handleChange({ name: 'images.icon', value })
                      }
                      hasError={!!errors.includes('images.icon')}
                    />

                    <ImageUpload
                      label="Background"
                      value={itemsValue.images.background}
                      handleChange={(value) =>
                        handleChange({ name: 'images.background', value })
                      }
                      hasError={!!errors.includes('images.background')}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>

          <Box
            component="div"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button onClick={handleSubmit} variant="contained">
              {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
            </Button>
          </Box>
          {openHelperModal && (
            <HelperModal
              handleClose={handleCloseHelperModal}
              helperList={HelperList}
            />
          )}
        </>
      )}
    </>
  );
};

export default MasterGameListItem;
