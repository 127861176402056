import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import { useSearch } from 'hooks/useSearch';

import {
  useDeleteWheelOfFortuneMutation,
  useGetWheelOfFortuneReportQuery,
  useGetWheelsOfFortuneQuery,
} from 'store/api/api/tournamentService/wheelOfFortuneController';
import { useGetCurrencyRatesQuery } from 'store/api/cr/currencyRates';
import { showAlert } from 'store/slices/alert';

import { findPatchPages } from 'utils/pages';

import { Assessment, Delete, Difference, Edit } from '@mui/icons-material';
import { Stack, TextField, Typography } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import Confirm from 'components/UI/Confirm/Confirm';
import Autocomplete from 'components/UI/Form/Autocomplete';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

import { PAGE_PATH } from 'components/config/pages';
import { columns } from 'components/config/wheelOfFortune';

const Campaigns = () => {
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState(null);
  const [reportCurrency, setReportCurrency] = useState(null);
  const [reportItem, setReportItem] = useState(null);
  const [reportConfirm, setReportConfirm] = useState(false);
  const [deleteWheelOfFortune] = useDeleteWheelOfFortuneMutation();
  const { data: dataCurrency, isLoading: currencyLoading } =
    useGetCurrencyRatesQuery();

  const { isFetching } = useGetWheelOfFortuneReportQuery(
    { id: reportItem?.id, name: reportItem?.name, currency: reportCurrency },
    { skip: !reportItem || !reportCurrency },
  );

  const { searchParams, handleSearch } = useSearch();

  const reportStartDownload = useCallback((currency) => {
    dispatch(
      showAlert({
        type: 'info',
        text: 'Your request has been accepted. The document will be download in a few minutes.',
      }),
    );
    setReportCurrency(currency);
  }, []);

  const handleCurrencyConfirm = useCallback(
    (confirm) => {
      setReportConfirm(false);
      if (confirm) {
        reportStartDownload(currency);
      }
    },
    [currency],
  );

  const handleReport = useCallback((item) => {
    const {
      recurringRule: { activeStatusFinishDateTime },
      monetaryConfig: { baseCurrency },
    } = item;

    setReportItem(null);
    setReportCurrency(null);

    if (dayjs.utc(activeStatusFinishDateTime).diff(dayjs.utc()) > 0) {
      dispatch(
        showAlert({
          type: 'error',
          text: 'This campaign is active. You will be able to download the results when the campaign is complete.',
        }),
      );
    } else {
      setReportItem(item);

      if (baseCurrency === 'EUR') {
        reportStartDownload(baseCurrency);
      } else {
        setCurrency(baseCurrency);
        setReportConfirm(true);
      }
    }
  }, []);

  return (
    <>
      {isFetching && <FullPageLoader />}
      <TableLayout
        actions={[
          { label: 'Edit', icon: <Edit /> },
          { label: 'Delete', icon: <Delete /> },
          { label: 'Copy', icon: <Difference /> },
          { label: 'Report', icon: <Assessment />, handleClick: handleReport },
        ]}
        columns={columns}
        getDataQuery={useGetWheelsOfFortuneQuery}
        queryConditional={searchParams}
        title="Wheel of Fortune"
        deleteItemQuery={deleteWheelOfFortune}
        itemComponentPath={findPatchPages(
          PAGE_PATH.WHEEL_OF_FORTUNE_CAMPAIGNS_ITEM,
        )}
      >
        <TextField
          name="name"
          label="Search by Name"
          onChange={handleSearch}
          sx={{ alignSelf: 'flex-start', mb: 2 }}
        />
      </TableLayout>
      {reportConfirm && (
        <Confirm
          handleClose={handleCurrencyConfirm}
          content={
            <Stack spacing={3}>
              <Typography>Select the currency of the report</Typography>
              <Autocomplete
                label="Currency"
                name="baseCurrency"
                value={currency}
                handleChange={({ value }) => setCurrency(value)}
                options={dataCurrency}
                loading={currencyLoading}
              />
            </Stack>
          }
          disabled={!currency}
        />
      )}
    </>
  );
};

export default Campaigns;
