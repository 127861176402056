import { useCallback, useState } from 'react';

import { useGetIntegrationTypesQuery } from 'store/api/hhs/integrationTypes';
import {
  useDeleteExternalOperatorConfigMutation,
  useGetExternalOperatorConfigsQuery,
} from 'store/api/hhsKeyCloack/externalOperatorConfigs';

import { findPatchPages } from 'utils/pages';

import { Delete } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import Select from 'components/UI/Form/Select';

import { columns } from 'components/config/externalOperatorConfigs';
import { PAGE_PATH } from 'components/config/pages';

const ExternalOperatorConfigs = () => {
  const [filter, setFilter] = useState({});

  const { data: integrationTypes, isLoading: integrationTypesLoading } =
    useGetIntegrationTypesQuery();

  const [deleteExternalOperatorConfig] =
    useDeleteExternalOperatorConfigMutation();

  const handleChangeFilter = useCallback(
    ({ name, value }) => setFilter((prev) => ({ ...prev, [name]: value })),
    [],
  );

  const handleClearFilter = useCallback(() => setFilter({}), []);

  return (
    <TableLayout
      title="External Operator configs"
      columns={columns}
      getDataQuery={useGetExternalOperatorConfigsQuery}
      queryConditional={filter}
      deleteItemQuery={deleteExternalOperatorConfig}
      itemComponentPath={findPatchPages(
        PAGE_PATH.EXTERNAL_OPERATOR_CONFIGS_ITEM,
      )}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: `250px 56px`,
          gap: 2,
          mb: 2,
        }}
      >
        <Select
          name="integrationType"
          value={filter.integrationType ?? ''}
          label="Filter by Integration Type"
          handleChange={handleChangeFilter}
          options={integrationTypes}
          required={false}
          loading={integrationTypesLoading}
        />
        {!!Object.keys(filter).length && (
          <IconButton aria-label="delete" onClick={handleClearFilter}>
            <Delete />
          </IconButton>
        )}
      </Box>
    </TableLayout>
  );
};

export default ExternalOperatorConfigs;
