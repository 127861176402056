export const HelperList = [
  {
    name: 'Campaign name',
    text: 'The name, which will be displayed in the list of other campaigns in the BO.',
  },
  {
    name: 'Start date',
    text: 'Date and time of the campaign start.',
  },
  {
    name: 'Campaign duration',
    text: 'The duration of the campaign, in days. Determines events quantity in the campaign.',
  },
  {
    name: 'Base currency',
    text: 'The main currency in which the campaign will be conducted.',
  },
  {
    name: 'Operator codes',
    text: 'The list of operator codes for which this campaign will be available.',
  },
  {
    name: 'Event template',
    text: 'An event template from the list created earlier.',
  },
  {
    name: 'Limit to base currency',
    text: 'Only users, that playing with the same currency, as campaigns base currency, can participate in a given campaign. Rounds in the other currencies will be ignored.',
  },
];
