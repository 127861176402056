import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetGameProvidersQuery } from 'store/api/configService/enumsController';
import {
  useDeleteMasterGameItemMutation,
  useDownloadCertificateGameQuery,
  useDownloadMasterGameListQuery,
  useGetGamesListQuery,
} from 'store/api/configService/internalGameController';
import { showAlert } from 'store/slices/alert';

import { findPatchPages } from 'utils/pages';

import { Assessment, Delete, Download, Edit } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import ClearBtn from 'components/UI/Filter/ClearBtn';
import FilterBox from 'components/UI/Filter/FilterBox';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

import { columns } from 'components/config/masterGamesList';
import { PAGE_PATH } from 'components/config/pages';

const MasterGameList = () => {
  const dispatch = useDispatch();

  const { provider } = useParams();
  const navigate = useNavigate();

  const [filter, setFilter] = useState({});
  const [downloadList, setDownloadList] = useState(false);
  const [certificateItem, setCertificateItem] = useState(null);

  const [deleteItem] = useDeleteMasterGameItemMutation();

  const { data: dataProviders, isLoading: isLoadingProviders } =
    useGetGameProvidersQuery();
  const { isFetching: isFetchingReport } = useDownloadMasterGameListQuery(
    {},
    { skip: !downloadList },
  );
  const { isFetching: isFetchingCertificate } = useDownloadCertificateGameQuery(
    certificateItem,
    {
      skip: !certificateItem,
    },
  );

  const handleChangeTab = useCallback((_, newValue) => {
    navigate(
      findPatchPages(PAGE_PATH.MASTER_GAME_LIST).replace(':provider', newValue),
    );
  }, []);

  const handleChangeFilter = useCallback(
    ({ name, value }) =>
      setFilter((prevState) => ({ ...prevState, [name]: value })),
    [],
  );
  const handleClearFilter = useCallback(() => setFilter({}), []);
  const handleDownload = useCallback(() => {
    dispatch(
      showAlert({
        type: 'info',
        text: 'Your request has been accepted. The Master Game List will be download in a few minutes.',
      }),
    );
    setDownloadList(true);
    setTimeout(() => setDownloadList(false), 1000);
  }, []);
  const handleCertificate = useCallback((item) => {
    setCertificateItem(item);
    certificateStartDownload();
  }, []);
  const certificateStartDownload = useCallback(() => {
    dispatch(
      showAlert({
        type: 'info',
        text: 'Your request has been accepted. The document will be download in a few minutes.',
      }),
    );
  }, []);

  useEffect(() => {
    if (!provider && dataProviders) {
      handleChangeTab(undefined, dataProviders[0].value.toLocaleLowerCase());
    }
  }, [provider, dataProviders]);

  return (
    <>
      {(isFetchingReport || isFetchingCertificate || isLoadingProviders) && (
        <FullPageLoader />
      )}
      {dataProviders && provider && (
        <>
          <Tabs
            value={provider}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ bgcolor: 'background.paper', mb: 3 }}
          >
            {dataProviders.map(({ label, value }) => (
              <Tab
                key={value}
                label={label}
                value={value.toLocaleLowerCase()}
              />
            ))}
          </Tabs>
          <TableLayout
            actions={[
              { label: 'Edit', icon: <Edit /> },
              { label: 'Delete', icon: <Delete /> },
              {
                label: 'Certificate',
                icon: <Assessment />,
                handleClick: handleCertificate,
              },
            ]}
            columns={columns}
            getDataQuery={useGetGamesListQuery}
            queryConditional={{
              ...filter,
              provider,
              sort: 'id,DESC',
            }}
            title="Master Game List"
            deleteItemQuery={deleteItem}
            itemComponentPath={(editItem) => {
              const itemId = editItem
                ? editItem?.id
                  ? editItem.id
                  : 'copy'
                : 'add';
              return findPatchPages(PAGE_PATH.MASTER_GAME_LIST_ITEM)
                .replace(':provider', provider)
                .replace(':gameCode', itemId);
            }}
          >
            <Box
              component="div"
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 56px',
                columnGap: 2,
                mb: 2,
              }}
            >
              <Box
                component="div"
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '200px 56px',
                  columnGap: 2,
                }}
              >
                <FilterBox>
                  <Text
                    label="Filter by Name"
                    name="game_name"
                    value={filter.game_name}
                    onChange={(event) =>
                      handleChangeFilter({
                        name: 'game_name',
                        value: event.target.value,
                      })
                    }
                  />
                </FilterBox>
                <ClearBtn handleClick={handleClearFilter} filter={filter} />
              </Box>
              <IconButton aria-label="download" onClick={handleDownload}>
                <Download />
              </IconButton>
            </Box>
          </TableLayout>
        </>
      )}
    </>
  );
};

export default MasterGameList;
