import PropTypes from 'prop-types';

import { Edit } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';

const EditColumn = ({ label, onClick, iconSize = 'small' }) => (
  <Box sx={{ display: 'flex', alignItems: 'center' }}>
    <span>{label}</span>
    <IconButton onClick={onClick}>
      <Edit fontSize={iconSize} />
    </IconButton>
  </Box>
);

EditColumn.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
  iconSize: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
};
export default EditColumn;
