import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { findPatchPages } from 'utils/pages';

import { Tab, Tabs } from '@mui/material';

import Campaigns from 'components/pages/PromoTools/WheelOfFortune/Campaigns/Campaigns';

import { PAGE_PATH } from 'components/config/pages';

const WheelOfFortune = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(location.pathname);

  const handleChange = useCallback((event, newValue) => {
    navigate(newValue);
    setActiveTab(newValue);
  }, []);

  const tabComponent = useMemo(() => {
    switch (location.pathname) {
      case findPatchPages(PAGE_PATH.WHEEL_OF_FORTUNE_CAMPAIGNS):
        return Campaigns;
      default:
        return null;
    }
  }, [location]);

  useEffect(() => {
    if (!tabComponent) {
      handleChange(
        undefined,
        findPatchPages(PAGE_PATH.WHEEL_OF_FORTUNE_CAMPAIGNS),
      );
    }
  }, [tabComponent]);

  if (!tabComponent) {
    return null;
  }

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ bgcolor: 'background.paper', mb: 3 }}
      >
        <Tab
          label="Wheel of Fortune"
          value={findPatchPages(PAGE_PATH.WHEEL_OF_FORTUNE_CAMPAIGNS)}
        />
      </Tabs>
      {React.createElement(tabComponent)}
    </>
  );
};

export default WheelOfFortune;
