import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

import { reportDownload } from 'utils/reportDownload';

const analytics = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getRoundsHistory: build.query({
      query: (params) => ({
        url: '/admin/v2/reports/rounds-history',
        params,
      }),
    }),
    getActionsByRound: build.query({
      query: (params) => ({
        url: '/admin/v2/reports/actions',
        params,
      }),
      transformResponse: (response) => ({
        content: response,
        totalElements: -1,
      }),
    }),
    getActionDetails: build.query({
      query: (params) => ({
        url: '/admin/v2/reports/action-details',
        params,
      }),
    }),
    getBetWinDaily: build.query({
      query: (params) => ({
        url: '/admin/v2/reports/get-bet-win-by-day-statistic',
        params,
      }),
    }),
    getRtpGameStatistic: build.query({
      query: (params) => ({
        url: '/admin/v2/reports/rtp-game-statistic',
        params,
      }),
    }),
    getRoundsHistoryCSV: build.query({
      query: (params) => ({
        url: '/admin/v2/reports/rounds-history-csv',
        cache: 'no-cache',
        params,
        responseHandler: async (response) =>
          await reportDownload({
            response,
            name: 'RoundHistory',
            format: 'csv',
          }),
      }),
    }),
  }),
});

export const {
  useGetRoundsHistoryQuery,
  useGetActionsByRoundQuery,
  useGetActionDetailsQuery,
  useGetBetWinDailyQuery,
  useGetRtpGameStatisticQuery,
  useGetRoundsHistoryCSVQuery,
} = analytics;
