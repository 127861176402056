export const columns = [
  {
    name: 'Quest',
    value: (row) => `${row.questTemplateId}-${row.questTemplateName}`,
  },
  {
    name: 'Language',
    sortLabel: 'lang',
    value: (row) => row.lang,
  },
  {
    name: 'Title',
    value: (row) => row.titleText,
  },
  {
    name: 'Call to action',
    value: (row) => row.callToActionText,
  },
  {
    name: 'Subtitle 1',
    value: (row) => row.periodHeader,
  },
  {
    name: 'Text 1',
    value: (row) => row.periodText,
  },
  {
    name: 'Subtitle 2',
    value: (row) => row.rewardHeader,
  },
  {
    name: 'Text 2',
    value: (row) => row.rewardText,
  },
  {
    name: 'Subtitle 3',
    value: (row) => row.tocHeader,
  },
  {
    name: 'Text 3',
    value: (row) => row.tocText,
  },
];

export const dialogColumns = columns.filter((item) => item.name !== 'ID');

export const filters = {
  'Filter by Quest': 'quest_template_id',
  'Filter by Language': 'lang',
};

export const fields = [
  {
    name: 'titleText',
    title: 'Title',
    type: 'text',
  },
  {
    name: 'callToActionText',
    title: 'Call to action',
    type: 'textarea',
  },
  {
    name: 'periodHeader',
    title: 'Subtitle 1',
    type: 'text',
  },
  {
    name: 'periodText',
    title: 'Text 1',
    type: 'textarea',
  },
  {
    name: 'rewardHeader',
    title: 'Subtitle 2',
    type: 'text',
  },
  {
    name: 'rewardText',
    title: 'Text 2',
    type: 'textarea',
  },
  {
    name: 'tocHeader',
    title: 'Subtitle 3',
    type: 'text',
  },
  {
    name: 'tocText',
    title: 'Text 3',
    type: 'textarea',
  },
];
