import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { PhotoCamera } from '@mui/icons-material';
import { Avatar, Box, Button, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const Wrapper = styled('div')({
  display: 'flex',
  columnGap: 10,
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ImgTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    maxWidth: 'fit-content',
    border: 'none',
    padding: '4px',
    margin: 0,
  },
}));

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasError: PropTypes.bool,
  required: PropTypes.bool,
};

const ImageUpload = ({
  label,
  value = null,
  handleChange,
  hasError = false,
  required = true,
}) => (
  <Wrapper>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Button
        variant="outlined"
        component="label"
        startIcon={<PhotoCamera />}
        {...(!!hasError && { color: 'error' })}
      >
        {`${value ? 'Change' : 'Load'} ${label}${required ? '*' : ''}`}
        <VisuallyHiddenInput
          type="file"
          accept="image/*"
          onChange={(event) => handleChange(event.target.files[0])}
        />
      </Button>
    </Box>

    {value && (
      <ImgTooltip
        placement="top"
        enterDelay={500}
        title={
          <>
            <Box
              component="img"
              alt={label}
              src={_.isObject(value) ? URL.createObjectURL(value) : value}
              sx={{ maxHeight: 350, maxWidth: 350 }}
            />
          </>
        }
      >
        <Avatar
          alt={label}
          src={_.isObject(value) ? URL.createObjectURL(value) : value}
          sx={{ height: 60, width: 60 }}
        />
      </ImgTooltip>
    )}
  </Wrapper>
);

ImageUpload.propTypes = propTypes;
export default ImageUpload;
