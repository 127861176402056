import React from 'react';

import { Box, CircularProgress, useTheme } from '@mui/material';

const FullPageLoader = () => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        zIndex: 1000,
        left: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'rgba(255, 255, 255, 0.5)'
            : 'rgba(18, 18, 18, 0.5)',
      }}
    >
      <CircularProgress />
    </Box>
  );
};
export default FullPageLoader;
