import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const tags = hhsKeyCloack.injectEndpoints({
  //TODO: update mock endpoints after BE will be ready
  endpoints: (build) => ({
    getTags: build.query({
      queryFn: () => ({
        data: {
          content: [
            {
              tagId: '1',
              tagName: 'all_games',
              games: { 2: 'fullstate\\html5\\evoplay\\clashofpirates' },
              systems: { 1: 'localwl' },
            },
          ],
          totalElements: 1,
        },
      }),
    }),
    updateTag: build.mutation({
      queryFn: ({ id, ...body }) => {
        id ? console.log('updated', id, body) : console.log('created', body);
      },
    }),
    deleteTag: build.mutation({
      queryFn: ({ tagId }) => {
        console.log('deleted', tagId);
      },
    }),
    getGamesWithTags: build.query({
      queryFn: () => ({
        data: {
          content: [
            {
              game_id: '2',
              name: 'Clash of Pirates',
              absolute_name: 'fullstate\\html5\\evoplay\\clashofpirates',
              columns: '5',
              baraban_height: '3',
              hidden: '0',
              published: '2016-09-06 00:00:00',
              provider_id: '1',
              provider_game_absolute_name: '1',
              allow_custom_denominations: '0',
              settings:
                '{"languages":["bg","de","en","ens","es","fr","hr","id","it","ja","ko","lt","pt","ro","ru","th","tr","uk","vi","zh","zhtw"],"default_language":"en"}',
              is_certificated: '0',
              maker_id: '0',
              toolbar: '0',
              slug: null,
              hhs_development: '0',
              engine_swf_version: '0',
              game_code: 'clash_of_pirates',
              vendor_id: '1',
              gameId: '2',
              absoluteName: 'fullstate\\html5\\evoplay\\clashofpirates',
              systems: [],
              tags: {
                1: 'all_games',
              },
            },
          ],
          totalElements: 1,
        },
      }),
    }),
  }),
});

export const {
  useGetTagsQuery,
  useUpdateTagMutation,
  useDeleteTagMutation,
  useGetGamesWithTagsQuery,
} = tags;
