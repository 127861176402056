import { styled } from '@mui/material/styles';

const FilterBox = styled('div')`
  width: ${({ width = 210 }) => `${width}px`};

  > div {
    width: 100%;
  }
`;

export default FilterBox;
