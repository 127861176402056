import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { findPatchPages } from 'utils/pages';

import { Tab, Tabs } from '@mui/material';

import Campaigns from 'components/pages/PromoTools/Quests/Campaigns/Campaigns';
import EventTemplates from 'components/pages/PromoTools/Quests/EventTemplates/EventTemplates';
import QuestTemplates from 'components/pages/PromoTools/Quests/QuestTemplates/QuestTemplates';

import { PAGE_PATH } from 'components/config/pages';

const Quests = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(location.pathname);

  const handleChange = useCallback((event, newValue) => {
    navigate(newValue);
    setActiveTab(newValue);
  }, []);

  const tabComponent = useMemo(() => {
    switch (location.pathname) {
      case findPatchPages(PAGE_PATH.QUESTS_CAMPAIGNS):
        return Campaigns;
      case findPatchPages(PAGE_PATH.QUESTS_EVENT_TEMPLATES):
        return EventTemplates;
      case findPatchPages(PAGE_PATH.QUESTS_TEMPLATES):
        return QuestTemplates;
      default:
        return null;
    }
  }, [location]);

  useEffect(() => {
    if (!tabComponent) {
      handleChange(undefined, findPatchPages(PAGE_PATH.QUESTS_CAMPAIGNS));
    }
  }, [tabComponent]);

  if (!tabComponent) {
    return null;
  }

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        sx={{ bgcolor: 'background.paper', mb: 3 }}
      >
        <Tab
          label="Quests"
          value={findPatchPages(PAGE_PATH.QUESTS_CAMPAIGNS)}
        />
        <Tab
          label="Event Templates"
          value={findPatchPages(PAGE_PATH.QUESTS_EVENT_TEMPLATES)}
        />
        <Tab
          label="Quest Templates"
          value={findPatchPages(PAGE_PATH.QUESTS_TEMPLATES)}
        />
      </Tabs>
      {React.createElement(tabComponent)}
    </>
  );
};

export default Quests;
