import { useState, useCallback } from 'react';
import _debounce from 'lodash/debounce';

export const useSearch = () => {
  const [searchParams, setSearchParams] = useState({});

  const handleSearch = useCallback(
    _debounce(
      ({ target: { name, value } }) =>
        setSearchParams((prev) => ({
          ...prev,
          [name]: value,
        })),
      500,
    ),
    [],
  );

  return {
    searchParams,
    handleSearch,
  };
};
