import { api } from 'store/api/api';

export const bingoReports = api.injectEndpoints({
  endpoints: (build) => ({
    getBingoReportList: build.query({
      query: (params) => ({
        url: '/bingo/api/admin/v1/reports',
        params,
      }),
      transformResponse: ({ content, totalElements }) => ({
        content,
        totalElements,
      }),
    }),
    getReportDetails: build.query({
      query: (params) => ({
        url: '/bingo/api/admin/v1/report/details',
        params,
      }),
    }),
    getReportPlayers: build.query({
      query: (params) => ({
        url: '/bingo/api/admin/v1/report/players',
        params,
      }),
      transformResponse: ({ content, ...data }) => {
        const { playerEvents, ...restContent } = content ?? [];
        return {
          content: playerEvents ?? [],
          ...restContent,
          ...data,
        };
      },
    }),
  }),
});

export const {
  useGetBingoReportListQuery,
  useGetReportDetailsQuery,
  useGetReportPlayersQuery,
} = bingoReports;
