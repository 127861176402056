import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { baseQueryWithRauth } from 'store/api/queryWithReAuth';

import { getToken } from 'services/UserService';
import { getENV } from 'utils/getEnv';

import { config } from 'config/api';

const baseQuery = fetchBaseQuery({
  baseUrl: config[getENV()]?.cs,
  prepareHeaders: (headers) => {
    const accessToken = getToken();
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const cs = createApi({
  reducerPath: 'cs',
  tagTypes: ['FixedCurrencies'],
  baseQuery: (...props) => baseQueryWithRauth(...props, baseQuery),
  endpoints: () => ({}),
});
