import dayjs from 'dayjs';

import { Box } from '@mui/material';

import { DateTimeFormat } from 'config/dates';

export const typeMaps = {
  FixedRateCurrency: 'Fixed Currency Rate',
};

const highlightElement = (property, row) => (
  <Box
    sx={{
      ...(row.changedProperties?.includes(property) && {
        color: '#CC7722',
      }),
    }}
  >
    {typeof row.state[property] === 'boolean'
      ? row.state[property]
        ? 'true'
        : 'false'
      : row.state[property]}
  </Box>
);

export const columns = [
  {
    name: 'Name',
    value: (row) => row.name,
  },
  {
    name: 'Author',
    value: (row) => row.author,
  },
  {
    name: 'Commit Date',
    value: (row) => dayjs.utc(new Date(row.commitDate)).format(DateTimeFormat),
  },
  {
    name: 'State',
    value: () => `Show details`,
    collapse: {
      id: (row) => row.commitDate,
      data: (row) => [row],
      columns: [
        {
          name: 'Code',
          value: (row) => highlightElement('code', row),
        },
        {
          name: 'Rate Different',
          value: (row) => highlightElement('rateDifferent', row),
        },
        {
          name: 'Rate',
          value: (row) => highlightElement('rate', row),
        },
        {
          name: 'Fixed Rate',
          value: (row) => highlightElement('fixedRate', row),
        },
      ],
    },
  },
];
