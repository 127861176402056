import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { findPatchPages } from 'utils/pages';

import { Tab, Tabs } from '@mui/material';

import { PAGE_PATH } from 'components/config/pages';
import { PROVIDERS_LIST } from 'components/config/promotions';

const Promotions = () => {
  const { provider } = useParams();
  const navigate = useNavigate();

  const handleChangeTab = useCallback((_, newValue) => {
    navigate(
      findPatchPages(PAGE_PATH.CLIENT_AREA_PROMOTIONS).replace(
        ':provider',
        newValue,
      ),
    );
  }, []);

  useEffect(() => {
    if (!provider) {
      handleChangeTab(undefined, PROVIDERS_LIST[0]);
    }
  }, [provider]);

  if (!PROVIDERS_LIST.includes(provider)) {
    return null;
  }

  return (
    <Tabs
      value={provider}
      onChange={handleChangeTab}
      variant="scrollable"
      scrollButtons="auto"
      sx={{ bgcolor: 'background.paper', mb: 3 }}
    >
      {PROVIDERS_LIST.map((provider) => (
        <Tab
          key={provider}
          label={provider.toLocaleUpperCase()}
          value={provider}
        />
      ))}
    </Tabs>
  );
};

export default Promotions;
