import React from 'react';

import PropTypes from 'prop-types';

import { TableCell, TableRow } from '@mui/material';

import { convertToTitle } from 'components/config/bingoPlayersReport';

const propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      part: PropTypes.number.isRequired,
      patternName: PropTypes.string.isRequired,
      winningNumber: PropTypes.number,
      winningCall: PropTypes.number,
      prizePercentage: PropTypes.number,
      prizeAmount: PropTypes.number,
      winningTickets: PropTypes.arrayOf(
        PropTypes.shape({
          ticketId: PropTypes.string,
          prizePerTicket: PropTypes.number,
          playerId: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  padding: PropTypes.string.isRequired,
};

const partDetailsMap = [
  { detailsKey: 'patternName' },
  { detailsKey: 'winningNumber', styles: { fontWeight: 'bold' } },
  { detailsKey: 'winningCall' },
  { detailsKey: 'prizePercentage', title: 'Prize %' },
  { detailsKey: 'prizeAmount' },
];

const DetailsRows = ({ data, padding }) => (
  <>
    {partDetailsMap.map((row) => (
      <TableRow key={row.detailsKey}>
        {data?.map((part) =>
          part[row.detailsKey] ? (
            <React.Fragment key={part.part}>
              <TableCell sx={{ padding }}>
                {row.title ? row.title : convertToTitle(row.detailsKey)}
              </TableCell>
              <TableCell sx={{ padding, ...row.styles }}>
                {part[row.detailsKey]}
              </TableCell>
            </React.Fragment>
          ) : null,
        )}
      </TableRow>
    ))}
  </>
);

DetailsRows.propTypes = propTypes;
export default DetailsRows;
