import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const testPlayers = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getTestPlayersList: build.query({
      query: ({ size, page, ...params }) => ({
        url: '/api/v2/test-players',
        params: {
          ...params,
          limit: size,
          offset: page * size,
          ...(params.sort.length && {
            orderField: params.sort.split(',')[0],
            orderDirection: params.sort.split(',')[1]?.toUpperCase(),
          }),
        },
      }),
      providesTags: () => [{ type: 'testPlayers' }],
    }),
    createTestPlayers: build.mutation({
      query: (data) => ({
        url: '/api/v2/test-players',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: () => [{ type: 'testPlayers' }],
    }),
  }),
});

export const { useGetTestPlayersListQuery, useCreateTestPlayersMutation } =
  testPlayers;
