import { cs } from 'store/api/cs';

const auditController = cs.injectEndpoints({
  endpoints: (build) => ({
    getAuditTypes: build.query({
      query: () => ({
        url: `/currency-service/admin/v1/audit/types`,
      }),
    }),
    getAuditListByType: build.query({
      query: ({ type, id, ...query }) => ({
        url: `/currency-service/admin/v1/audit/${type}${id ? `/${id}` : ''}`,
        params: query,
      }),
      transformResponse: (response) => ({
        ...response,
        content: response.content.map((item) => ({
          ...item,
          id: item.commitDate,
        })),
        totalElements: -1,
      }),
    }),
  }),
});

export const { useGetAuditTypesQuery, useGetAuditListByTypeQuery } =
  auditController;
