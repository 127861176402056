import { useCallback, useMemo, useState } from 'react';

import { useToggle } from 'hooks/useToggle';

import { useGetSystemListQuery } from 'store/api/hhs/recipe.api';
import {
  useDeleteSystemGroupMutation,
  useGetSystemGroupsQuery,
} from 'store/api/hhsKeyCloack/systemGroups';

import { findPatchPages } from 'utils/pages';

import TableLayout from 'components/Layout/TableLayout';
import AssignDialog from 'components/UI/AssignDialog/AssignDialog';
import EditColumn from 'components/UI/Table/EditColumn';

import { PAGE_PATH } from 'components/config/pages';

const SystemGroups = () => {
  const [assignDialogItem, setAssignDialogItem] = useState(null);

  const { data: dataSystems, isLoading: systemsLoading } =
    useGetSystemListQuery();

  const [deleteSystemGroup] = useDeleteSystemGroupMutation();

  const { opened: assignDialogOpened, handleOpen, handleClose } = useToggle();

  const handleOpenAssignDialog = useCallback((item) => {
    setAssignDialogItem(item);
    handleOpen();
  }, []);

  const handleCloseAssignDialog = useCallback(() => {
    handleClose();
    setAssignDialogItem(null);
  }, []);

  const assignDialogProps = useMemo(() => {
    switch (assignDialogItem?.type) {
      case 'systems': {
        return {
          data: dataSystems?.reduce(
            (acc, item) => ({
              ...acc,
              ...(assignDialogItem.data.systemIds.includes(item.value)
                ? { assigned: [...acc.assigned, item] }
                : { available: [...acc.available, item] }),
            }),
            { available: [], assigned: [] },
          ),
          handleSubmit: () => {},
          loading: systemsLoading,
        };
      }
      case 'users':
        //TODO: add real data for users during API integration
        return {
          handleSubmit: () => {},
        };
    }
  }, [assignDialogItem, dataSystems, systemsLoading]);

  const columns = useMemo(
    () => [
      {
        name: 'ID',
        value: ({ id }) => id,
      },
      {
        name: 'Name',
        value: ({ name }) => name,
      },
      {
        name: 'Comment',
        value: ({ comment }) => comment,
      },
      {
        name: 'Disabled',
        value: ({ disabled }) => disabled,
      },
      {
        name: 'Assigned Systems',
        value: (item) => (
          <EditColumn
            label={item.systemIds?.length}
            onClick={(e) => {
              e.stopPropagation();

              handleOpenAssignDialog({
                type: 'systems',
                data: item,
              });
            }}
          />
        ),
      },
      {
        name: 'Assigned Users',
        value: (item) => (
          <EditColumn
            label={item.users?.length}
            onClick={(e) => {
              e.stopPropagation();

              handleOpenAssignDialog({
                type: 'users',
                data: item,
              });
            }}
          />
        ),
      },
    ],
    [handleOpenAssignDialog],
  );

  return (
    <>
      <TableLayout
        title="System Groups"
        columns={columns}
        getDataQuery={useGetSystemGroupsQuery}
        deleteItemQuery={deleteSystemGroup}
        itemComponentPath={findPatchPages(PAGE_PATH.SYSTEM_GROUPS_ITEM)}
      />
      {assignDialogOpened && (
        <AssignDialog
          type={assignDialogItem.type}
          handleClose={handleCloseAssignDialog}
          {...assignDialogProps}
        />
      )}
    </>
  );
};

export default SystemGroups;
