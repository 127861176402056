import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useGetIntegrationTypesQuery } from 'store/api/hhs/integrationTypes';
import { useGetCurrenciesQuery } from 'store/api/hhsKeyCloack/currencies';
import {
  useGenerateSystemKeyMutation,
  useGetGameLicenseListQuery,
  useGetSystemQuery,
  useUpdateSystemMutation,
} from 'store/api/hhsKeyCloack/systemSettings';
import { showAlert } from 'store/slices/alert';
import { tableSelector } from 'store/slices/table';

import { EditItem } from 'utils/EditItem';
import { findPatchPages } from 'utils/pages';
import { isNumeric } from 'utils/validate';

import { Cached, KeyboardBackspace } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
} from '@mui/material';

import Autocomplete from 'components/UI/Form/Autocomplete';
import Select from 'components/UI/Form/Select';
import Text from 'components/UI/Form/Text';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';
import NotFound from 'components/pages/NotFound/NotFound';

import { PAGE_PATH } from 'components/config/pages';
import { settingSwitches } from 'components/config/systemSettings';

const initialState = {
  name: '',
  title: '',
  licenseType: 'other',
  integrationType: '',
  eventListener: '',
  callbackUrl: '',
  balanceIncreaseUrl: '',
  ...settingSwitches.reduce(
    (acc, { name, initialChecked }) => ({
      ...acc,
      [name]: !!initialChecked,
    }),
    {},
  ),
  tags: [],
  callbackTimeoutSec: '0',
  gameSessionLifeTimeHours: '0',
  scalable: false,
  scale: '12',
  currencies: [],
  comments: '',
  labels: '',
};

const convertNumericStringToBoolean = (str) => !!+str;

const initialValue = (editItem, itemId) => {
  if (!editItem) return initialState;

  const initialFields = Object.entries(initialState).reduce(
    (acc, [key, initialValue]) => {
      const value = editItem[key];
      return {
        ...acc,
        [key]: value
          ? settingSwitches.find(({ name }) => name === key)
            ? convertNumericStringToBoolean(value)
            : value
          : initialValue,
      };
    },
    {},
  );

  return {
    ...initialFields,
    systemKey: editItem.systemKey ?? '',
    altSystemKey: editItem.altSystemKey ?? '',
    released:
      itemId !== 'copy' && convertNumericStringToBoolean(editItem.production),
    enabled: !convertNumericStringToBoolean(editItem.disabled),
    labels: editItem.labels?.join(', ') ?? '',
  };
};

const SystemSettingsItem = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { itemId } = useParams();

  const [editItem, setEditItem] = useState(null);
  const [itemsValue, setItemsValue] = useState(null);
  const [errors, setErrors] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [process, setProcess] = useState(false);

  const editItemStore = useSelector(tableSelector.getEditItem);

  const {
    data: editItemQuery,
    isLoading,
    isError,
  } = useGetSystemQuery(
    { id: itemId },
    {
      skip: !isNumeric(itemId) || Boolean(isNumeric(itemId) && editItemStore),
    },
  );

  const { data: gameLicenseList, isLoading: gameLicenseListLoading } =
    useGetGameLicenseListQuery();
  const { data: integrationTypes, isLoading: integrationTypesLoading } =
    useGetIntegrationTypesQuery();
  const { data: currencies, isLoading: currenciesLoading } =
    useGetCurrenciesQuery();

  const [updateSystem] = useUpdateSystemMutation();
  const [generateSystemKey] = useGenerateSystemKeyMutation();

  const handleBack = useCallback(() => {
    navigate(findPatchPages(PAGE_PATH.SYSTEM_SETTINGS));
  }, []);

  const editItemClass = useMemo(
    () =>
      new EditItem({
        dispatch,
        handleBack,
        setErrors,
        setSubmit,
        setProcess,
        setItemsValue,
      }),
    [],
  );

  const handleChange = useCallback(
    ({ name, value, isCheckValue }) =>
      editItemClass.handleChange({
        name,
        value,
        errors,
        isCheckValue,
      }),
    [errors],
  );

  const handleChangeSystemKey = useCallback(
    (key) => async () => {
      const result = await generateSystemKey();

      if (result.error) {
        dispatch(
          showAlert({
            type: 'error',
            text:
              result.error.data?.errorMessage ??
              'Failed to generate system key',
          }),
        );

        return;
      }

      setItemsValue((prev) => ({
        ...prev,
        [key]: result.data.systemKey,
      }));

      errors.includes(key) &&
        setErrors((prev) => prev.filter((error) => error !== key));
    },
    [errors],
  );

  const handleSubmit = useCallback(
    () =>
      editItemClass.handleSubmit({
        itemsValue,
        textFields: [
          'name',
          'title',
          'licenseType',
          'integrationType',
          ...(editItem ? ['systemKey'] : []),
        ],
      }),
    [itemsValue, editItem],
  );

  const updateData = useCallback(async () => {
    const result = await updateSystem({
      ...(editItem?.id && { id: editItem.id }),
      ...itemsValue,
      labels: itemsValue.labels.split(',').flatMap((label) => {
        const str = label.trim();
        return str ? [str] : [];
      }),
    });

    editItemClass.checkUpdateData(result);
  }, [editItem, itemsValue]);

  useEffect(() => {
    submit && updateData();
  }, [submit]);

  useEffect(() => {
    const editItem = editItemStore || editItemQuery;

    if (!isLoading) {
      if (editItem) {
        setEditItem(editItem);
        setItemsValue(initialValue(editItem, itemId));
      } else {
        setItemsValue(initialValue(null));
      }
    }
  }, [editItemQuery, editItemStore, isLoading]);

  useEffect(() => {
    if (itemId === 'copy' && !editItemStore) {
      handleBack();
    }
  }, [itemId, editItemStore]);

  if (isError) return <NotFound />;

  return (
    <>
      {(process || (itemId !== 'add' && !itemsValue)) && <FullPageLoader />}
      {itemsValue && (
        <>
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              mb: 3,
            }}
          >
            <IconButton onClick={handleBack}>
              <KeyboardBackspace />
            </IconButton>
            <Box
              component="h3"
              sx={{
                m: 0,
                display: 'flex',
                alignItems: 'center',
                fontWeight: 500,
              }}
            >
              {editItem
                ? editItem.id
                  ? `Update System "${editItem.name}"`
                  : `Copy System "${editItem.name}"`
                : 'Create System'}
            </Box>
          </Box>
          <Stack
            spacing={2}
            sx={{
              mb: 2,
              pt: 1,
              pb: 3,
              maxHeight: 'calc(100vh - 230px)',
              overflowY: 'auto',
            }}
          >
            <Text
              name="name"
              value={itemsValue.name}
              label="Name"
              onChange={({ target }) => handleChange(target)}
              error={errors.includes('name')}
              disabled={!!editItem}
              required
            />
            <Text
              name="title"
              value={itemsValue.title}
              label="Title"
              onChange={({ target }) => handleChange(target)}
              error={errors.includes('title')}
              disabled={!!editItem}
              required
            />
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2,
              }}
            >
              <Select
                name="licenseType"
                value={itemsValue.licenseType}
                label="License"
                options={gameLicenseList}
                handleChange={handleChange}
                error={errors.includes('licenseType')}
                loading={gameLicenseListLoading}
              />
              <Select
                name="integrationType"
                value={itemsValue.integrationType}
                label="Integration Type"
                options={integrationTypes}
                handleChange={handleChange}
                error={errors.includes('integrationType')}
                loading={integrationTypesLoading}
              />
              {!!editItem && (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Text
                      name="systemKey"
                      value={itemsValue.systemKey}
                      label="Key"
                      onChange={({ target }) => handleChange(target)}
                      error={errors.includes('systemKey')}
                      required
                      fullWidth
                    />
                    <IconButton onClick={handleChangeSystemKey('systemKey')}>
                      <Cached />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Text
                      name="altSystemKey"
                      value={itemsValue.altSystemKey}
                      label="Alt.Key"
                      onChange={({ target: { name, value } }) =>
                        handleChange({ name, value, isCheckValue: false })
                      }
                      fullWidth
                    />
                    <IconButton onClick={handleChangeSystemKey('altSystemKey')}>
                      <Cached />
                    </IconButton>
                  </Box>
                </>
              )}
              <Text
                name="eventListener"
                value={itemsValue.eventListener}
                label="Event Listener URL"
                onChange={({ target: { name, value } }) =>
                  handleChange({ name, value, isCheckValue: false })
                }
              />
              <Text
                name="callbackUrl"
                value={itemsValue.callbackUrl}
                label="Callback URL"
                onChange={({ target: { name, value } }) =>
                  handleChange({ name, value, isCheckValue: false })
                }
              />
            </Box>
            <Text
              name="balanceIncreaseUrl"
              value={itemsValue.balanceIncreaseUrl}
              label="Balance Increase URL"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
            />
            <Autocomplete
              name="tags"
              value={itemsValue.tags}
              label="Tags"
              options={[]}
              handleChange={handleChange}
              error={errors.includes('tags')}
              required={false}
              multiple
            />
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: 2,
              }}
            >
              {settingSwitches.map(({ name, label }) => (
                <FormControlLabel
                  key={name}
                  control={
                    <Switch
                      name={name}
                      checked={itemsValue[name]}
                      onChange={({ target: { name, checked } }) =>
                        handleChange({ name, value: checked })
                      }
                    />
                  }
                  label={label}
                  sx={{ mx: 0 }}
                />
              ))}
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2,
              }}
            >
              <Text
                name="callbackTimeoutSec"
                value={itemsValue.callbackTimeoutSec}
                label="Callback Timeout"
                onChange={({ target: { name, value } }) =>
                  handleChange({ name, value, isCheckValue: false })
                }
              />
              <Text
                name="gameSessionLifeTimeHours"
                value={itemsValue.gameSessionLifeTimeHours}
                label="Game Session Lifetime"
                onChange={({ target: { name, value } }) =>
                  handleChange({ name, value, isCheckValue: false })
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    name="scalable"
                    checked={itemsValue.scalable}
                    onChange={({ target: { name, checked } }) =>
                      handleChange({ name, value: checked })
                    }
                  />
                }
                label="Scalable"
                sx={{ mx: 0 }}
              />
              {itemsValue.scalable && (
                <>
                  <Text
                    name="scale"
                    value={itemsValue.scale}
                    label="Scale"
                    onChange={({ target: { name, value } }) =>
                      handleChange({ name, value, isCheckValue: false })
                    }
                  />
                  <Autocomplete
                    name="currencies"
                    value={itemsValue.currencies}
                    label="Currencies"
                    options={currencies}
                    handleChange={({ name, value }) =>
                      handleChange({ name, value, isCheckValue: false })
                    }
                    required={false}
                    loading={currenciesLoading}
                    multiple
                  />
                </>
              )}
            </Box>
            <Text
              name="comments"
              value={itemsValue.comments}
              label="Comments"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
              multiline
              rows={2}
            />
            <Text
              name="labels"
              value={itemsValue.labels}
              label="Labels"
              onChange={({ target: { name, value } }) =>
                handleChange({ name, value, isCheckValue: false })
              }
            />
          </Stack>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{ alignSelf: 'flex-end' }}
          >
            {editItem ? (editItem.id ? 'Update' : 'Copy') : 'Create'}
          </Button>
        </>
      )}
    </>
  );
};

export default SystemSettingsItem;
