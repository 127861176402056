import dayjs from 'dayjs';

import { Avatar } from '@mui/material';

import { DateTimeFormat } from 'config/dates';

export const columns = [
  {
    name: 'ID',
    sortLabel: 'id',
    value: ({ id }) => id,
  },
  {
    name: 'Name',
    sortLabel: 'name',
    value: ({ name }) => name,
  },
  {
    name: 'Subtype',
    sortLabel: 'monetaryConfig.subType',
    value: ({ monetaryConfig }) => monetaryConfig?.subType,
  },
  {
    name: 'Image',
    value: ({ mediumBanner }) => <Avatar alt="Icon" src={mediumBanner} />,
  },
  {
    name: 'Base Currency',
    sortLabel: 'monetaryConfig.baseCurrency',
    value: ({ monetaryConfig }) => monetaryConfig?.baseCurrency,
  },
  {
    name: 'Min bet',
    value: ({ monetaryConfig }) => monetaryConfig?.minimalBet,
  },
  {
    name: 'Campaign prize pool',
    value: ({ monetaryConfig }) => monetaryConfig?.campaignPrizePool,
  },
  {
    name: 'Scoring Rule',
    sortLabel: 'monetaryConfig.scoringRule',
    value: ({ monetaryConfig }) => monetaryConfig?.scoringRule,
  },
  {
    name: 'Games',
    value: ({ gameCodes }) => gameCodes?.length ?? 0,
  },
  {
    name: 'Operators',
    value: ({ operatorCodes }) => operatorCodes?.length ?? 0,
  },
  {
    name: 'Start',
    sortLabel: 'temporalDetails.startDateTime',
    value: ({ temporalDetails }) =>
      dayjs
        .utc(new Date(temporalDetails?.startDateTime))
        .format(DateTimeFormat),
  },
  {
    name: 'End',
    sortLabel: 'temporalDetails.finishDateTime',
    value: ({ temporalDetails }) =>
      dayjs
        .utc(new Date(temporalDetails?.finishDateTime))
        .format(DateTimeFormat),
  },
];

export const tournamentSubTypes = [
  { label: 'Regular', value: 'REGULAR' },
  { label: 'Races', value: 'RACES' },
];
