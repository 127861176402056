import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { alertSelector, hideAlert } from 'store/slices/alert';

import { AlertTitle, Box, Alert as MuiAlert } from '@mui/material';

const Alert = () => {
  const dispatch = useDispatch();
  const { items, isAutoHide } = useSelector(alertSelector.getData);

  const prepareTitle = useCallback(
    ({ title, type }) => {
      const text = title ? title : type;
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    [items],
  );

  useEffect(() => {
    if (Object.keys(items).length && isAutoHide) {
      setTimeout(() => dispatch(hideAlert()), 7500);
    }
  }, [items]);

  if (!Object.keys(items).length) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        display: 'grid',
        rowGap: '10px',
        position: 'absolute',
        top: '20px',
        right: '20px',
        minWidth: '220px',
        maxWidth: '500px',
        maxHeight: 'calc(100vh - 50px)',
        overflow: 'hidden scroll',
        zIndex: 1301,
      }}
    >
      {Object.keys(items).map((key) => (
        <MuiAlert
          key={key}
          severity={items[key].type}
          onClose={() => dispatch(hideAlert(key))}
        >
          <AlertTitle>{prepareTitle(items[key])}</AlertTitle>
          <div dangerouslySetInnerHTML={{ __html: items[key].text }} />
        </MuiAlert>
      ))}
    </Box>
  );
};
export default Alert;
