import { css } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PrizeContainer = styled('div')`
  display: grid;
  gap: ${({ theme, spacing = 2 }) => theme.spacing(spacing)};
  grid-template-columns: repeat(3, 1fr);
  ${({ theme, mainPrize }) =>
    mainPrize &&
    css(
      `position: relative;
            padding: ${theme.spacing(3)} ${theme.spacing(2)};
            border: 1px solid ${theme.palette.primary.main};
            border-radius: ${theme.shape.borderRadius}px;`,
    )};
  ${({ type, prizeType }) => {
    switch (type) {
      case 'prize_drops':
      case 'wheel_of_fortune':
        if (prizeType === 'MONEY') {
          return css`
            grid-template-areas: 'prizeType prizeQuantity moneyPrize';
          `;
        }
        if (prizeType === 'TEXT') {
          return css`
            grid-template-areas: 'prizeType prizeQuantity textPrize';
          `;
        }
        if (prizeType === 'FREE_ROUND') {
          return css`
            grid-template-areas:
              'prizeType prizeQuantity freeRounds'
              'gameCode betAmount expirationPeriod'
              'activeGame . .';
          `;
        }
        return css`
          grid-template-areas: 'prizeType prizeQuantity';
        `;
      case 'tournament':
        if (prizeType === 'MONEY') {
          return css`
            grid-template-areas:
              'prizeType rankFrom rankTo'
              'moneyPrize moneyPrize moneyPrize';
          `;
        }
        if (prizeType === 'TEXT') {
          return css`
            grid-template-areas:
              'prizeType rankFrom rankTo'
              'textPrize textPrize textPrize';
          `;
        }
        if (prizeType === 'FREE_ROUND') {
          return css`
            grid-template-areas:
              'prizeType rankFrom rankTo'
              'freeRounds gameCode betAmount'
              'expirationPeriod activeGame .';
          `;
        }
        return css`
          grid-template-areas: 'prizeType rankFrom rankTo';
        `;
      default:
        if (prizeType === 'MONEY') {
          return css`
            grid-template-areas: 'prizeType moneyPrize moneyPrize';
          `;
        }
        if (prizeType === 'TEXT') {
          return css`
            grid-template-areas: 'prizeType textPrize textPrize';
          `;
        }
        if (prizeType === 'FREE_ROUND') {
          return css`
            grid-template-areas:
              'prizeType gameCode activeGame'
              'freeRounds betAmount expirationPeriod';
          `;
        }
        return css`
          grid-template-areas: 'prizeType';
        `;
    }
  }};
`;
export const PrizeType = styled('div')`
  grid-area: prizeType;
`;
export const PrizeQuantity = styled('div')`
  grid-area: prizeQuantity;
`;
export const RankFrom = styled('div')`
  grid-area: rankFrom;
`;
export const RankTo = styled('div')`
  grid-area: rankTo;
`;
export const MoneyPrize = styled('div')`
  grid-area: moneyPrize;
`;
export const TextPrize = styled('div')`
  grid-area: textPrize;
`;
export const FreeRounds = styled('div')`
  grid-area: freeRounds;
`;
export const GameCode = styled('div')`
  grid-area: gameCode;
`;
export const BetAmount = styled('div')`
  grid-area: betAmount;
`;
export const ExpirationPeriod = styled('div')`
  grid-area: expirationPeriod;
`;
export const ActiveGame = styled('div')`
  grid-area: activeGame;
  display: flex;
`;
