import React, { useCallback, useEffect, useRef, useState } from 'react';

import { throttle } from 'lodash';

import {
  useGetAuditListByTypeQuery,
  useGetAuditTypesQuery,
} from 'store/api/cs/auditController';

import { Delete } from '@mui/icons-material';
import { Box, IconButton, Tab, Tabs, TextField } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';
import FullPageLoader from 'components/UI/FullPageLoader/FullPageLoader';

import { columns, typeMaps } from 'components/config/audit';

const Audit = () => {
  const filterRef = useRef();

  const [auditType, setAuditType] = useState(null);
  const [filter, setFilter] = useState(null);
  const [isFocused, setFocus] = useState(false);

  const { data: dataTypes, isFetching: isFetchingTypes } =
    useGetAuditTypesQuery();

  const handleChangeType = useCallback(
    (event, newValue) => setAuditType(newValue),
    [],
  );
  const handleChangeFilter = useCallback(
    throttle((value) => setFilter(value), 1000),
    [],
  );
  const handleClearFilter = useCallback(() => {
    setFilter(null);
    filterRef.current.value = null;
    filterRef.current.blur();
    setInputFocus(false);
  }, []);

  const setInputFocus = useCallback((state) => {
    const notEmpty = !!filterRef.current?.value;
    if (notEmpty) return setFocus(true);
    setFocus(state);
  }, []);

  useEffect(() => {
    if (dataTypes) {
      setAuditType(dataTypes[0]);
    }
  }, [dataTypes]);

  return (
    <>
      {isFetchingTypes && <FullPageLoader />}
      {auditType && (
        <>
          <Tabs
            value={auditType}
            onChange={handleChangeType}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ bgcolor: 'background.paper', mb: 3 }}
          >
            {dataTypes.map((type) => (
              <Tab key={type} label={typeMaps[type]} value={type} />
            ))}
          </Tabs>
          <TableLayout
            actions={[]}
            columns={columns}
            getDataQuery={useGetAuditListByTypeQuery}
            title={typeMaps[auditType]}
            queryConditional={{
              type: auditType,
              ...(filter && { id: filter }),
            }}
            editableRow={false}
          >
            <Box
              component="div"
              sx={{
                mb: 2,
                display: 'grid',
                gridTemplateColumns: `200px 56px`,
                columnGap: 2,
              }}
            >
              <TextField
                inputRef={filterRef}
                label="Filter by Name"
                InputLabelProps={{ shrink: isFocused }}
                onFocus={() => setInputFocus(true)}
                onBlur={() => setInputFocus(false)}
                onChange={(event) => handleChangeFilter(event.target.value)}
              />
              {filter && (
                <IconButton aria-label="delete" onClick={handleClearFilter}>
                  <Delete />
                </IconButton>
              )}
            </Box>
          </TableLayout>
        </>
      )}
    </>
  );
};

export default Audit;
