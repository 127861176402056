import React from 'react';

import { useSearch } from 'hooks/useSearch';

import {
  useDeleteQuestsCampaignMutation,
  useGetQuestsCampaignsQuery,
} from 'store/api/api/tournamentService/questCampaignController';

import { findPatchPages } from 'utils/pages';

import { TextField } from '@mui/material';

import TableLayout from 'components/Layout/TableLayout';

import { PAGE_PATH } from 'components/config/pages';
import { columns } from 'components/config/questsCampaigns';

const Campaigns = () => {
  const [deleteEventTemplate] = useDeleteQuestsCampaignMutation();

  const { searchParams, handleSearch } = useSearch();

  return (
    <TableLayout
      columns={columns}
      getDataQuery={useGetQuestsCampaignsQuery}
      queryConditional={searchParams}
      title="Quests"
      deleteItemQuery={deleteEventTemplate}
      itemComponentPath={findPatchPages(PAGE_PATH.QUESTS_CAMPAIGNS_ITEM)}
    >
      <TextField
        name="name"
        label="Search by Name"
        onChange={handleSearch}
        sx={{ alignSelf: 'flex-start', mb: 2 }}
      />
    </TableLayout>
  );
};

export default Campaigns;
