import { hhsKeyCloack } from 'store/api/hhsKeyCloack';

const currenciesController = hhsKeyCloack.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query({
      query: () => ({
        url: '/api/v2/currencies',
      }),
    }),
  }),
});

export const { useGetCurrenciesQuery } = currenciesController;
